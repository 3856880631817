import { Component, Input, OnInit } from '@angular/core';
import { VideoTranslations } from './video.translations';
@Component({
  // TODO: Fix tslint
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {
  @Input() url: string;

  @Input() public set translations(value: VideoTranslations) {
    this._translations = new VideoTranslations(value);
  }
  public get translations(): VideoTranslations {
    return this._translations;
  }
  public showDialogClose = true;
  private _translations: VideoTranslations = new VideoTranslations();

  ngOnInit() {
    this.url = this.parseUrl(this.url);
  }

  private parseUrl(url) {
    return url.replace('watch?v=', 'v/');
  }
}
