<div
  class="flex items-center gap-4 py-4 px-4 text-base whitespace-normal"
  [ngClass]="[linkClass ?? '', dialogContent ? 'sm:px-6' : '']"
  matRipple
  [matRippleColor]="color"
  [matRippleDisabled]="['simple', 'subcategory'].includes(type)"
>
  <mat-icon
    *ngIf="leftIconName"
    class="flex-none order-1"
    [svgIcon]="leftIconName"
    [attr.aria-label]="ariaLabel"
  ></mat-icon>

  <mat-icon
    *ngIf="rightIconName"
    class="flex-none order-10"
    [svgIcon]="rightIconName"
    [attr.aria-label]="ariaLabel"
    tabindex="0"
    #tooltip="matTooltip"
    [matTooltip]="rightIconTooltipText"
    [matTooltipPosition]="rightIconTooltipPosition"
    [matTooltipDisabled]="!rightIconTooltipText"
  ></mat-icon>

  <zelis-avatar
    *ngIf="avatarSrc"
    class="flex-none order-2"
    [src]="avatarSrc"
    [placeholderIcon]="avatarPlaceholderIcon"
    [isProviderAvatar]="isProviderAvatar"
    size="small"
    [ariaLabel]="ariaLabel"
  ></zelis-avatar>

  <mat-checkbox
    *ngIf="type === 'multi' && !slideToggle"
    class="flex-none pointer-events-none"
    tabindex="-1"
    [name]="name"
    [value]="value"
    [color]="color"
    [checked]="selected"
    [disabled]="disabled"
    [aria-labelledby]="'list-item-primary-text_' + index"
    [ngClass]="{
      'order-3': checkboxPosition === 'before',
      'order-9': checkboxPosition === 'after',
    }"
  ></mat-checkbox>

  <mat-radio-button
    *ngIf="type === 'single' && !slideToggle"
    class="flex-none pointer-events-none -mr-1"
    tabindex="-1"
    [name]="name"
    [value]="value"
    [zelisMaterialColor]="color"
    [checked]="selected"
    [disabled]="disabled"
    [aria-labelledby]="'list-item-primary-text_' + index"
    [ngClass]="{
      'order-3': checkboxPosition === 'before',
      'order-9': checkboxPosition === 'after',
    }"
  ></mat-radio-button>

  <mat-slide-toggle
    data-cy="list-item-mat-slide-toggle"
    *ngIf="type === 'toggle' || slideToggle"
    class="flex-none pointer-events-none"
    tabindex="-1"
    [color]="color"
    [checked]="selected"
    [disabled]="disabled"
    [aria-labelledby]="'list-item-primary-text_' + index"
    [ngClass]="{
      'order-3': checkboxPosition === 'before',
      'order-9': checkboxPosition === 'after',
    }"
  ></mat-slide-toggle>

  <div class="flex-auto order-4">
    <div
      class="dls-body-1-regular"
      [ngClass]="{
        'dls-body-1-regular': type !== 'subcategory',
        'font-bold': subhead,
        'text-black': subhead && !disabled,
        'text-sm': type === 'subcategory',
        'text-palette-m3-system-gray-35 font-medium': type === 'subcategory',
        'text-palette-m3-system-gray-45': disabled,
        'text-palette-m3-system-blue-40': primaryTextAsLink && !disabled,
        'dls-body-2-regular': primaryTextAsLink,
      }"
      [attr.id]="'list-item-primary-text_' + index"
      [zelisHighlight]="highlightTerm"
      data-cy="list-item-primary-text "
      [innerHTML]="primaryText"
    ></div>

    <div
      *ngIf="secondaryText"
      class="dls-caption-regular text-palette-m3-system-gray-35"
      data-cy="list-item-secondary-text "
      [innerHTML]="secondaryText"
    ></div>
  </div>

  <div *ngIf="lozengeText || metadata" class="flex-1 order-5">
    <div class="flex items-center justify-end gap-4">
      <zelis-theme-lozenge
        [color]="lozengeColor"
        [emphasis]="lozengeEmphasis"
        size="medium"
        [type]="lozengeType"
        [classes]="lozengeClasses"
        *ngIf="
          lozengeType === 'theme' &&
          lozengeColor &&
          lozengeEmphasis &&
          lozengeText
        "
        >{{ lozengeText }}
      </zelis-theme-lozenge>
      <zelis-theme-lozenge
        *ngIf="lozengeType === 'low-emphasis-gray' && lozengeText"
        size="medium"
        emphasis="low"
        color="system-gray"
      >
        {{ lozengeText }}
      </zelis-theme-lozenge>

      <zelis-theme-lozenge
        *ngIf="lozengeType === 'high-emphasis-yellow' && lozengeText"
        size="medium"
        emphasis="high"
        color="system-yellow"
      >
        {{ lozengeText }}
      </zelis-theme-lozenge>

      <p
        *ngIf="metadata"
        class="dls-caption-regular text-palette-m3-system-gray-35"
      >
        {{ metadata }}
      </p>
    </div>
  </div>
</div>
