import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DlsButtonModule } from '@zelis/dls/button';
import { MatListModule } from '@angular/material/list';
import { DlsLozengeModule } from '@zelis/dls/lozenge';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { PlanListSelectionComponent } from '../plan-list-selection/plan-list-selection.component';
import { WizardLoginLinkModule } from '../wizard-login-link/wizard-login-link.module';

@NgModule({
  declarations: [PlanListSelectionComponent],
  imports: [
    CommonModule,
    DlsButtonModule,
    MatListModule,
    DlsLozengeModule,
    MatRadioModule,
    TranslateModule,
    WizardLoginLinkModule,
  ],
  exports: [PlanListSelectionComponent],
})
export class PlanListSelectionModule {}
