<div class="dialog-header">
  <span mat-dialog-title class="sr-only">{{ translations.srTitle }}</span>
  <button
    class="close-button"
    *ngIf="showDialogClose"
    mat-icon-button
    mat-dialog-close
    [aria-label]="translations.closeDialog"
    [attr.tabindex]="showDialogClose ? 0 : -1"
  >
    <mat-icon svgIcon="dls-times-plain-light" aria-hidden="true"></mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-content" id="dialog-content">
    <iframe
      fxHide.lt-md
      class="popup-video"
      [attr.src]="url | sanitize"
    ></iframe>
    <iframe
      fxHide.gt-sm
      class="mobile-popup-video"
      [attr.src]="url | sanitize"
    ></iframe>
  </div>
</mat-dialog-content>
