import { SavedProvidersState, initialState } from './saved-providers.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as SavedProviderActions from './saved-providers.actions';
import { SavedProvider } from '@interfaces/saved-provider.model';

export const SavedProvidersReducer = createReducer(
  initialState,
  on(SavedProviderActions.requestSavedProviders, (state) => ({
    ...state,
    loading: true,
    savedProviders: [],
    error: null,
  })),
  on(
    SavedProviderActions.requestSavedProvidersSuccess,
    (state, { results }) => ({
      ...state,
      loading: false,
      savedProviders: [...results],
      error: null,
    })
  ),
  on(SavedProviderActions.requestSavedProvidersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    savedProviders: [],
    error: error,
  })),
  on(SavedProviderActions.setSavedProvidersDisabled, (state) => ({
    ...state,
    enabled: false,
    loading: false,
    savedProviders: [],
    error: null,
  })),
  on(SavedProviderActions.setSavedProvidersEnabled, (state, { max }) => ({
    ...state,
    enabled: true,
    loading: true,
    savedProviders: [],
    error: null,
    max: max,
  })),
  on(
    SavedProviderActions.addProviderToSavedProviders,
    (state, { providerCCID }) => ({
      ...state,
      savedProviders: [
        ...state.savedProviders,
        new SavedProvider({
          client_canonical_id: providerCCID,
          updating: true,
        }),
      ],
    })
  ),
  on(
    SavedProviderActions.addSavedProvidersSuccess,
    (state, { savedProvider }) => ({
      ...state,
      error: null,
      savedProviders: [
        ...state.savedProviders.filter(
          (_savedProvider) =>
            _savedProvider.client_canonical_id !==
            savedProvider.client_canonical_id
        ),
        savedProvider,
      ],
    })
  ),
  on(
    SavedProviderActions.addSavedProvidersFailure,
    (state, { error, providerCCID }) => ({
      ...state,
      savedProviders: state.savedProviders.filter(
        (savedProvider) => savedProvider.client_canonical_id !== providerCCID
      ),
      error: error,
    })
  ),
  on(
    SavedProviderActions.removeProviderFromSavedProviders,
    (state, { savedProvider }) => ({
      ...state,
      savedProviders: [
        ...state.savedProviders.filter(
          (_savedProvider) =>
            _savedProvider.client_canonical_id !==
            savedProvider.client_canonical_id
        ),
        new SavedProvider({ ...savedProvider, updating: true }),
      ],
    })
  ),
  on(
    SavedProviderActions.removeSavedProvidersFailure,
    (state, { error, savedProvider }) => ({
      ...state,
      error: error,
      savedProviders: [
        ...state.savedProviders.filter(
          (_savedProvider) =>
            _savedProvider.client_canonical_id !==
            savedProvider.client_canonical_id
        ),
        savedProvider,
      ],
    })
  ),
  on(
    SavedProviderActions.removeSavedProvidersSuccess,
    (state, { providerCCID }) => ({
      ...state,
      error: null,
      savedProviders: state.savedProviders.filter(
        (_savedProvider) => _savedProvider.client_canonical_id !== providerCCID
      ),
    })
  )
);

export const reducer = (state: SavedProvidersState, action: Action) => {
  return SavedProvidersReducer(state, action);
};
