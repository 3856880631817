import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SavedProvidersState } from './saved-providers.state';
import { SavedProvider } from '@interfaces/saved-provider.model';

export const savedProvidersState =
  createFeatureSelector<SavedProvidersState>('saved_providers');

export const getSavedProvidersState = createSelector(
  savedProvidersState,
  (state: SavedProvidersState) => {
    return state;
  }
);

export const getSavedProvidersLoading = createSelector(
  getSavedProvidersState,
  (state: SavedProvidersState) => state.loading
);

export const getSavedProviders = createSelector(
  getSavedProvidersState,
  (state: SavedProvidersState) => {
    if (!state.loading) {
      return state.savedProviders;
    }
  }
);

export const getSavedProvidersEnabled = createSelector(
  getSavedProvidersState,
  (state: SavedProvidersState) => {
    return state.enabled;
  }
);

export const getSavedProvidersMaxReached = createSelector(
  getSavedProvidersState,
  (state: SavedProvidersState) => {
    if (!state.loading) {
      return state.savedProviders.length >= state.max;
    }
  }
);

export const getSavedProvidersBtnData = createSelector(
  getSavedProvidersEnabled,
  getSavedProviders,
  getSavedProvidersMaxReached,
  (enabled: boolean, savedProviders: SavedProvider[], maxReached: boolean) => {
    return {
      enabled: enabled,
      savedProviders: savedProviders,
      maxReached: maxReached,
    };
  }
);

export const getSavedProvidersData = createSelector(
  getSavedProviders,
  getSavedProvidersState,
  (savedProviders: SavedProvider[], state: SavedProvidersState) => {
    return {
      savedProviders,
      max: state.max,
    };
  }
);
