import { HttpMockConfigIgnoredQueryParam } from '@interfaces/http-mock-config-ignored-query-param.interface';

export class HttpMockConfig {
  // Key name used for injecting a spec mock config into a page request URL and storing to localStorage
  public keyName: string = 'e2e_spec_mock_config';

  // Client domains to use for recording and mock matching
  public clientDomains: any = {
    product: 'https://demo-a.product.sapphirecareselect.com',
    bcbsnc: 'https://qa-mz.bcbsnc.aws.mdx.med',
    demo: 'https://qa-mz.demo.aws.mdx.med',
  };

  // Default client to use for recording and mock matching
  public defaultClient: string = 'product';

  // Only attempt to find a mock for request URLs where the first path segment matches any of these
  public mockedPaths: string[] = ['api', 'auth', 'share'];

  // Ignore requests from these URLs and always return an empty result
  public ignoredRequestUrls: string[] = [
    '/api/configuration_profile/settings/ada_amenities.json',
    '/api/configuration_profile/settings/affiliated_medical_groups.json',
    '/api/configuration_profile/settings/ancillary_links.json',
    '/api/configuration_profile/settings/customer_service_info_layer.json',
    '/api/configuration_profile/settings/default_member.json',
    '/api/configuration_profile/settings/enable_messages.json',
    '/api/configuration_profile/settings/gated_entry.json',
    '/api/configuration_profile/settings/has_home_footnote.json',
    '/api/configuration_profile/settings/has_pcp_distinction.json',
    '/api/configuration_profile/settings/mlelite_show_benefits.json',
    '/api/configuration_profile/settings/promotional_procedures.json',
    '/api/configuration_profile/settings/show_cultural_competency.json',
    '/api/configuration_profile/settings/smartshopper.json',
    '/api/configuration_profile/settings/suppress_unauthenticated_procedure_tile.json',
    '/api/configuration_profile/settings/suppress_tier_why.json',
    '/api/configuration_profile/settings/telehealth_code.json',
    '/api/configuration_profile/settings/tn_quality_ratings.json',
    '/api/configuration_profile/settings/tout_network_selection.json',
    '/api/configuration_profile/settings/unauthenticated_entry.json',
    '/api/configuration_profile/settings/network_selection_dropdown.json',
    '/api/log_event/',
  ];

  // Ignore these query params when matching a request URL to a mock recording
  // If a pattern is given, only ignore part of the value that matches the pattern.
  public ignoredQueryParams: HttpMockConfigIgnoredQueryParam[] = [
    { key: this.keyName },
    { key: 'transaction_id' },
    { key: 'filters' },
    { key: 'cache' },
    { key: 'sort', pattern: / random \d+/g },
    { key: 'facet[has_incentive]' },
    { key: 'limit' },
    { key: 'radius' },
    { key: 'page' },
    { key: 'average_wait_time' },
    { key: 'professional_gender' },
  ];

  // Request headers to use when recording new mocks
  public requestHeaders: string[] = ['X-XSRF-TOKEN', 'cookie', '_session_id'];
}
