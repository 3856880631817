import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import { ChatMessageModule } from '../chat-message/chat-message.module';
import { DlsButtonModule } from '@zelis/dls/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxAutoScrollModule } from 'ngx-auto-scroll';
import { TranslateModule } from '@ngx-translate/core';
import { UnfocusModule } from '../../../directives/unfocus/unfocus.module';
import { DlsProgressSpinnerModule } from '@zelis/dls/progress-spinner';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  imports: [
    CommonModule,
    ChatMessageModule,
    DlsButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgxAutoScrollModule,
    TranslateModule,
    UnfocusModule,
    DlsProgressSpinnerModule,
    DlsIconModule,
  ],
  declarations: [ChatComponent],
  exports: [ChatComponent],
})
export class ChatModule {}
