import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MemberActivitiesState } from './member-activities.state';

const selectMemberActivitiesState =
  createFeatureSelector<MemberActivitiesState>('member-activities');

export const selectAllMemberActivities = createSelector(
  selectMemberActivitiesState,
  (state) => state.memberActivities
);

export const selectActivitiesLoading = createSelector(
  selectMemberActivitiesState,
  (state) => state.loading
);

export const selectAllMemberActivitiesError = createSelector(
  selectMemberActivitiesState,
  (state) => state.error
);

export const selectActiveMemberActivitiesCount = createSelector(
  selectMemberActivitiesState,
  (state) => state.activeCount
);

export const selectActiveMemberActivitiesEnabled = createSelector(
  selectMemberActivitiesState,
  (state) => state.enabled
);

export const selectActivityDetails = createSelector(
  selectMemberActivitiesState,
  (state) => state.activityDetails
);

export const selectActiveStep = createSelector(
  selectActivityDetails,
  (activityDetails) => {
    if (!activityDetails || !activityDetails.steps) {
      return null;
    }
    return (
      activityDetails.steps.find((step) => step.state === 'pending') ||
      activityDetails.steps[0] ||
      null
    );
  }
);

export const selectActivityDetailsError = createSelector(
  selectMemberActivitiesState,
  (state) => state.error
);

export const selectActivitiesSuppressIllustrations = createSelector(
  selectMemberActivitiesState,
  (state) => state.suppressIllustrations
);
