import { map, filter, switchMap, first, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, of, combineLatest } from 'rxjs';
import { AppParamsService } from '@services/app.params.service';
import { ConfigurationService } from '@services/configuration.service';
import { Specialty } from '@interfaces/specialty.model';
import { AppParams } from '@interfaces/app.interface.appParams';
import { SettingsService } from '@services/settings.service';
import { AllowableNetworks } from '@interfaces/allowable-networks.interface';
import { find, isArray, extend } from 'lodash';
import { Store, select } from '@ngrx/store';
import { NetworkStoreSelectors } from '@store/network';
import { AppState } from '@state/app.state';

@Injectable({
  providedIn: 'root',
})
export class SpecialtiesService {
  public specialties: BehaviorSubject<Specialty[]> = new BehaviorSubject(
    undefined
  );

  constructor(
    private http: HttpClient,
    private appParams: AppParamsService,
    private configurationService: ConfigurationService,
    private settingsService: SettingsService,
    private store: Store<AppState>
  ) {}

  public requestSpecialties(
    id?: string,
    params: AppParams = {}
  ): Observable<Specialty[]> {
    if (id && this.specialties.value) {
      const found = this.specialties.value.find(
        (spec) => spec.id === parseInt(id, 10)
      );
      if (found) {
        return of([found]);
      }
    }
    return combineLatest([
      this.getConfigSig(),
      this.getAppParams(),
      this.getAllowableNetworks(),
    ]).pipe(
      withLatestFrom(
        this.store.pipe(select(NetworkStoreSelectors.getResolvedNetwork))
      ),
      switchMap(([[sig, appParams, allowable], network]) => {
        if (!appParams) {
          return of(null);
        }
        let net_id = network.id;
        if (params.network_id) {
          net_id = params.network_id;
        }
        if (net_id === '0') {
          net_id = this.getFirstAllowableNetwork(allowable);
        }
        let defaultParams = {
          config_signature: sig,
          network_id: net_id,
        };
        const { network_id, ...paramsWithoutNet } = params;
        if (paramsWithoutNet) {
          defaultParams = extend({}, defaultParams, paramsWithoutNet);
        }
        let requestParams = new HttpParams({
          fromObject: defaultParams,
        });
        if (id) {
          requestParams = requestParams.set('search_specialty_id', id);
        }

        return this.http
          .get('/api/search_specialties.json', {
            params: requestParams,
          })
          .pipe(
            map((results) => {
              if (id) {
                return [
                  results['search_specialties'].find(
                    (spec) => spec.id === parseInt(id, 10)
                  ),
                ];
              } else {
                this.specialties.next(results['search_specialties']);
                return results['search_specialties'];
              }
            })
          );
      })
    );
  }

  private getAppParams(): Observable<AppParams> {
    return this.appParams.resolved.pipe(filter((params) => !!params));
  }

  private getConfigSig(): Observable<string> {
    return this.configurationService.signatureAppParamsResolved.pipe(
      filter((sig) => !!sig),
      switchMap((sig) =>
        this.configurationService.signatureResolved().pipe(map(() => sig))
      ),
      first((sig) => !!sig)
    );
  }

  private getAllowableNetworks(): Observable<AllowableNetworks> {
    return this.settingsService.getSetting('allowable_networks');
  }

  private getFirstAllowableNetwork(
    allowableNetworks: AllowableNetworks
  ): string {
    const allowableNetworksArray = isArray(allowableNetworks)
      ? allowableNetworks
      : [allowableNetworks];
    if (find(allowableNetworksArray[0])[0]) {
      return find(allowableNetworksArray[0])[0].id;
    } else {
      return '0';
    }
  }
}
