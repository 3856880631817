import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconSvg } from '../interfaces/mat-icon-svg.interface';

@Injectable({
  providedIn: 'root',
})
export class MatIconRegistryService {
  private svgs: MatIconSvg[] = [
    { name: `mdx-spyglass`, path: `/assets/images/search.svg` },
    { name: `mdx-takes-your-card`, path: `/assets/images/takes_your_card.svg` },
    { name: `mdx-find-plan`, path: `/assets/images/find_plan.svg` },
    { name: `mdx-questionnaire`, path: `/assets/images/questionnaire.svg` },
    { name: `mdx-clear`, path: `/assets/images/clear.svg` },
    {
      name: `close-in-red-circle`,
      path: `/assets/images/close-in-red-circle.svg`,
    },
    {
      name: `check-in-gray-circle`,
      path: `/assets/images/check-in-gray-circle.svg`,
    },
    {
      name: `magnifier-with-money`,
      path: `/assets/images/magnifier-with-money.svg`,
    },
    { name: `dollar-pin-white`, path: `/assets/images/dollar-pin-white.svg` },
    { name: `dollar-pin-black`, path: `/assets/images/dollar-pin-black.svg` },
    { name: `green-checkmark`, path: `/assets/images/green-checkmark.svg` },
    { name: `circle-1`, path: `/assets/images/circle-1.svg` },
    { name: `circle-2`, path: `/assets/images/circle-2.svg` },
    { name: `circle-3`, path: `/assets/images/circle-3.svg` },
    { name: `horizontal-link`, path: `/assets/images/horizontal-link.svg` },
    { name: 'paper-plane', path: '/assets/images/paper-plane.svg' },
    { name: 'person-outline', path: '/assets/images/person_outline.svg' },
    {
      name: 'person-outline-add',
      path: '/assets/images/person_outline_add.svg',
    },
    { name: 'filled-arrow', path: '/assets/images/filled-arrow.svg' },
    { name: 'tier-badge', path: '/assets/images/tier-badge-icon-white.svg' },
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  public addSvgIcons(): void {
    this.svgs.forEach((svg: MatIconSvg) => {
      this.matIconRegistry.addSvgIcon(
        svg.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(svg.path)
      );
    });
  }
}
