import { Component, Input, OnChanges } from '@angular/core';
import { AuthStatus } from '@interfaces/auth-status.model';
import { RouteUtilities } from '@utilities/route.utilities';
import { ProductAnalyticsService } from '@services/product-analytics/product-analytics.service';
import { StorageUtilities } from '@utilities/storage.utilities';

@Component({
  selector: 'app-login-prompt',
  templateUrl: './login-prompt.component.html',
  styleUrls: ['./login-prompt.component.scss'],
})
export class LoginPromptComponent implements OnChanges {
  @Input() public auth: AuthStatus;
  @Input() public headlineKey: string;
  @Input() public subHeadlineKey: string;
  @Input() public noAccountKey: string;
  @Input() public loginPromptKey: string;

  public authUrl: string;

  constructor(
    private productAnalyticsService: ProductAnalyticsService,
    private storage: StorageUtilities = new StorageUtilities()
  ) {}

  ngOnChanges() {
    this.noAccountKey =
      this.noAccountKey || 'profile_reviews_login_prompt_no_account';
    this.loginPromptKey =
      this.loginPromptKey || 'profile_reviews_login_prompt_signup';
    this.setAuthUrl();
  }

  public clickLoginButton(): void {
    this.productAnalyticsService.setAuthenticationPage('Pop-up CTA');
    this.storage.sessionStorageSet('guidedSearchPrompt', true);
  }

  private setAuthUrl(): void {
    this.authUrl = this.auth.url;
  }
}
