import { Action, createReducer, on } from '@ngrx/store';
import {
  SearchFiltersV2State,
  initialState,
} from '@store/search-filters-v2/search-filters-v2.state';
import * as SearchFiltersV2Actions from './search-filters-v2.actions';

export const searchFiltersFromConfigSettingsReducer = createReducer(
  initialState,
  on(
    SearchFiltersV2Actions.requestSettingsSearchFiltersFromConfig,
    (state) => ({
      ...state,
      configFilters: { loading: true, filters: null, error: null },
    })
  ),
  on(
    SearchFiltersV2Actions.requestSettingsSearchFiltersFromConfigSuccess,
    (state, { filters }): any => {
      return {
        ...state,
        configFilters: { loading: false, filters: [...filters], error: null },
      };
    }
  ),
  on(
    SearchFiltersV2Actions.requestSettingsSearchFiltersFromConfigFailure,
    (state, { error }) => ({
      ...state,
      configFilters: { loading: false, filters: null, error: error.message },
    })
  ),
  on(
    SearchFiltersV2Actions.setSearchFiltersWithFacetData,
    SearchFiltersV2Actions.setSearchFiltersWithSerpLiteFacetData,
    (state, { appliedFilters }) => ({
      ...state,
      appliedFilters: { loading: false, filters: appliedFilters, error: null },
    })
  ),
  on(
    SearchFiltersV2Actions.requestFacets,
    SearchFiltersV2Actions.requestSerpLiteFacets,
    (state) => ({
      ...state,
      appliedFilters: { loading: true, filters: null, error: null },
    })
  ),
  on(
    SearchFiltersV2Actions.requestFacetsFailure,
    SearchFiltersV2Actions.requestSerpLiteFacetsFailure,
    (state, { error }) => ({
      ...state,
      appliedFilters: { loading: false, filters: null, error: error.message },
    })
  ),
  on(
    SearchFiltersV2Actions.setSearchFiltersWithBaseFacetData,
    (state, { baseFilters }) => ({
      ...state,
      baseFilters: { loading: false, filters: baseFilters, error: null },
    })
  ),
  on(SearchFiltersV2Actions.requestBaseFacets, (state) => ({
    ...state,
    baseFilters: { loading: true, filters: null, error: null },
  })),
  on(SearchFiltersV2Actions.requestBaseFacetsFailure, (state, { error }) => ({
    ...state,
    baseFilters: { loading: false, filters: null, error: error.message },
  })),
  on(
    SearchFiltersV2Actions.setSelectedFilters,
    (state, { selectedFilters }) => ({
      ...state,
      selectedFilters: { ...state.selectedFilters, ...selectedFilters },
      loading: false,
      error: null,
    })
  ),
  on(SearchFiltersV2Actions.resetSelectedFilters, (state) => ({
    ...state,
    selectedFilters: null,
  }))
);

export const reducer = (state: SearchFiltersV2State, action: Action) => {
  return searchFiltersFromConfigSettingsReducer(state, action);
};
