import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { MemberParams } from '@interfaces/member-params.model';
import { Observable, BehaviorSubject, combineLatest, of } from 'rxjs';
import { AppParams } from '@interfaces/app.interface.appParams';
import { MemberDependent } from '@interfaces/member-dependent.model';
import { filter, map, switchMap } from 'rxjs/operators';
import { each, includes, isEmpty } from 'lodash';
import { UserSelectedCritical } from '@interfaces/user-selected-critical.model';
import { Place } from '@classes/place.class';
import { RouteUtilities } from '@utilities/route.utilities';
import { StorageUtilities } from '@utilities/storage.utilities';

@Injectable({
  providedIn: 'root',
})
export class AppParamsService {
  public params: AppParams;
  public resolved: BehaviorSubject<AppParams> = new BehaviorSubject(null);

  private memberParams: BehaviorSubject<MemberParams> = new BehaviorSubject(
    null
  );
  private seed: number;

  constructor(
    private routeUtilities: RouteUtilities,
    private storage: StorageUtilities
  ) {}

  public onRouteChange(route: ActivatedRoute): Observable<AppParams> {
    return combineLatest([route.params, route.queryParams]).pipe(
      switchMap(([paramsVal, queryVal]) => {
        if (!isEmpty(paramsVal) || !isEmpty(queryVal)) {
          this.params = new AppParams(Object.assign({}, paramsVal, queryVal));
          this.resolved.next(this.params);
          return of(new AppParams(this.params));
        } else {
          return of(null);
        }
      })
    );
  }

  public get(): AppParams {
    return this.params;
  }

  public setHttpParams(selectedParams: any): HttpParams {
    let params: HttpParams = new HttpParams();

    if (selectedParams.sort) {
      selectedParams.sort = this.setSortRandom(selectedParams.sort);
    }

    each(selectedParams, (value, key) => {
      if (value) {
        params = params.set(key, value);
      }
    });

    return params;
  }

  public setSortRandom(sort: string): string {
    if (includes(sort, 'random') && sort.search(/\b\d+$/) === -1) {
      if (!this.seed) {
        this.seed = Math.floor(Math.random() * 1000000) + 1;
      }
      sort += ' ' + this.seed;
    }

    return sort;
  }

  public setProfileType(id: string): string {
    if (id && id[0] && id[0] === 'f') {
      return 'facility';
    } else if (id && id[0] && id[0] === 'p') {
      return 'professional';
    }
  }

  public setMemberParams(member: MemberDependent): void {
    this.memberParams.next(new MemberParams(member));
    if ('medical_group_numbers' in member) {
      this.storage.sessionStorageSet(
        'medicalGroupNos',
        member.medical_group_numbers
      );
    }
    this.setSelectedMember(member);
  }

  public getMemberParams(): any {
    return this.memberParams.getValue();
  }

  public getSelectedMember(): Observable<MemberParams> {
    return this.memberParams.asObservable();
  }

  public listenToSelectedMemberNumber(): Observable<string> {
    return this.getSelectedMember().pipe(
      filter((member) => !!member),
      map((member) => member.member_number)
    );
  }

  public getSelectedMemberNumber(): string {
    return this.storage.sessionStorageGet('selectedMemberNumber') || null;
  }

  public getUserSelectedCritical(): UserSelectedCritical[] {
    return this.storage.localStorageGet('userSelectedCritical');
  }

  public setUserSelectedCritical(type: string, value: string | Place): void {
    const ci = this.routeUtilities.getParamFromUrl('ci');
    const userSavedCritical: UserSelectedCritical[] =
      this.getUserSelectedCritical();
    // Replace location or network value if matching ci critical exists, otherwise set or push a new one
    const userSelectedCritical =
      userSavedCritical && userSavedCritical.length >= 1
        ? this.setCriticalValue(ci, type, value, userSavedCritical)
        : this.createNewCriticals(ci, type, value);

    this.storage.localStorageSet('userSelectedCritical', userSelectedCritical);
  }

  private setCriticalValue(
    ci: string,
    type: string,
    value: string | Place,
    userSavedCritical: UserSelectedCritical[]
  ): UserSelectedCritical[] {
    const matchingSavedCriticalIndex = userSavedCritical.findIndex(
      (crit) => crit.ci === ci
    );
    if (matchingSavedCriticalIndex >= 0) {
      userSavedCritical[matchingSavedCriticalIndex][type] = value;
    } else {
      const newCritical = { ci: ci };
      newCritical[type] = value;
      userSavedCritical.push(newCritical);
    }
    return userSavedCritical;
  }

  private createNewCriticals(
    ci: string,
    type: string,
    value: string | Place
  ): UserSelectedCritical[] {
    const newCritical = [];
    newCritical.push({ ci: ci });
    newCritical[0][type] = value;
    return newCritical;
  }

  private setSelectedMemberNumberToSessionStorage(memberNumber: string): void {
    this.storage.sessionStorageRemove('selectedMemberNumber');
    if (memberNumber) {
      this.storage.sessionStorageSet('selectedMemberNumber', memberNumber);
    }
  }

  private setSelectedMember(member: MemberDependent): void {
    if (member.isPrimary) {
      // Use SSO if selected member is primary
      this.clearSelectedMember();
    } else {
      this.setSelectedMemberNumberToSessionStorage(member.member_number);
    }
  }

  private clearSelectedMember(): void {
    this.storage.sessionStorageRemove('selectedMemberNumber');
  }
}
