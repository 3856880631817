import { Action, createReducer, on } from '@ngrx/store';
import * as MemberActivitiesActions from './member-activities.actions';
import { MemberActivitiesState, initialState } from './member-activities.state';

export const MemberActivitiesReducer = createReducer(
  initialState,
  on(MemberActivitiesActions.loadMemberActivities, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    MemberActivitiesActions.loadMemberActivitiesSuccess,
    (state, { memberActivities }) => ({
      ...state,
      memberActivities,
      activeCount: memberActivities.active.length,
      error: null,
      loading: false,
    })
  ),
  on(
    MemberActivitiesActions.loadMemberActivitiesFailure,
    (state, { error }) => ({
      ...state,
      memberActivities: null,
      error,
      loading: false,
    })
  ),
  on(
    MemberActivitiesActions.setMemberActivitiesEnabled,
    (state, { enabled }) => ({
      ...state,
      enabled,
    })
  ),
  on(MemberActivitiesActions.loadActivityDetails, (state) => ({
    ...state,
    activityDetails: null,
    error: null,
  })),
  on(
    MemberActivitiesActions.loadActivityDetailsSuccess,
    (state, { activityDetails }) => ({
      ...state,
      activityDetails,
      error: null,
    })
  ),
  on(
    MemberActivitiesActions.loadActivityDetailsFailure,
    (state, { error }) => ({
      ...state,
      activityDetails: null,
      error,
    })
  ),
  on(
    MemberActivitiesActions.setActivitiesSuppressIllustrations,
    (state, { suppressIllustrations }) => ({
      ...state,
      suppressIllustrations,
    })
  )
);
export const reducer = (state: MemberActivitiesState, action: Action) => {
  return MemberActivitiesReducer(state, action);
};
