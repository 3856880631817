import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MobileHamburgerMenuComponent } from './mobile-hamburger-menu.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  declarations: [MobileHamburgerMenuComponent],
  imports: [CommonModule, TranslateModule, MatDialogModule, DlsIconModule],
  providers: [],
  exports: [MobileHamburgerMenuComponent],
})
export class MobileHamburgerMenuModule {}
