import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PrintData } from '@interfaces/print.model';

export const selectPrintState = createFeatureSelector<PrintData>('print');

export const selectPrintData = createSelector(
  selectPrintState,
  (state: PrintData) => state
);

export const selectPrintDataById = (id: number) =>
  createSelector(selectPrintState, (state: PrintData) => state[id]);
