import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalHeaderComponent } from './global-header.component';
import { MobileHamburgerMenuModule } from '../mobile-hamburger-menu/mobile-hamburger-menu.module';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { PageContainerModule } from '@zelis/platform-ui-components';
import { SkipLinksModule } from '../skip-link/skip-links.module';
import { GlobalLanguageSelectModule } from './language/global-language-select.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { GlobalHeaderAutosuggestModule } from './autosuggest/global-header-autosuggest.module';
import { GlobalNetworkDropdownModule } from './network/global-network-dropdown.module';
import { GlobalLocationSelectModule } from './location/global-location-select.module';
import { MobileActionButtonsModule } from '../mobile-action-buttons';
import { GuidedSearchTriggerModule } from '@components/guided-search/guided-search-trigger/guided-search-trigger.module';
import { DlsButtonModule } from '@zelis/dls/button';
import { DlsNavButtonModule } from '@zelis/dls/nav-button';
import { SaveForPrintModule } from '@components/save-for-print/save-for-print.module';
import { NetworkSelectionDropdownModule } from '@components/network-selection-dropdown/network-selection-dropdown.module';
import { UnfocusModule } from '@directives/unfocus/unfocus.module';
import { DlsBadgeModule } from '@zelis/dls/badge';

@NgModule({
  declarations: [GlobalHeaderComponent],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    PageContainerModule,
    SkipLinksModule,
    GlobalLanguageSelectModule,
    MatButtonModule,
    MatMenuModule,
    GlobalHeaderAutosuggestModule,
    GlobalNetworkDropdownModule,
    GlobalLocationSelectModule,
    MobileActionButtonsModule,
    MobileHamburgerMenuModule,
    MatIconModule,
    GuidedSearchTriggerModule,
    DlsButtonModule,
    DlsNavButtonModule,
    SaveForPrintModule,
    NetworkSelectionDropdownModule,
    UnfocusModule,
    DlsBadgeModule,
  ],
  providers: [],
  exports: [GlobalHeaderComponent],
})
export class GlobalHeaderModule {}
