import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import {
  switchMap,
  catchError,
  filter,
  shareReplay,
  map,
  tap,
} from 'rxjs/operators';
import { RouteUtilities } from '@utilities/route.utilities';
import { ParsedUrl } from '@interfaces/parsed-url.model';
import { StringifyParsedUrlOptions } from '@interfaces/stringify-parsed-url-options.model';
import { HttpMockConfig } from './http.mock.config';
import { AppConfigService } from '@services/app.config.service';
import { HttpSpecMock } from '@interfaces/http-spec-mock.model';
import { HttpSpecMockConfigService } from '@services/http-spec-mock-config/http-spec-mock-config.service';
import { HttpMockIndex } from '@interfaces/http-mock-index.interface';
import { HttpMockIndexItem } from '@interfaces/http-mock-index-item.model';
import { HttpMockResult } from '@interfaces/http-mock-result.interface';
import { StorageUtilities } from '@utilities/storage.utilities';

@Injectable({
  providedIn: 'root',
})
export class HttpMockInterceptorService {
  private cache: Map<string, Observable<HttpEvent<any>>> = new Map();
  private routeUtilities: RouteUtilities = new RouteUtilities();
  private storageUtilities: StorageUtilities = new StorageUtilities();
  private config: HttpMockConfig = new HttpMockConfig();
  private mockMatchStringifyUrlOptions: StringifyParsedUrlOptions = {
    fullyQualified: false,
    includeSearch: true,
    excludeQueryParams: this.config.ignoredQueryParams,
  };
  private vcrIndex: HttpMockIndex;
  private recordingStorageKey: string = 'new_vcr_recordings';

  constructor(
    private appConfigService: AppConfigService,
    private specMockConfigService: HttpSpecMockConfigService
  ) {
    this.loadVcrIndex();
  }

  // TODO: Support mock recording (allow_recording)
  // TODO: Support mock recording login: mockUtilities.login('NC_PH_II_SG_LG_JACKSON_LTD_BSP_GRP3_TDM_SUB');

  public getMockRecording(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const parsedRequestUrl: ParsedUrl = this.routeUtilities.parseUrl(
      request.urlWithParams
    );
    // If request path should be mocked...
    if (this.isMockedPath(parsedRequestUrl)) {
      // Get spec mock or mock recording
      const originalRequest = request.clone();
      return this.handleGetMockRecording(
        parsedRequestUrl,
        request,
        next,
        originalRequest
      );
    }
    return;
  }

  private loadVcrIndex(): void {
    this.vcrIndex =
      require('../../../../platform-ui-2-e2e/src/fixtures/vcr-index.json') as HttpMockIndex;
  }

  private getSpecMockedHttpResponse(
    specMock: HttpSpecMock
  ): Observable<HttpEvent<any>> {
    if (this.isSuccessStatusCode(specMock.statusCode)) {
      return of(
        new HttpResponse({
          status: specMock.statusCode,
          body: specMock.mock,
        })
      );
    }
    return throwError(
      () =>
        new HttpErrorResponse({
          status: specMock.statusCode,
          error: specMock.mock,
        })
    );
  }

  private getSpecMock(parsedRequestUrl: ParsedUrl): HttpSpecMock {
    if (this.specMockConfigService.config.specMocks) {
      const stringifyUrlOptions: StringifyParsedUrlOptions = {
        fullyQualified: false,
        includeSearch: true,
      };
      const mockUrl: string = this.routeUtilities.stringifyParsedUrl(
        parsedRequestUrl,
        stringifyUrlOptions
      );

      return this.findSpecMock(mockUrl) || null;
    }
    return null;
  }

  private findSpecMock(url: string): HttpSpecMock {
    let specMock: HttpSpecMock;
    this.specMockConfigService.config.specMocks.forEach((mock) => {
      const urlMatches = this.routeUtilities.matchUrl(url, mock.url);
      const queryParamsMatch = this.routeUtilities.matchUrlQueryParams(
        url,
        mock.queryParams
      );
      if (
        urlMatches &&
        queryParamsMatch &&
        (!specMock || (specMock && mock.queryParams))
      ) {
        specMock = mock;
      }
    });
    return specMock;
  }

  private getMockIndexKey(requestUrl: string): string {
    return `${this.getClientDomain()}|${this.getContext()}|${requestUrl}`;
  }

  private getMockIndex(requestUrl: string): HttpMockIndexItem {
    const indexKey = this.getMockIndexKey(requestUrl);
    return new HttpMockIndexItem(this.vcrIndex[indexKey]);
  }

  private handleGetMockRecording(
    parsedRequestUrl: ParsedUrl,
    request: HttpRequest<any>,
    next: HttpHandler,
    originalRequest: HttpRequest<any>
  ): Observable<HttpEvent<any>> {
    const specMock: HttpSpecMock = this.getSpecMock(parsedRequestUrl);
    // If data has been mocked in the e2e spec, use that.
    if (specMock) {
      console.info(
        `Notice: Spec Mock "${specMock.url}" used for "${request.urlWithParams}". Status Code: ${specMock.statusCode}`,
        specMock
      );
      return this.getSpecMockedHttpResponse(specMock);
    }
    // ...else, if request has been ignored, return empty result.
    if (this.isIgnoredRequest(parsedRequestUrl)) {
      console.info(
        `Notice: Mock request ignored for "${request.urlWithParams}". Using null result.`
      );
      return this.getEmptyResultResponse();
    }
    // ...otherwise, look for mock in mock recording.
    return this.requestMockRecording(
      parsedRequestUrl,
      request,
      next,
      originalRequest
    );
  }

  private requestMockRecording(
    parsedRequestUrl: ParsedUrl,
    request: HttpRequest<any>,
    next: HttpHandler,
    originalRequest: HttpRequest<any>
  ): Observable<HttpEvent<any>> {
    const requestUrl: string = this.routeUtilities.stringifyParsedUrl(
      parsedRequestUrl,
      this.mockMatchStringifyUrlOptions
    );
    const mockIndex: HttpMockIndexItem = this.getMockIndex(requestUrl);
    // Get cached mock response or request new and cache response
    if (mockIndex && mockIndex.file && mockIndex.index >= 0) {
      const mockResponse = this.getMockRecordingResponse(
        mockIndex.file,
        request,
        requestUrl,
        next
      );
      return mockResponse.pipe(
        filter((event) => event instanceof HttpResponse),
        catchError((error) =>
          this.handleMockRecordingError(error, mockIndex.file, requestUrl)
        ),
        switchMap((event: HttpResponse<any>) =>
          this.handleMockRecording(event, mockIndex, parsedRequestUrl)
        )
      );
    }
    return this.handleNewMockRecording(requestUrl, originalRequest, next);
  }

  private getMockRecordingResponse(
    mockUrl: string,
    request: HttpRequest<any>,
    requestUrl: string,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Get cache
    const cache = this.getCache(mockUrl);
    if (cache) {
      return cache;
    }
    // Add X-Mock-Request-Url header for easier mock request debugging
    const mockRequest = request.clone({
      method: 'GET',
      url: mockUrl,
      params: new HttpParams(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: new HttpHeaders({ 'X-Mock-Request-Url': requestUrl }),
    });
    // Cache request
    const mockResponse = next.handle(mockRequest);
    this.putCache(mockUrl, mockResponse);
    return mockResponse;
  }

  private handleMockRecording(
    event: HttpResponse<any>,
    mockIndex: HttpMockIndexItem,
    parsedRequestUrl: ParsedUrl
  ): Observable<HttpEvent<any>> {
    const requestUrl = this.routeUtilities.stringifyParsedUrl(
      parsedRequestUrl,
      this.mockMatchStringifyUrlOptions
    );
    const mockResponse = this.getMockResponseFromResult(event.body, mockIndex);
    if (mockResponse) {
      const statusCode = parseInt(String(mockResponse.status), 10);
      console.info(
        `Notice: E2E VCR found "${mockIndex.file}" and used for "${requestUrl}".`,
        mockResponse
      );
      if (this.isSuccessStatusCode(statusCode)) {
        return of(
          new HttpResponse({
            body: mockResponse.body,
            status: statusCode,
          })
        );
      }
      console.info(
        `Notice: E2E VCR found "${mockIndex.file}", but returned error code "${statusCode}" and used for "${requestUrl}".`,
        mockResponse
      );
      return throwError(
        () =>
          new HttpErrorResponse({
            error: mockResponse.body,
            status: statusCode,
          })
      );
    }
    console.warn(
      `Warning: E2E VCR found "${mockIndex.file}", but no match for request "${requestUrl}". Using null result.`
    );
    return this.getEmptyResultResponse();
  }

  private handleMockRecordingError(
    error: any,
    mockUrl: string,
    requestUrl: string
  ): Observable<any> {
    console.warn(
      `Warning: E2E VCR not found "${mockUrl}" for request "${requestUrl}".`
    );
    return throwError(() => error);
  }

  private handleNewMockRecording(
    requestUrl: string,
    originalRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.appConfigService.config.pipe(
      switchMap((appConfig) => {
        if (appConfig.e2e_allow_recording) {
          return next.handle(originalRequest).pipe(
            filter((event) => event instanceof HttpResponse),
            tap((event) => this.recordNewVcrMock(event as HttpResponse<any>))
          );
        }
        console.warn(
          `Warning: E2E VCR index not found for request "${requestUrl}". New recording or re-indexing needed?`
        );
        return this.get404ResultResponse();
      })
    );
  }

  private recordNewVcrMock(event: HttpResponse<any>): void {
    if (this.isSuccessStatusCode(event.status)) {
      this.saveRecordingToStorage(event);
    } else {
      console.warn(
        `Warning: Recording skipped. New VCR recording request for "${event.url}" did not return success.`
      );
    }
  }

  private saveRecordingToStorage(event: HttpResponse<any>): void {
    const recordings: any =
      this.storageUtilities.localStorageGet(this.recordingStorageKey) || {};
    const parsedRequestUrl: ParsedUrl = this.routeUtilities.parseUrl(event.url);
    const requestUrl: string = this.routeUtilities.stringifyParsedUrl(
      parsedRequestUrl,
      this.mockMatchStringifyUrlOptions
    );
    const indexKey: string = this.getMockIndexKey(requestUrl);
    if (recordings[indexKey] === undefined) {
      recordings[indexKey] = {
        ...event,
        mock_request_url: requestUrl,
      };
      this.storageUtilities.localStorageSet(
        this.recordingStorageKey,
        recordings
      );
      console.info(`Notice: Recording new VCR mock for "${event.url}".`);
    }
  }

  private isSuccessStatusCode(status: number): boolean {
    return String(status).substring(0, 1) === '2';
  }

  private getMockResponseFromResult(
    result: HttpMockResult[],
    mockIndex: HttpMockIndexItem
  ): HttpMockResult {
    if (result && result[mockIndex.index]) {
      return result[mockIndex.index];
    }
    return null;
  }

  private isMockedPath(parsedUrl: ParsedUrl): boolean {
    const path =
      (parsedUrl &&
        parsedUrl.pathObject &&
        parsedUrl.pathObject.segments &&
        parsedUrl.pathObject.segments[0]) ||
      '';
    return path && this.config.mockedPaths.indexOf(path) !== -1;
  }

  private isIgnoredRequest(parsedRequestUrl: ParsedUrl): boolean {
    const stringifyUrlOptions: StringifyParsedUrlOptions = {
      fullyQualified: false,
      includeSearch: false,
    };
    const requestUrl = this.routeUtilities.stringifyParsedUrl(
      parsedRequestUrl,
      stringifyUrlOptions
    );
    return this.config.ignoredRequestUrls.indexOf(requestUrl) !== -1;
  }

  private getEmptyResultResponse(): Observable<HttpResponse<any>> {
    return of(
      new HttpResponse({
        status: 204,
        body: null,
      })
    );
  }

  private get404ResultResponse(): Observable<HttpResponse<HttpErrorResponse>> {
    return throwError(
      () =>
        new HttpErrorResponse({
          status: 404,
        })
    );
  }

  private getContext(): string {
    return this.specMockConfigService.config.context || '';
  }

  private getClientDomain(): string {
    const specDomain =
      this.config.clientDomains[this.specMockConfigService.config.client];
    const defaultDomain = this.config.clientDomains[this.config.defaultClient];
    if (specDomain && specDomain !== defaultDomain) {
      return specDomain;
    }
    return '';
  }

  /**
   * putCache: Create new cache item.
   * @param url: string
   * @param httpEvent: Observable<HttpEvent<any>>
   */
  private putCache(url: string, httpEvent: Observable<HttpEvent<any>>): void {
    this.cache.set(url, this.applyCacheFilter(httpEvent));
  }

  /**
   * getCache: Gets the request from the cache.
   * @param url: string
   * @returns Observable<HttpEvent<any>>
   */
  private getCache(url: string): Observable<HttpEvent<any>> {
    return this.cache.get(url);
  }

  private applyCacheFilter(
    httpEvent: Observable<HttpEvent<any>>
  ): Observable<HttpEvent<any>> {
    return httpEvent.pipe(
      // Only cache response events
      filter((event) => event instanceof HttpResponse),
      // Don't make HTTP call again
      shareReplay(1),
      // Apply header to indicate response is from cache
      map((response: HttpResponse<any>) =>
        response.clone({
          headers: response.headers.set('X-From-Cache', 'true'),
        })
      )
    );
  }
}
