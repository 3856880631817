<div
  *ngIf="!!member?.id"
  class="msa-banner-container flex flex-col md:flex-row justify-between md:items-center md:h-[100px] p-2 gap-2"
>
  <div class="msa-banner-main justify-start flex flex-col md:flex-row gap-4">
    <div>
      <p>
        {{ 'msa_banner_memberName_label' | translate }}
        {{ member?.full_name | translate }}
      </p>
      <p>
        {{ 'msa_banner_memberId_label' | translate }}
        {{ member?.subscriber_id }}
      </p>
    </div>

    <zelis-button
      *ngIf="showStartOver()"
      (clickFn)="newMember()"
      variant="stroked"
      color="system-blue"
    >
      {{ 'msa_banner_newMember_button' | translate }}
    </zelis-button>
  </div>

  <zelis-button (clickFn)="logout()" variant="stroked" color="system-blue">
    {{ 'msa_banner_logoutSession_button' | translate }}
  </zelis-button>
</div>
