export class VideoTranslations {
  closeDialog?: string;
  srTitle?: string;

  constructor(data?: VideoTranslations) {
    data = data || {};
    this.closeDialog = data.closeDialog || 'Close the dialog window';
    this.srTitle = data.srTitle || 'Watch How It Works';
  }
}
