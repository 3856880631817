import { PrintData } from '@interfaces/print.model';
import { createAction, props } from '@ngrx/store';

export enum printActionTypes {
  setPrintItemState = '[SET] Set Print Item State',
  removePrintStateItem = '[DELETE] Remove Print Item State',
  clearAllPrintItemStateAction = '[DELETE] Clear All Print Item State',
}

export const setPrintItemStateAction = createAction(
  printActionTypes.setPrintItemState,
  props<{ printState: PrintData }>()
);
export const removePrintItemStateAction = createAction(
  printActionTypes.removePrintStateItem,
  props<{ printState: PrintData }>()
);

export const clearAllPrintItemStateAction = createAction(
  printActionTypes.clearAllPrintItemStateAction
);
