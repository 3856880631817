import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MarketSegment } from '@enums/uux-marketing-plans.enum';
import { SelectionCard } from '@interfaces/network-selection-wizard-card.interface';
import { StorageUtilities } from '@utilities/storage.utilities';
import { includes, remove } from 'lodash';

@Component({
  selector: 'app-plan-type-selection',
  templateUrl: './plan-type-selection.component.html',
})
export class PlanTypeSelectionComponent implements OnInit {
  @Output() public marketSegment: EventEmitter<MarketSegment> =
    new EventEmitter();
  public segmentsWithoutPlans: MarketSegment[];
  public marketSegmentData: SelectionCard[] = [
    {
      title: 'network_selection_wizard_plan_type_employer_title',
      description: 'network_selection_wizard_plan_type_employer_description',
      ctaText: 'app_global_select',
      market_segment: MarketSegment.employer,
    },
    {
      title: 'network_selection_wizard_plan_type_retail_title',
      description: 'network_selection_wizard_plan_type_retail_description',
      ctaText: 'app_global_select',
      market_segment: MarketSegment.retail,
    },
    {
      title: 'network_selection_wizard_plan_type_medicare_title',
      description: 'network_selection_wizard_plan_type_medicare_description',
      ctaText: 'app_global_select',
      market_segment: MarketSegment.medicare,
    },
    {
      title: 'network_selection_wizard_plan_type_medicaid_title',
      description: 'network_selection_wizard_plan_type_medicaid_description',
      ctaText: 'app_global_select',
      market_segment: MarketSegment.medicaid,
    },
  ];
  private storage: StorageUtilities = new StorageUtilities();

  ngOnInit() {
    this.segmentsWithoutPlans = this.storage.sessionStorageGet(
      'marketSegmentsWithoutPlans'
    );
    this.hideTilesWithoutPlans();
  }

  public setMarketSegment(segment: MarketSegment): any {
    this.marketSegment.emit(segment);
  }

  private hideTilesWithoutPlans(): void {
    this.marketSegmentData = remove(
      this.marketSegmentData,
      (tile) => !includes(this.segmentsWithoutPlans, tile.market_segment)
    );
  }
}
