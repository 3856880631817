import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatButtonComponent } from './chat-button.component';
import { DlsButtonModule } from '@zelis/dls/button';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';
import { DlsProgressSpinnerModule } from '@zelis/dls/progress-spinner';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    DlsButtonModule,
    FlexLayoutModule,
    DlsProgressSpinnerModule,
    TranslateModule,
    MatIconModule,
  ],
  declarations: [ChatButtonComponent],
  exports: [ChatButtonComponent],
})
export class ChatButtonModule {}
