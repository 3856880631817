import { createReducer, on } from '@ngrx/store';
import * as printActions from './print.actions';
import { printDataState, printAdapter } from './print.state';

export const printReducer = createReducer(
  printDataState,
  on(printActions.setPrintItemStateAction, (state, { printState }) => {
    return printAdapter.addOne(printState, state);
  }),
  on(printActions.removePrintItemStateAction, (state, { printState }) => {
    return printAdapter.removeOne(printState.id, state);
  }),
  on(printActions.clearAllPrintItemStateAction, (state) => {
    return printAdapter.removeAll(state);
  })
);
