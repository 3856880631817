import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-guided-search-dialog',
  templateUrl: './guided-search-dialog.component.html',
  styleUrls: ['./guided-search-dialog.component.scss'],
})
export class GuidedSearchDialogComponent implements OnInit, OnDestroy {
  private destroy = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GuidedSearchDialogComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.closeDialogOnNavigation();
  }

  ngOnDestroy(): void {
    this.destroySubscriptions();
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }

  private closeDialogOnNavigation(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroy),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private destroySubscriptions(): void {
    this.destroy.next(undefined);
    this.destroy.unsubscribe();
  }
}
