import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WizardPreviousSelections } from '@interfaces/wizard-previous-selections.interface';
import { PageTransition } from '@interfaces/page-transition.interface';
import { NetworkSelectionWizardService } from '@services/network-selection-wizard/network-selection-wizard.service';

@Component({
  selector: 'app-modify-previous-selections',
  templateUrl: './modify-previous-selections.component.html',
})
export class ModifyPreviousSelectionsComponent {
  @Input() previousSelections: WizardPreviousSelections;
  @Output() public goToOverlayPage: EventEmitter<PageTransition> =
    new EventEmitter();
  public selectedOption = null;

  constructor(
    private networkSelectWizardService: NetworkSelectionWizardService
  ) {}

  public handleSelect(value: string) {
    this.selectedOption = value;
  }

  public onContinue() {
    switch (this.selectedOption) {
      case 'location':
        this.handleSubmit(false, 'location-of-care');
        return;
      case 'plan':
        this.handleSubmit(null, 'plan-type-selection');
        return;
      case 'both':
        this.handleSubmit(true, 'location-of-care');
        return;
      default:
        return;
    }
  }

  private handleSubmit(setDefaultLocationPage: boolean, wizardPage: string) {
    this.networkSelectWizardService.setDefaultLocationPage(
      setDefaultLocationPage
    );
    this.goToOverlayPage.emit({ page: wizardPage });
  }
}
