import { BillingCodeBenefitLimits } from '@interfaces/billing-code-benefit-limits.model';
import { BillingCode } from '@interfaces/billing-code.interface';
import { BillingCodePreRequisite } from '@interfaces/billing-code-pre-requisite.model';
import { createAction, props } from '@ngrx/store';
import { BillingCodeOON } from '@interfaces/billing-code-OON.model';

export enum BillingCodeSearchActionsTypes {
  selectedBillingCode = '[SET] Set selected billing code',

  loadBillingCodeBenefitLimits = '[GET] Get billing code benefit limits request',
  loadBillingCodeBenefitLimitsSuccess = '[GET] Get billing code benefit limits Success',
  loadBillingCodeBenefitLimitsFailure = '[GET] Get billing code benefit limits failed',

  loadBillingCodePreRequisite = '[GET] Get billing code pre requisites request',
  loadBillingCodePreRequisiteSuccess = '[GET] Get billing code pre requisites Success',
  loadBillingCodePreRequisiteFailure = '[GET] Get billing code pre requisites failed',

  loadBillingCodeOON = '[GET] Get billing code out of network',
  loadBillingCodeOONSuccess = '[GET] Get billing code out of network Success',
  loadBillingCodeOONFailure = '[GET] Get billing code out of network failed',

  ResetSelectedBillingCodeDependents = '[SET] Reset selected billing code dependents',
  UpdateSelectedBillingCodeDependents = '[SET] Update selected billing code dependents',

  loadBillingCodesByAutocompleteSearchTerm = '[GET] Get billing codes autocomplete by search term request',
  loadBillingCodesByAutocompleteSearchTermSuccess = '[GET] Get billing codes autocomplete by search term request success',
  loadBillingCodesByAutocompleteSearchTermFailure = '[GET] Get billing codes autocomplete by search term request failed',
  clearBillingCodes = '[DELETE] Clear billing codes',

  loadBillingCodeByServiceCode = '[GET] Get single billing code by service code request',
  loadBillingCodeByServiceCodeSuccess = '[GET] Get single billing code by service code request success',
  loadBillingCodeByServiceCodeFailure = '[GET] Get single billing code by service code request failed',
}

export const loadBillingCodeByServiceCode = createAction(
  BillingCodeSearchActionsTypes.loadBillingCodeByServiceCode,
  props<{ serviceCode: string }>()
);

export const loadBillingCodeByServiceCodeSuccess = createAction(
  BillingCodeSearchActionsTypes.loadBillingCodeByServiceCodeSuccess,
  props<{ billing_code: BillingCode }>()
);

export const loadBillingCodeByServiceCodeFailure = createAction(
  BillingCodeSearchActionsTypes.loadBillingCodeByServiceCodeFailure,
  props<{ error: any }>()
);

export const loadBillingCodesByAutocompleteSearchTerm = createAction(
  BillingCodeSearchActionsTypes.loadBillingCodesByAutocompleteSearchTerm,
  props<{ searchTerm: string; page: number; isProfilePage: boolean }>()
);

export const loadBillingCodesByAutocompleteSearchTermSuccess = createAction(
  BillingCodeSearchActionsTypes.loadBillingCodesByAutocompleteSearchTermSuccess,
  props<{ billing_codes: BillingCode[]; meta: any; isProfilePage: boolean }>()
);

export const loadBillingCodesByAutocompleteSearchTermFailure = createAction(
  BillingCodeSearchActionsTypes.loadBillingCodesByAutocompleteSearchTermFailure,
  props<{ error: any }>()
);

export const selectedBillingCode = createAction(
  BillingCodeSearchActionsTypes.selectedBillingCode,
  props<{ payload: BillingCode }>()
);

export const loadBillingCodeBenefitLimits = createAction(
  BillingCodeSearchActionsTypes.loadBillingCodeBenefitLimits
);

export const loadBillingCodeBenefitLimitsSuccess = createAction(
  BillingCodeSearchActionsTypes.loadBillingCodeBenefitLimitsSuccess,
  props<{ payload: BillingCodeBenefitLimits }>()
);

export const loadBillingCodeBenefitLimitsFailure = createAction(
  BillingCodeSearchActionsTypes.loadBillingCodeBenefitLimitsFailure,
  props<{ error: any }>()
);

export const loadBillingCodePreRequisite = createAction(
  BillingCodeSearchActionsTypes.loadBillingCodePreRequisite
);

export const loadBillingCodePreRequisiteSuccess = createAction(
  BillingCodeSearchActionsTypes.loadBillingCodePreRequisiteSuccess,
  props<{ payload: BillingCodePreRequisite }>()
);

export const loadBillingCodePreRequisiteFailure = createAction(
  BillingCodeSearchActionsTypes.loadBillingCodePreRequisiteFailure,
  props<{ error: any }>()
);

export const loadBillingCodeOON = createAction(
  BillingCodeSearchActionsTypes.loadBillingCodeOON,
  props<{ payload: string }>()
);

export const loadBillingCodeOONSuccess = createAction(
  BillingCodeSearchActionsTypes.loadBillingCodeOONSuccess,
  props<{ payload: BillingCodeOON }>()
);

export const loadBillingCodeOONFailure = createAction(
  BillingCodeSearchActionsTypes.loadBillingCodeOONFailure,
  props<{ error: any }>()
);

export const ResetSelectedBillingCodeDependents = createAction(
  BillingCodeSearchActionsTypes.ResetSelectedBillingCodeDependents
);

export const UpdateSelectedBillingCodeDependents = createAction(
  BillingCodeSearchActionsTypes.UpdateSelectedBillingCodeDependents,
  props<{ payload: string }>()
);

export const clearBillingCodes = createAction(
  BillingCodeSearchActionsTypes.clearBillingCodes
);
