import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { SettingsService } from '@services/settings.service';
import { AuthService } from '@services/auth.service';
import { first, switchMap } from 'rxjs/operators';
import { AuthStatus } from '@interfaces/auth-status.model';
import { ActivatedRoute, Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NetworkSelectionDropdownService {
  public networkChangeTooltip: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private isInitialPageLoad: boolean = true;

  constructor(
    private settingsService: SettingsService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  /**
   * Get the network selection dropdown config if member is not authenticated and a corp_code exists.
   * @returns Observable<boolean>
   */
  public getNetworkSelectionDropdownConfig(): Observable<boolean> {
    return this.route.queryParams.pipe(
      first(),
      switchMap((params: Params) => this.shouldRequestConfig(params)),
      switchMap((requestConfig: boolean) => {
        if (requestConfig) {
          return this.requestNetworkSelectionDropdownConfig();
        }
        return of(false);
      })
    );
  }

  public updateNetworkChangeTooltip(showValue: boolean) {
    this.networkChangeTooltip.next(showValue);
  }

  private requestNetworkSelectionDropdownConfig(): Observable<boolean> {
    return this.settingsService.getSetting('network_selection_dropdown').pipe(
      first(),
      switchMap((enabled) => of(!!enabled))
    );
  }

  private shouldRequestConfig(params: Params): Observable<boolean> {
    if (!!params.corp_code) {
      return this.authService.authStatus.pipe(
        first((authStatus) => !!authStatus.resolved),
        switchMap((authStatus: AuthStatus) => {
          return of(!authStatus.auth_status);
        })
      );
    }
    return of(false);
  }

  /**
   * This flag indicates if the page is loaded for the first time.
   * If so, the "Search Again" tooltip should not be displayed.
   */
  public get getInitialPageLoadState(): boolean {
    return this.isInitialPageLoad;
  }

  public set setInitialPageLoadState(state: boolean) {
    this.isInitialPageLoad = state;
  }
}
