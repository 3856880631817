import { SavedProvider } from '@interfaces/saved-provider.model';

export type SavedProvidersState = {
  enabled: boolean;
  loading: boolean;
  savedProviders: SavedProvider[];
  error: any;
  max: number;
};

export const initialState: SavedProvidersState = {
  enabled: false,
  loading: true,
  savedProviders: [],
  error: null,
  max: 10,
};
