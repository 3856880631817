/**
 * Generate a deterministic random number between 1 and maxValue
 * based on the inputString.
 * @argument inputString: Randomizer seed
 * @argument maxValue: Max random number
 * @returns number
 */
export const assignRandomNumberToInput = (
  inputString: string,
  maxValue: number
): number => {
  let hash = 0;
  for (let i = 0; i < inputString.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + inputString.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash &= hash; // Convert to 32-bit integer
  }
  const randomNumber = (Math.abs(hash) % maxValue) + 1;
  return randomNumber;
};
