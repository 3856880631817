import {
  filter,
  catchError,
  tap,
  map,
  toArray,
  shareReplay,
  mergeMap,
  distinctUntilChanged,
  switchMap,
  take,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of, iif, from, Subject, combineLatest } from 'rxjs';
import { SettingsService } from './settings.service';
import { Network } from '@interfaces/network.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Place } from '@classes/place.class';
import { LocationService } from './location/location.service';
import { AllowableNetworks } from '@interfaces/allowable-networks.interface';
import { CriticalParamsService } from './critical-params/critical-params.service';
import { AuthService } from './auth.service';
import { AuthStatus } from '@interfaces/auth-status.model';
import { AppParamsService } from './app.params.service';
import { UserSelectedCritical } from '@interfaces/user-selected-critical.model';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { NetworkStoreActions } from '../root-store/network';
import {
  find,
  includes,
  isArray,
  isEmpty,
  isEqual,
  keyBy,
  values,
} from 'lodash';
import { CityService } from '@services/location/city-lookup.service';
import { ConfigurationService } from './configuration.service';

const DEFAULT = 'default';
const NO_VALID_NETWORK_TRANSLATION_KEY = 'app_global_search_no_valid_network';

@Injectable({
  providedIn: 'root',
})
export class NetworksService {
  public networks: Observable<any[]> = this.getNetworks();
  public mappedNetworks: Observable<Network[]> = this.getMappedNetworks();
  public resolvedNetwork: Observable<Network> = this.getResolvedNetwork();
  public medicareDirectories: Observable<any[]> = this.getMedicareDirectories();
  public networkChanged: Subject<boolean> = new Subject();
  public selectedNetwork: Network;

  constructor(
    private appParamsService: AppParamsService,
    private authService: AuthService,
    private criticalParamsService: CriticalParamsService,
    private http: HttpClient,
    private locationService: LocationService,
    private settingsService: SettingsService,
    private route: ActivatedRoute,
    private store: Store<any>,
    private cityService: CityService,
    private configurationService: ConfigurationService
  ) {}

  /**
   * Set new network id to the url.
   * @param networkId: string
   * @returns void
   */
  public setNetwork(networkId: string): void {
    this.criticalParamsService.setCriticalParams({ network_id: networkId });
  }

  public getSelectedNetwork(): Network {
    return this.selectedNetwork;
  }

  public getNetworkById(networks: Network[], id: string): Network {
    return networks.find((network) => network.id === id) || null;
  }

  public networkSelected(networkId: string): void {
    this.storeUserSelectedNetwork(networkId);
  }

  public getMappedNetworksBySignature(
    signature: string
  ): Observable<Network[]> {
    return this.getMappedNetworks(signature);
  }

  public getNoValidNetworks(): Observable<boolean> {
    return this.getValidNetworksCount().pipe(map((count) => count === 0));
  }

  public getValidNetworksCount(): Observable<number> {
    return this.mappedNetworks.pipe(
      filter((networks) => !!networks),
      map((networks) =>
        networks.filter(
          (network) =>
            network && network.name !== 'app_global_search_no_valid_network'
        )
      ),
      map((networks) => networks.length)
    );
  }

  /**
   * Get mapped networks based on allowable_networks config and current resolved state.
   * @returns Observable<Network[]>
   */
  private getMappedNetworks(signature?: string): Observable<Network[]> {
    return combineLatest([
      this.getAllowableNetworks(signature),
      this.networks,
      this.getGeoLocationState(),
      this.onErrorState(),
    ]).pipe(
      // Filter out empty/nonexistent values
      filter(
        ([allowable, networks, state, errorState]) =>
          !!(!isEmpty(allowable) && !isEmpty(networks) && (state || errorState))
      ),
      // Determine which subset of allowable networks to map
      mergeMap(([allowable, networks, state, errorState]) =>
        iif(
          // Determine if valid (state or default level) networks are available
          () => !isEmpty(allowable[state]) || !isEmpty(allowable[DEFAULT]),
          this.filterAndMapNetworks(
            networks,
            allowable[state] || allowable[DEFAULT],
            true
          ),
          this.filterAndMapNetworks(
            networks,
            (!isEmpty(this.firstAllowableNetwork(allowable)) &&
              this.firstAllowableNetwork(allowable)) ||
              allowable[errorState],
            false
          )
        )
      )
    );
  }

  private filterAndMapNetworks(
    networks: Network[],
    networksToMap: Network[],
    valid: boolean
  ): Observable<Network[]> {
    if (networksToMap && networksToMap.length) {
      return from(networksToMap).pipe(
        // Filter out networks not included in networks.json
        filter(
          (network) =>
            !!(
              network &&
              includes(
                networks.map((n) => n.id),
                String(network.id)
              )
            )
        ),
        map((network) => {
          const networkMatch = keyBy(networks, 'id')[network.id];
          network.rbp = networkMatch.rbp;
          const mappedNetwork = new Network(network);
          mappedNetwork.tier_code = network.tier_code || networkMatch.tier_code;
          mappedNetwork.hpn = network.hpn || networkMatch.hpn;
          mappedNetwork.contains_all_plans =
            network.contains_all_plans || networkMatch.contains_all_plans;
          const name = valid
            ? network.name || networkMatch.name
            : NO_VALID_NETWORK_TRANSLATION_KEY;
          mappedNetwork.id = valid ? mappedNetwork.id : '0';
          return mappedNetwork.setName(name);
        }),
        toArray(),
        map((mappedNetworks) =>
          mappedNetworks.length ? mappedNetworks : this.noNetworksFound()
        )
      );
    } else {
      return of([]);
    }
  }

  private noNetworksFound(): Network[] {
    console.warn(
      'Networks not found in networks.json that match allowable network'
    );
    return [
      new Network({
        id: '0',
        name: 'app_global_search_no_valid_network_found',
      }),
    ];
  }

  private firstAllowableNetwork(
    allowableNetworks: AllowableNetworks
  ): Network[] {
    const allowableNetworksArray = isArray(allowableNetworks)
      ? allowableNetworks
      : values(allowableNetworks);
    const usableNetwork = find(allowableNetworksArray, (val) => val.length > 0);
    return usableNetwork || [];
  }

  private getNetworks(): Observable<any> {
    return this.configurationService.signature.pipe(
      filter((sig) => !!sig),
      take(1),
      switchMap((sig) => {
        let params: HttpParams = new HttpParams();
        params = params.set('config_signature', sig);
        return this.http.get(`/api/networks.json`, { params: params }).pipe(
          catchError(() => of({})),
          filter((results) => results && results['networks']),
          map((results) => results['networks']),
          shareReplay()
        );
      })
    );
  }

  private getMedicareDirectories(): Observable<any> {
    return this.http.get(`/api/medicare_directory.json`).pipe(
      catchError(() => of({})),
      map((results) => results['link']),
      shareReplay()
    );
  }

  private getAllowableNetworks(
    signature: string
  ): Observable<AllowableNetworks> {
    if (!!signature) {
      return this.settingsService
        .requestSetting(signature, 'allowable_networks')
        .pipe(map((settings) => settings.settings[0]['allowable_networks']));
    }
    return this.settingsService.getSetting('allowable_networks');
  }

  private onErrorState(): Observable<string> {
    return this.settingsService.getSetting('geo_location').pipe(
      filter((response) => response && response.on_error),
      map((place) => place.on_error.region)
    );
  }

  private getGeoLocationState(): Observable<string> {
    return this.locationService.geo.pipe(
      filter((place: Place) => place && place.isValid()),
      switchMap((place: Place) => {
        return iif(
          () => !!(place.region || place.state_code),
          of(place.region || place.state_code),
          this.stateLookup(place)
        );
      })
    );
  }

  private stateLookup(place: Place): Observable<string> {
    return this.cityService.forPlace(new Place(place)).pipe(
      filter((places: Place[]) => !!places?.length),
      map((places: Place[]) => places[0]),
      map((city: Place) => city.region || city.state_code)
    );
  }

  private getResolvedNetwork(): Observable<Network> {
    return combineLatest([
      this.networkParamChange(),
      this.mappedNetworks.pipe(
        distinctUntilChanged((prev, curr) =>
          isEqual(
            prev.map((n) => n.id),
            curr.map((n) => n.id)
          )
        )
      ),
      this.getAuthStatus(),
      this.getCi(),
    ]).pipe(
      mergeMap(([networkId, resolvedNetworks, authStatus, ci]) =>
        this.findResolvedNetwork(networkId, resolvedNetworks, authStatus, ci)
      ),
      distinctUntilChanged(),
      tap((resolvedNetwork) => {
        if (resolvedNetwork) {
          if (
            resolvedNetwork.id !==
            (this.selectedNetwork && this.selectedNetwork.id)
          ) {
            this.networkChanged.next(true);
            this.store.dispatch(
              NetworkStoreActions.setResolvedNetwork({
                network: resolvedNetwork,
              })
            );
            this.selectedNetwork = resolvedNetwork;
          }
          this.criticalParamsService.setCriticalParams({
            network_id: resolvedNetwork.id,
          });
        }
      })
    );
  }

  private networkParamChange(): Observable<string> {
    return this.criticalParamsService.criticalParamsSubject.pipe(
      distinctUntilChanged((prev, curr) => prev.network_id === curr.network_id),
      map((params) => params.network_id)
    );
  }

  private findResolvedNetwork(
    networkId: string,
    resolvedNetworks: Network[],
    authStatus: AuthStatus,
    ci: string
  ): Observable<Network> {
    if (!authStatus.auth_status) {
      const userSavedNetworkId = this.getUserSavedNetworkId(ci);
      if (userSavedNetworkId && !networkId) {
        networkId = userSavedNetworkId;
      }
    }
    const network = this.getNetworkById(resolvedNetworks, networkId);
    if (network) {
      return of(network);
    }
    if (ci === 'INCORRECT-GROUP-NUMBER-OR-NETWORK') {
      return of(
        new Network({
          name: 'app_global_search_header_health_plan',
          id: '0',
        })
      );
    }
    return of(resolvedNetworks[0]);
  }

  private getUserSavedNetworkId(ci: string): string {
    const userSavedCritical = this.getSavedCritical();
    if (userSavedCritical && userSavedCritical.length >= 1) {
      const userSavedMatchingCritical = userSavedCritical.find(
        (crit) => crit.ci === ci
      );
      if (userSavedMatchingCritical) {
        return userSavedMatchingCritical.network;
      }
    }
  }

  private getSavedCritical(): UserSelectedCritical[] {
    return this.appParamsService.getUserSelectedCritical();
  }

  private storeUserSelectedNetwork(networkId: string): void {
    this.appParamsService.setUserSelectedCritical('network', networkId);
  }

  private getAuthStatus(): Observable<AuthStatus> {
    return this.authService.authStatus.pipe(
      filter((auth) => auth.resolved),
      take(1)
    );
  }

  private getCi(): Observable<string> {
    return this.criticalParamsService.criticalParamsSubject.pipe(
      filter((criticalParams) => !!criticalParams),
      map((data) => data.ci),
      distinctUntilChanged()
    );
  }
}
