import { DOCUMENT } from '@angular/common';
import {
  Inject,
  Injectable,
  Renderer2,
  RendererFactory2,
  RendererStyleFlags2,
} from '@angular/core';
import { ThemePalettes } from '@interfaces/theme-palettes.interface';
import { SettingsService } from '@services/settings.service';
import {
  MaterialCssVariables,
  MaterialCssVarsService,
} from 'angular-material-css-vars';
import hexRgb from 'hex-rgb';
import { merge } from 'lodash';
import rgbHex from 'rgb-hex';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ThemingService {
  public clientPalettes: Observable<ThemePalettes>;
  public resolved: Observable<boolean>;
  private resolvedSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  private paletteMap = [
    {
      palette: 'primary',
      name: 'Primary',
      contrast: 'PrimaryContrast',
    },
    {
      palette: 'accent',
      name: 'Accent',
      contrast: 'AccentContrast',
    },
  ];
  private renderer: Renderer2;
  private rootElement: HTMLElement;
  private defaultPalettes: ThemePalettes = {
    primary: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      500: '#3e0b52',
    },
    accent: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      500: '#005EB9',
    },
  };

  constructor(
    private materialCssVarsService: MaterialCssVarsService,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private settingsService: SettingsService
  ) {
    this.resolved = this.resolvedSubject.asObservable();
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.rootElement = this.document.documentElement;
    this.clientPalettes = this.getClientPalettes();
  }

  public getCssVarValue(name: string): string {
    return getComputedStyle(this.rootElement).getPropertyValue(name);
  }

  public getCssVarColorAsHex(name: string): string {
    const color = this.getCssVarValue(name);
    if (!color) {
      return '';
    }
    if (color.startsWith('#')) {
      return color;
    }
    return `#${rgbHex(`rgba(${color})`)}`;
  }

  private getClientPalettes(): Observable<ThemePalettes> {
    return this.getAccountPalettes().pipe(
      map((accountPalettes) => {
        const mergedPalettes: ThemePalettes = {};
        merge(mergedPalettes, this.defaultPalettes, accountPalettes);
        return mergedPalettes;
      }),
      tap((palettes) => this.initPaletteVariables(palettes))
    );
  }

  private getAccountPalettes(): Observable<ThemePalettes> {
    return this.settingsService.getSetting('theme_palettes').pipe(
      catchError(() => of(null)),
      map((palettes) => palettes || {})
    );
  }

  private initPaletteVariables(palettes: ThemePalettes) {
    this.materialCssVarsService.setDarkTheme(false);
    this.materialCssVarsService.setPrimaryColor(palettes.primary['500']);
    this.materialCssVarsService.setAccentColor(palettes.accent['500']);
    this.setPaletteVariables(palettes);
    this.resolvedSubject.next(true);
  }

  private setPaletteVariables(palettes: any) {
    this.paletteMap.forEach((pMap) =>
      this.setVariables(palettes[pMap.palette], pMap)
    );
  }

  private setVariables(palette: any, paletteMap: any, isContrast = false) {
    if (!palette) {
      return;
    }
    Object.keys(palette).forEach((shade) => {
      if (shade === 'contrast') {
        this.setVariables(palette.contrast, paletteMap, true);
      } else {
        const name = isContrast ? paletteMap.contrast : paletteMap.name;
        this.setMaterialVariable(name, shade, palette[shade]);
      }
    });
  }

  private setMaterialVariable(name: string, shade: string, color: string) {
    const mapToName = `${name}${shade}`;
    if (!MaterialCssVariables[mapToName]) {
      return;
    }
    if (this.colorIsHex(color)) {
      this.materialCssVarsService.setVariable(
        MaterialCssVariables[mapToName],
        this.colorHexToRgb(color)
      );
    }
    if (this.colorIsVar(color)) {
      const mapFromName = `var(${color})`;
      this.renderer.setStyle(
        this.rootElement,
        MaterialCssVariables[mapToName],
        mapFromName,
        RendererStyleFlags2.DashCase
      );
    }
  }

  private colorHexToRgb(color: string): string {
    const rgb = hexRgb(color);
    return `${rgb.red}, ${rgb.green}, ${rgb.blue}`;
  }

  private colorIsHex(color: string): boolean {
    return color.startsWith('#');
  }

  private colorIsVar(color: string): boolean {
    return color.startsWith('--');
  }
}
