import { FeatureFlagSet } from '@interfaces/feature-flag-set.interface';
import { FeaturesService } from '@services/features/features.service';
import {
  ErrorHandler,
  Injectable,
  Inject,
  InjectionToken,
} from '@angular/core';
import Rollbar from 'rollbar';
import { ObservableError } from '@interfaces/observable-error.model';
import { HttpErrorResponse } from '@angular/common/http';
import { LogOutService } from '@services/logout.service';
import { AppConfigService } from '@services/app.config.service';
import { switchMap, filter, map, tap, shareReplay, take } from 'rxjs/operators';
import { AppConfig } from '@interfaces/app-config.model';
import { Observable } from 'rxjs';

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
const ROLLBAR_ERROR_EXCLUDELIST = ['ChunkLoadError'];

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  private configAndFeatures: Observable<{
    config: AppConfig;
    features: FeatureFlagSet;
  }>;

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private featuresService: FeaturesService,
    private logoutService: LogOutService,
    private appConfigService: AppConfigService
  ) {
    this.configAndFeatures = this.getConfigAndFeatures();
  }

  public handleError(error: any): void {
    if (this.shouldLogOut(error)) {
      this.doLogout();
      return;
    }

    if (this.shouldLogError(error)) {
      console.error(error);
      this.configAndFeatures.pipe(take(1)).subscribe(({ config, features }) => {
        if (
          this.shouldLogToRollbar(config, features) &&
          !this.onRollbarExcludeList(error.message)
        ) {
          this.rollbar.error(error.message, error);
        }
      });
    }
  }

  private shouldLogToRollbar(
    config: AppConfig,
    features: FeatureFlagSet
  ): boolean {
    return !!(config.rollbar_token && !features.disable_rollbar);
  }

  private onRollbarExcludeList(message: string): boolean {
    return ROLLBAR_ERROR_EXCLUDELIST.some((error: string) => {
      return message?.toLowerCase().includes(error.toLowerCase());
    });
  }

  private shouldLogError(error: any): boolean {
    return !!(
      (
        error &&
        !(error instanceof ObservableError) &&
        error.status !== 204 && // no data
        error.status !== 401 && // not authorized
        error.status !== 500
      ) // these will be logged by the API
    );
  }

  private shouldLogOut(error: HttpErrorResponse): boolean {
    return (
      error.status === 401 &&
      error.error &&
      error.error.hint === 'Session Expired'
    );
  }

  private doLogout(): void {
    this.logoutService.logout();
  }

  private getConfigAndFeatures(): Observable<{
    config: AppConfig;
    features: FeatureFlagSet;
  }> {
    return this.appConfigService.config.pipe(
      filter((config) => !!config?.rollbar_token),
      switchMap((config) =>
        this.featuresService
          .getFeatureFlags()
          .pipe(map((features) => ({ config, features })))
      ),
      tap(({ config }) => this.configureRollbar(config)),
      shareReplay(1)
    );
  }

  private configureRollbar(config: AppConfig): void {
    this.rollbar.configure({
      accessToken: config.rollbar_token,
      payload: {
        environment: config.environment,
        environment_client: config.client,
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: config.revision_sha,
            guess_uncaught_frames: true,
          },
        },
        server: {
          branch: config.branch_tag,
        },
      },
    });
  }
}
