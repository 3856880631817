export class MemberSearchHistory {
  billing_code?: string;
  billing_code_description?: string;
  procedure_id?: string;
  procedure_name?: string;
  search_term?: string;
  created_at?: string;
  search_type_code?: string;
  search_specialty_id?: string;
  specialty_search_name?: string;

  constructor(history: MemberSearchHistory = {}) {
    this.billing_code = history.billing_code || null;
    this.procedure_id = history.procedure_id || null;
    this.search_term = history.search_term || setSearchTerm(history);
    this.created_at = history.created_at || null;
    this.search_type_code = history.search_type_code || null;
    this.billing_code_description = history.billing_code_description || null;
    this.procedure_name = history.procedure_name || null;
    this.specialty_search_name = history.specialty_search_name || null;
    this.search_specialty_id = history.search_specialty_id || null;
  }
}

const setSearchTerm = (history: MemberSearchHistory) => {
  return (
    history.procedure_name ||
    history.specialty_search_name ||
    history.search_term ||
    (history?.billing_code
      ? history.billing_code + ' - ' + history.billing_code_description
      : null)
  );
};

export interface MobileMemberSearchHistory {
  date?: string;
  history?: {
    search_term: string;
  }[];
}
