<div
  class="carousel-container carousel-animation {{ animation }}"
  *ngIf="items?.length > 0"
  [ngClass.gt-sm]="{ 'carousel-ready': carouselReady }"
  [ngClass.lt-md]="{ 'carousel-ready': carouselReady, mobile: true }"
>
  <div
    class="carousel-sections"
    fxLayout="row"
    fxLayoutAlign.gt-sm="start center"
    fxLayoutAlign.lt-md="start start"
  >
    <div
      class="pad"
      *ngIf="items?.length > 1"
      fxFlex.gt-sm="28px"
      fxFlex.lt-md="8px"
      ngClass.lt-md="top-8x"
    >
      <button
        mat-fab
        class="previous-item-button platform-button button-basic pull-left"
        ngClass.lt-md="button-small mat-elevation-z4"
        (click)="previousItem()"
      >
        <mat-icon svgIcon="dls-arrow-left-light"></mat-icon>
      </button>
    </div>
    <div class="carousel-stage-container" ngClass.lt-md="mat-elevation-z3">
      <div class="carousel-stage">
        <ng-content></ng-content>
      </div>
    </div>
    <div
      class="pad"
      *ngIf="items?.length > 1"
      fxFlex.gt-sm="28px"
      fxFlex.lt-md="8px"
      ngClass.lt-md="top-8x"
    >
      <button
        mat-fab
        class="next-item-button platform-button button-basic pull-right"
        ngClass.lt-md="button-small mat-elevation-z4"
        (click)="nextItem()"
      >
        <mat-icon svgIcon="dls-arrow-right-light"></mat-icon>
      </button>
    </div>
  </div>
</div>
