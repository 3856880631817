<mat-chip-option
  class="chip dls-body-2-regular"
  [attr.aria-label]="ariaLabel"
  [disabled]="disabled"
  [selected]="selected"
  [removable]="removable"
  [selectable]="selectable"
  [tabIndex]="0"
  [ngClass]="{
    selected: selected,
    'pointer-events-none bg-palette-m3-system-gray-95 text-palette-m3-system-gray-70': disabled,
  }"
  (removed)="removed.emit($event)"
  (selectionChange)="selectionChange.emit($event)"
  (click)="onClick($event)"
  (keydown.enter)="onClick($event)"
>
  <div
    class="flex flex-row items-center space-between gap-[8px]"
    [ngClass]="{
    'pl-[12px]': !leadingIcon,
    'pl-[8px]': leadingIcon,
    'pr-[12px]': !trailingIcon && !removable,
    'pr-[8px]': trailingIcon || removable,
  }"
  >
    <ng-template [ngIf]="leadingIcon">
      <mat-icon [svgIcon]="leadingIcon"></mat-icon>
    </ng-template>

    <ng-content> </ng-content>

    <ng-template [ngIf]="trailingIcon">
      <mat-icon [svgIcon]="trailingIcon"></mat-icon>
    </ng-template>

    <ng-template [ngIf]="removable">
      <button matChipRemove class="p-0">
        <mat-icon
          svgIcon="dls-times-plain-light"
          [ngClass]="{
            'text-palette-m3-system-gray-70': !selected || disabled,
            'text-palette-m3-system-blue-30': selected,
          }"
        ></mat-icon>
      </button>
    </ng-template>
  </div>
</mat-chip-option>
