<div class="gated-entry-overlay-network-location" ngClass.lt-md="mobile">
  <div class="page-container">
    <div class="page-title-icon">
      <mat-icon svgIcon="dls-search-plain-light"></mat-icon>
    </div>
    <div>
      <h2
        class="page-title text-center text-light"
        data-cy="gated-entry-network-location.page-title"
      >
        <span>
          {{ 'gated_entry_browsing_title' | translate }}
        </span>
      </h2>
    </div>
    <div class="title-separator"></div>
    <div class="form-container">
      <!-- Location Select -->
      <div
        *ngIf="pageTransition.page === 'network-location'"
        [@slideInOutAnimation]="pageTransition.direction"
      >
        <div class="form-section">
          <div
            class="page-description"
            data-cy="gated-entry-network-location.location-page-description"
            [innerHTML]="'gated_entry_sub_title_location_select' | translate"
          ></div>
          <app-global-location-select
            [ngClass]="{
              'app-form-field-error':
                !loggedIn && locationSelected && noValidNetworks
            }"
            analytics
            (click)="openMobileLocation()"
            [mobileOverlay]="false"
            [results]="locationAutosuggest.cities"
            [selectedOption]="
              locationSelected || hasSelectedOption
                ? locationAutosuggest.selectedLocation
                : null
            "
            appearance="outline"
            (termEntered)="locationAutosuggest.onLocationEntered($event)"
            (termSelected)="onLocationSelect($event)"
            (emitBrowserLocationRequested)="onGpsLocateSelect()"
            (optionSelected)="onOptionSelect($event)"
          >
          </app-global-location-select>
          <!-- Network Error Message -->
          <div
            class="text-error margin top-2x"
            *ngIf="!loggedIn && locationSelected && noValidNetworks"
            data-cy="gated-entry-network-location.text-error"
          >
            {{ 'gated_entry_location_select_no_networks_error' | translate }}
          </div>
          <!-- Location Select Form Buttons -->
          <div
            class="margin top-6x"
            fxLayout.gt-xs="row"
            fxLayout.lt-sm="column-reverse"
            fxLayoutAlign.gt-xs="end center"
            fxLayoutAlign.lt-sm="start space-between"
            fxLayoutGap.gt-xs="24px"
            fxLayoutGap.lt-sm="16px"
          >
            <div
              fxFlex.gt-xs="nogrow"
              *ngIf="!loggedIn && !gatedEntryConfig.homeScreenDisabled"
            >
              <zelis-button
                variant="stroked"
                [fullWidth]="true"
                data-cy="gated-entry-network-location.go-back"
                color="accent"
                (click)="goBack.emit()"
              >
                {{ 'app_global_go_back' | translate }}
              </zelis-button>
            </div>
            <div fxFlex.gt-xs="240px">
              <zelis-button
                variant="flat"
                [forwardedClass]="ccss.loginButtonClass | async"
                [fullWidth]="true"
                data-cy="gated-entry-network-location.continue"
                color="accent"
                [disabled]="continueButtonDisabled()"
                (clickFn)="onContinueClick()"
              >
                {{ 'app_global_continue' | translate }}
              </zelis-button>
            </div>
          </div>
        </div>
      </div>
      <!-- Network Select -->
      <div
        *ngIf="pageTransition.page === 'network-select'"
        [@slideInOutAnimation]="pageTransition.direction"
      >
        <div class="form-section">
          <div
            class="page-description"
            data-cy="gated-entry-network-location.network-page-description"
            [innerHTML]="
              'gated_entry_sub_title_network_select'
                | translate
                  : { place: locationAutosuggest.selectedLocation?.toString() }
            "
          ></div>
          <div
            class="page-description page-notice"
            *ngIf="networkUnavailable"
            [innerHTML]="
              'gated_entry_sub_title_network_unavailable' | translate
            "
          ></div>
          <app-global-network-dropdown
            analytics
            [showTout]="false"
            [showAlphaPrefixTout]="false"
            [autoSelect]="autoSelectNetwork"
            appearance="outline"
            (selectedValue)="updateSelectedNetwork($event)"
            (selectedNetwork)="onNetworkSelect($event)"
          >
          </app-global-network-dropdown>
          <!-- Network Select Form Buttons -->
          <div
            class="margin top-6x"
            fxLayout.gt-xs="row"
            fxLayout.lt-sm="column-reverse"
            fxLayoutAlign.gt-xs="end center"
            fxLayoutAlign.lt-sm="start space-between"
            fxLayoutGap.gt-xs="24px"
            fxLayoutGap.lt-sm="16px"
          >
            <div
              fxFlex.gt-xs="nogrow"
              *ngIf="startingPage !== 'network-select'"
            >
              <zelis-button
                variant="stroked"
                [fullWidth]="true"
                color="accent"
                (click)="goBack.emit()"
              >
                {{ 'app_global_go_back' | translate }}
              </zelis-button>
            </div>
            <div
              fxFlex.gt-xs="240px"
              *ngIf="{
                selectedNetwork: selectedNetwork | async
              } as selectedNetwork"
            >
              <zelis-button
                variant="flat"
                [forwardedClass]="
                  selectedNetwork.selectedNetwork
                    ? (ccss.loginButtonClass | async)
                    : ''
                "
                [fullWidth]="true"
                color="accent"
                [disabled]="selectedNetwork.selectedNetwork === ''"
                (clickFn)="onContinueClick()"
              >
                {{ 'app_global_continue' | translate }}
              </zelis-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
