import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { CriticalParams } from '@interfaces/critical-params.interface';
import { Router } from '@angular/router';
import { RouteUtilities } from '@utilities/route.utilities';
import { WindowService } from '../window.service';
import { Location } from '@angular/common';
import { StorageUtilities } from '@utilities/storage.utilities';
import { AppConfig } from '@interfaces/app-config.model';
import { isEqual, merge } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CriticalParamsService {
  public criticalParams: CriticalParams = {
    ci: '',
    network_id: '',
    geo_location: '',
    locale: 'en',
  };

  public criticalParamsSubject: BehaviorSubject<CriticalParams>;
  private routeUtilities = new RouteUtilities();
  private storage = new StorageUtilities();

  constructor(
    private windowService: WindowService,
    private router: Router,
    private location: Location
  ) {
    this.addSelectedLocale();
    this.setNetworkandGeoLocationFromURL();
    this.criticalParamsSubject = new BehaviorSubject(this.criticalParams);
    this.setSessionStorageCi(this.routeUtilities.getParamFromUrl('ci'));
  }

  public setCriticalParams(data: CriticalParams): void {
    // if data has geo_location not in lat,lng format then remove and don't set
    if (data.geo_location && !/-?\d+.?\d*,-?\d+.?\d*/.test(data.geo_location)) {
      delete data.geo_location;
    }
    if (
      data.locale &&
      (data.locale === null || data.locale === 'null' || data.locale === 'nu')
    ) {
      delete data.locale;
    }
    if (this.onCriticalParamsChange(data)) {
      this.criticalParams = Object.assign({}, this.criticalParams, data);
      // SUBSTRING USED FOR BACKWARDS COMPATIBILITY WITH OLD LOCALE FORMAT
      this.criticalParams.locale = this.criticalParams.locale.substring(0, 2);
      this.criticalParamsSubject.next(this.criticalParams);
    }
  }

  public getResolvedCriticalParams(): Observable<CriticalParams> {
    return this.criticalParamsSubject.pipe(
      filter((params: any) => {
        return (
          params.ci && params.geo_location && params.locale && params.network_id
        );
      }),
      distinctUntilChanged((prev, curr) => isEqual(prev, curr))
    );
  }

  public getParamsFromUrlAndCritical(): any {
    return Object.assign(
      {},
      this.routeUtilities.getParamsFromUrl(),
      this.criticalParams
    );
  }

  public updateCi(sigResult: any, appConfig: AppConfig): void {
    const tempSignatureRes = {
      ci: this.useSessionCi() || sigResult.identifier || null,
    };
    // Component Identifier Enabled: Only set CI to URL if query param is not already set
    let currentCi;
    if (appConfig.client_configs?.component_identifier_enabled) {
      currentCi = this.routeUtilities.getParamFromUrl('ci');
    }
    if (!appConfig.client_configs?.component_identifier_enabled || !currentCi) {
      this.updateUrlCi(tempSignatureRes.ci);
      this.setCriticalParams({ ci: tempSignatureRes.ci });
    }
    if (appConfig.client_configs?.component_identifier_enabled) {
      currentCi = currentCi || tempSignatureRes.ci;
      this.setSessionStorageCi(currentCi);
      tempSignatureRes.ci = currentCi;
    } else {
      this.setSessionStorageCi(tempSignatureRes.ci);
    }

    this.criticalParams.ci = currentCi || tempSignatureRes.ci;
    this.criticalParamsSubject.next(this.criticalParams);
  }

  private setNetworkandGeoLocationFromURL(): void {
    const queryParams = this.windowService['location'].search || '';
    const params = this.routeUtilities.getParamsFromString(queryParams);
    this.criticalParams.network_id = params['network_id'] || '';
    this.criticalParams.geo_location = params['geo_location'] || '';
  }

  private useSessionCi(): string {
    const ci = this.windowService['sessionStorage'].getItem('ci');
    return !this.criticalParams.ci && ci ? ci : '';
  }

  private addSelectedLocale(): void {
    const urlLocale = this.routeUtilities.getParamFromUrl('locale');
    const sessionURL = this.storage.sessionStorageGet('locale');

    if (sessionURL && !urlLocale) {
      this.criticalParams.locale = sessionURL;
    }
    if (urlLocale && urlLocale !== sessionURL) {
      this.storage.sessionStorageSet('locale', urlLocale);
    }
  }

  private onCriticalParamsChange(data: any): boolean {
    return !isEqual(
      this.criticalParams,
      Object.assign({}, this.criticalParams, data)
    );
  }

  private setSessionStorageCi(ci: string): void {
    if (ci) {
      this.windowService['sessionStorage'].setItem('ci', ci);
      this.setCriticalParams({ ci: ci });
    }
  }

  private updateUrlCi(ci: string): void {
    // This is simply for bookmarking and a visual indication of current CI
    // Uses location replaceState to avoid retriggering router event
    const params = this.routeUtilities.getParamsFromString(
      window.location.search
    );
    // when network is updated and signature call is cached updateUrlCi is called before the URL is updated with the network id.
    // The race condition causes the window.location.search to include the old network id.
    const networkID = this.criticalParamsSubject.getValue().network_id;
    const queryParams = merge(params, { ci: ci, network_id: networkID });
    const urlTree = this.router.createUrlTree([], {
      queryParamsHandling: 'merge',
      preserveFragment: true,
      queryParams: queryParams,
    });
    this.location.replaceState(this.router.serializeUrl(urlTree));
  }
}
