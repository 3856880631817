import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { SearchReducer } from './search.reducers';
import { StoreFeatures } from '@enums/store-features.enum';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(StoreFeatures.Search, SearchReducer),
  ],
})
export default class SearchStoreModule {}
