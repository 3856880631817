<div
  fxFlex
  class="language-select"
  [attr.aria-label]="'global_header_select_language' | translate"
>
  <!-- change language button -->
  <zelis-nav-button
    [ariaLabel]="'global_header_select_language' | translate"
    [label]="'app_global_' + language | translate"
    [leadingIcon]="'dls-globe-light'"
    [trailingIcon]="'dls-caret-down-solid'"
    [matMenuTriggerFor]="languageMenu"
    appUnfocus
  ></zelis-nav-button>

  <!-- dropdown menu -->
  <mat-menu
    #languageMenu="matMenu"
    class="language-menu w-56 global-header-dropdown"
  >
    <ng-container *ngIf="!displayMultipleLang">
      <button
        class="language-button-option"
        mat-menu-item
        (click)="setLanguage('en')"
      >
        <span class="text-medium">English</span>
      </button>
      <button
        class="language-button-option"
        data-cy="global-header.language-button-es"
        mat-menu-item
        (click)="setLanguage('es')"
      >
        <span class="text-medium">Español</span>
      </button>
    </ng-container>
    <ng-container *ngIf="displayMultipleLang">
      <button
        *ngFor="let language of multipleLangArray; trackBy: trackByLanguage"
        class="language-button-option"
        mat-menu-item
        (click)="setLanguage(language.code)"
      >
        <span class="text-medium">{{ language.translation }}</span>
      </button>
    </ng-container>
    <button
      *ngIf="meaningfulAccessLanguage"
      data-cy="global-header.language-button-more-languages"
      class="language-button-option more-languages"
      mat-menu-item
      (click)="openLanguagesOverlay()"
    >
      <mat-icon
        svgIcon="dls-comments-question-light"
        class="h-10 w-10"
      ></mat-icon>
      <span class="text-medium">{{
        'global_header_more_languages' | translate
      }}</span>
    </button>
  </mat-menu>
</div>
