export class SavedProvider {
  client_canonical_id: string;
  created_at?: string;
  updating?: boolean;

  constructor(obj: SavedProvider) {
    this.client_canonical_id = obj.client_canonical_id;
    this.created_at = obj.created_at || null;
    this.updating = obj.updating || false;
  }
}
