import { createReducer, on } from '@ngrx/store';
import { SearchStoreState } from '@interfaces/search-state.interface';
import { initialState } from './search.state';
import {
  setFacilitySuppressWriteReviewAction,
  setIsProviderTypeSubSearch,
  setSearchMethodAction,
  setSearchParamType,
} from './search.actions';

export const SearchReducer = createReducer<SearchStoreState>(
  initialState,
  on(setSearchMethodAction, (state, { searchMethod }): SearchStoreState => {
    return { ...state, searchMethod };
  }),
  on(
    setFacilitySuppressWriteReviewAction,
    (state, { facilitySuppressWriteReview }): SearchStoreState => {
      return {
        ...state,
        searchClientSettings: {
          ...state.searchClientSettings,
          facilitySuppressWriteReview,
        },
      };
    }
  ),
  on(setSearchParamType, (state, { searchParamType }): SearchStoreState => {
    return {
      ...state,
      searchParamType: searchParamType,
    };
  }),
  on(
    setIsProviderTypeSubSearch,
    (state, { isProviderTypeSubSearch }): SearchStoreState => {
      return {
        ...state,
        isProviderTypeSubSearch: isProviderTypeSubSearch,
      };
    }
  )
);
