<div
  class="rounded-full"
  [ngClass]="{
    'h-8 w-8': isSize('32px'),
    'h-10 w-10': isSize('40px'),
    'h-12 w-12': isSize('auto') || isSize('small') || isSize('48px'),
    'h-14 w-14': isSize('56px'),
    'h-16 w-16': isSize('large') || isSize('64px'),
    'h-[72px] w-[72px]': isSize('72px'),
    'h-20 w-20': isSize('80px'),
    'h-[88px] w-[88px]': isSize('88px'),
    'h-24 w-24': isSize('96px'),
    'h-[104px] w-[104px]': isSize('104px'),
    'h-28 w-28': isSize('112px'),
    'h-[120px] w-[120px]': isSize('120px'),
    'h-32 w-32': isSize('128px'),
    'h-[136px] w-[136px]': isSize('136px'),
    'h-36 w-36': isSize('144px'),
    'h-[152px] w-[152px]': isSize('152px'),
    'h-40 w-40': isSize('160px'),
    'md:h-16 md:w-16': isSize('auto')
  }"
  [class.mat-elevation-z0]="isElevation('auto') || isElevation('0dp')"
  [class.mat-elevation-z1]="isElevation('1dp')"
  [class.mat-elevation-z2]="isElevation('2dp')"
  [class.mat-elevation-z3]="isElevation('4dp')"
  [class.mat-elevation-z4]="isElevation('6dp')"
  [class.mat-elevation-z5]="isElevation('8dp')"
  [class.mat-elevation-z6]="isElevation('12dp')"
  [class.mat-elevation-z7]="isElevation('16dp')"
  [class.mat-elevation-z8]="isElevation('20dp')"
  [class.mat-elevation-z9]="isElevation('24dp')"
  [class.md:avatar-standard-desktop-elevation]="isElevation('auto')"
  data-cy="avatar"
>
  <div
    *ngIf="text; else imageTpl"
    class="h-full w-full rounded-full flex items-center justify-center bg-palette-primary-900"
    data-cy="avatar-text-container"
  >
    <h3
      class="text-palette-primary-900-contrast font-bold uppercase"
      [ngClass]="{
        'dls-caption-regular': isSize('32px'),
        'text-[15px] leading-5': isSize('40px'),
        'text-lg leading-6':
          isSize('auto') || isSize('small') || isSize('48px'),
        'text-[21px] leading-7': isSize('56px'),
        'text-2xl': isSize('large') || isSize('64px'),
        'text-[27px] leading-9': isSize('72px'),
        'text-3xl leading-10': isSize('80px'),
        'text-[33px] leading-11': isSize('88px'),
        'text-4xl leading-12': isSize('96px'),
        'text-[39px] leading-13': isSize('104px'),
        'text-[42px] leading-14': isSize('112px'),
        'text-[45px] leading-15': isSize('120px'),
        'text-5xl leading-[64px]': isSize('128px'),
        'text-[51px] leading-[68px]': isSize('136px'),
        'text-[54px] leading-[72px]': isSize('144px'),
        'text-[57px] leading-[76px]': isSize('152px'),
        'text-[60px] leading-[80px]': isSize('160px')
      }"
      data-cy="avatar-text"
    >
      {{ text }}
    </h3>
  </div>
</div>

<ng-template #imageTpl>
  <div
    *ngIf="src || !isProviderAvatar; else providerPlaceholderTpl"
    class="h-full w-full rounded-full flex items-center justify-center border border-palette-m3-system-gray-85 bg-cover bg-center"
    [class.bg-palette-m3-system-gray-95]="!isImageLoaded || hasError"
    [class.text-palette-m3-system-gray-60]="!isImageLoaded || hasError"
    [style.backgroundImage]="'url(' + src + ')'"
    aria-label="avatar-image"
    data-cy="avatar-image"
  >
    <!--
      The 70.71% below it's the maximum size a square inside a circle can be,
      which is the diameter of the circle divided by sqrt(2).
    -->

    <mat-icon
      class="m-0 h-[70.71%] w-[70.71%]"
      [class.hidden]="(isImageLoaded && !hasError) || isProviderAvatar"
      [svgIcon]="placeholderIcon!"
      [inline]="true"
      data-cy="avatar-placeholder-icon"
      aria-hidden="false"
      [attr.aria-label]="ariaLabel"
    ></mat-icon>

    <!-- Hidden image only used for on-load and error events -->
    <img
      [src]="src"
      (load)="onImageLoad()"
      (error)="onImageError()"
      class="invisible absolute"
      data-cy="avatar-hidden-image"
      aria-hidden="true"
      alt=""
    />
  </div>
</ng-template>

<ng-template #providerPlaceholderTpl>
  <mat-icon
    [svgIcon]="placeholderIcon!"
    [inline]="true"
    data-cy="avatar-placeholder-icon"
    aria-hidden="false"
    [attr.aria-label]="ariaLabel"
  ></mat-icon>
</ng-template>
