import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  OnChanges,
} from '@angular/core';
import { BadgeColor } from './badge.types';

@Directive({
  selector: '[zelisBadge]',
})
export class BadgeDirective implements OnChanges {
  @Input() zelisBadge?: number | null;
  @Input() badgeHidden = false;
  @Input() iconBadge = false;
  @Input() badgeColor: BadgeColor = 'system-gray';

  private badgeElement: HTMLElement | null = null;

  constructor(public elementRef: ElementRef, public renderer: Renderer2) {}

  ngOnChanges(): void {
    if (this.badgeHidden) {
      this.removeBadge();
      return;
    }

    if (!this.badgeElement) {
      this.createBadge();
    }

    if (!this.zelisBadge || this.zelisBadge < 1) {
      this.setEmptyBadge();
    } else {
      this.setCountBadge(this.zelisBadge);
    }

    this.setBadgeColor();
  }

  private createBadge(): void {
    this.badgeElement = this.renderer.createElement('span');
    this.iconBadge &&
      this.renderer.addClass(this.elementRef.nativeElement, 'zelis-icon-badge');
    this.renderer.addClass(this.elementRef.nativeElement, 'zelis-badge');
    this.renderer.addClass(this.badgeElement, 'zelis-badge-content');
    this.renderer.appendChild(this.elementRef.nativeElement, this.badgeElement);
  }

  private setCountBadge(count: number): void {
    if (!this.badgeElement) return;
    this.renderer.removeClass(this.badgeElement, 'zelis-badge-empty');
    this.renderer.setProperty(
      this.badgeElement,
      'textContent',
      count > 99 ? '99+' : count.toString()
    );
  }

  private setEmptyBadge(): void {
    if (!this.badgeElement) return;
    this.renderer.addClass(this.badgeElement, 'zelis-badge-empty');
    this.renderer.setProperty(this.badgeElement, 'textContent', '');
  }

  private setBadgeColor(): void {
    if (this.badgeColor == 'system-gray') {
      this.renderer.setStyle(
        this.badgeElement,
        'background-color',
        `rgb(var(--palette-m3-system-gray-85))`
      );
      this.renderer.setStyle(
        this.badgeElement,
        'color',
        `rgb(var(--palette-m3-system-gray-35))`
      );
    } else {
      this.renderer.setStyle(
        this.badgeElement,
        'background-color',
        `rgb(var(--palette-m3-${this.badgeColor}-40))`
      );
      this.renderer.setStyle(
        this.badgeElement,
        'color',
        `rgba(var(--palette-primary-contrast-500)`
      );
    }
  }

  private removeBadge(): void {
    if (this.badgeElement) {
      this.renderer.removeClass(this.elementRef.nativeElement, 'zelis-badge');
      this.renderer.removeChild(
        this.elementRef.nativeElement,
        this.badgeElement
      );
      this.badgeElement = null;
    }
  }
}
