import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  throwError,
  defer,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { BillingCodeStoreActions } from '@store/billing-code-search';
import { BillingCodeOON } from '@interfaces/billing-code-OON.model';
import { LocationService } from './location/location.service';
import { FaqQuestionItem } from '@interfaces/faq-question-item.interface';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@services/settings.service';
import { cloneDeep } from 'lodash';
import { BillingCode } from '@interfaces/billing-code.interface';
import { ActivatedRoute } from '@angular/router';
import { BillingCodeOONData } from '@interfaces/billing-code-OON-data.interface';
import { selectDefaultRadiusFilterRates } from '@store/search-filters/radius-filters/radius-filters.selectors';
import { AppParamsService } from './app.params.service';
import { selectSelectedBillingCodeObject } from '@store/billing-code-search/billing-code.selectors';

@Injectable({
  providedIn: 'root',
})
export class BillingCodeService {
  public oonData: Observable<BillingCodeOONData>;
  private oonDataSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private showErrorStatuses = [424, 500, 503, 504];

  private readonly defaultFaqQuestions: FaqQuestionItem[] = [
    { title: 'tinc_faq_question_1', content: 'tinc_faq_answer_1' },
    { title: 'tinc_faq_question_2', content: 'tinc_faq_answer_2' },
    { title: 'tinc_faq_question_3', content: 'tinc_faq_answer_3' },
    { title: 'tinc_faq_question_4', content: 'tinc_faq_answer_4' },
  ];

  constructor(
    private http: HttpClient,
    private store: Store,
    private locationService: LocationService,
    private translateService: TranslateService,
    private settingsService: SettingsService,
    private route: ActivatedRoute,
    private appParams: AppParamsService
  ) {
    this.oonData = this.oonDataSubject.asObservable();
  }

  public setOonData(data: BillingCodeOONData): void {
    this.oonDataSubject.next(data);
  }

  public searchBillingCodes(
    searchTerm: string,
    page: number | null,
    isProfilePage: boolean
  ): Observable<any> {
    const url = `/api/billing_codes.json`;
    const params = {
      fulltext: searchTerm,
    };
    if (page) {
      params['page'] = page;
    }
    return this.http.get(url, { withCredentials: true, params }).pipe(
      map((codes: any) => ({
        billing_codes: codes.billing_codes.map(
          (code: any) => new BillingCode(code)
        ),
        meta: codes._meta,
        isProfilePage: isProfilePage,
      })),
      catchError((err) => {
        return throwError(() => err.message);
      })
    );
  }

  public getBillingCodeByServiceCode(service_code: string): Observable<any> {
    const url = `/api/billing_codes.json?service_code=${service_code}`;
    return this.http.get(url, { withCredentials: true }).pipe(
      map((codes: any) => new BillingCode(codes.billing_codes[0])),
      catchError((err) => {
        return throwError(() => err.message);
      })
    );
  }

  public dispatchSelectedBillingCode(billingCode: BillingCode | null): void {
    this.store
      .pipe(select(selectSelectedBillingCodeObject), take(1))
      .subscribe((storeBillingCode) => {
        if (storeBillingCode?.service_code !== billingCode?.service_code) {
          this.store.dispatch(
            BillingCodeStoreActions.selectedBillingCode({
              payload: billingCode,
            })
          );
        }
      });
  }

  public setSelectedBillingCodeWithServiceCode(service_code: string): void {
    this.store
      .pipe(select(selectSelectedBillingCodeObject), take(1))
      .subscribe((selectedBillingCode) => {
        if (selectedBillingCode?.service_code !== service_code) {
          this.store.dispatch(
            BillingCodeStoreActions.loadBillingCodeByServiceCode({
              serviceCode: service_code,
            })
          );
        }
      });
  }

  public getBillingCodeOON(billingCode: string): Observable<BillingCodeOON> {
    const url = `/api/providers/oon_allowed_amounts.json`;
    const { zip, geo } = this.locationService.geo.getValue();
    const latLng = geo.split(',');
    const params = {
      billing_code: billingCode,
      radius: null,
      zip: zip,
      lat: latLng[0],
      lng: latLng[1],
      member_number: null,
    };
    return combineLatest([
      this.getRadius(),
      this.getMemberNumber(),
      this.getOONCoverageSetting(),
    ]).pipe(
      take(1),
      tap(([radius, memberNumber, oon_coverage]) => {
        params.radius = radius;
        params.member_number = memberNumber;
      }),
      switchMap(([radius, memberNumber, oon_coverage]) =>
        defer(() => {
          if (oon_coverage !== false) {
            return this.http
              .get(url, { params: params, withCredentials: true })
              .pipe(
                map((oon: BillingCodeOON) => new BillingCodeOON(oon)),
                catchError((err) => {
                  const errorMessage = this.showErrorStatuses.includes(
                    err?.error?.status_code
                  )
                    ? err?.error?.error
                    : '';
                  return throwError(() => errorMessage);
                })
              );
          } else {
            return of(
              new BillingCodeOON({
                show_not_covered_card: true,
              })
            );
          }
        })
      )
    );
  }

  public getBillingCodeFaq(): Observable<FaqQuestionItem[]> {
    return combineLatest([
      this.translateService.onLangChange.pipe(startWith(true)),
      this.settingsService.getSetting('tinc_landing_faq_questions'),
    ]).pipe(
      map(([_languageChange, questionsSetting]) => {
        let questionsToTranslate = cloneDeep(this.defaultFaqQuestions);
        if (questionsSetting) {
          const { questions, overwrite } = questionsSetting;
          if (questions.length > 0) {
            if (overwrite) {
              questionsToTranslate = questions;
            } else {
              questionsToTranslate = questionsToTranslate.concat(questions);
            }
          }
        }
        return this.mapFaqQuestions(questionsToTranslate);
      })
    );
  }

  public getDefaultRadius(): Observable<string> {
    return this.store.pipe(
      select(selectDefaultRadiusFilterRates),
      filter((radius) => radius),
      take(1)
    );
  }

  private getOONCoverageSetting(): Observable<boolean> {
    return this.settingsService.getSetting('oon_coverage');
  }

  private mapFaqQuestions(
    questionsConfig: FaqQuestionItem[]
  ): FaqQuestionItem[] {
    const questions = cloneDeep(questionsConfig);
    return questions.map((question) => {
      question.title = this.translateService.instant(question.title);
      question.content = this.translateService.instant(question.content);
      return new FaqQuestionItem(question);
    });
  }

  private getRadius(): Observable<string> {
    return this.route.queryParamMap.pipe(
      map((params) => (params.has('radius') ? params.get('radius') : null)),
      switchMap((radius) => {
        if (radius) {
          return of(radius);
        }
        return this.getDefaultRadius();
      }),
      take(1)
    );
  }

  private getMemberNumber(): Observable<string> {
    return this.appParams.listenToSelectedMemberNumber().pipe(take(1));
  }
}
