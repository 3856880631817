import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';
import { AlphaListComponent } from './alpha-list.component';
import { AZListLegendModule } from '@zelis/platform-ui-components';
import { AlphaListItemComponent } from './alpha-list-item/alpha-list-item.component';
import { GtmModule } from '../../directives/gtm/gtm.module';
import { AlphaListMenuItemComponent } from './alpha-list-menu-item/alpha-list-menu-item.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DlsLozengeModule } from '@zelis/dls/lozenge';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DlsListModule } from '@zelis/dls/list';

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AZListLegendModule,
    GtmModule,
    ScrollingModule,
    DlsListModule,
    DlsLozengeModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  declarations: [
    AlphaListComponent,
    AlphaListItemComponent,
    AlphaListMenuItemComponent,
  ],
  exports: [AlphaListComponent],
  providers: [CurrencyPipe],
})
export class AlphaListModule {}
