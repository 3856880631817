<div
  *ngIf="themingCcssResolved | async; else loader"
  [ngClass]="{
    'external-link-indicator': showExternalLinkIndicator,
  }"
  [ngClass.lt-md]="{
    'external-link-indicator': showExternalLinkIndicator,
    mobile: true
  }"
  #appContainer
  fxLayout="row"
  fxLayoutAlign="start stretch"
>
  <app-meta-information *ngIf="metaInfoEnabled"></app-meta-information>

  <div class="app-wrapper" [ngClass]="{ 'bva-main-content': bvaEnabled }">
    <div class="app-progress-bar">
      <zelis-progress-bar
        *ngIf="showProgressBar"
        color="primary"
        mode="indeterminate"
      >
      </zelis-progress-bar>
    </div>

    <div class="msa-banner-container" data-cy="app.msa-banner">
      <app-msa-banner> </app-msa-banner>
    </div>

    <header
      class="global-header-container"
      [hidden]="routeUtilities.hideOnRoutes(router.url, ['msaform', 'demo'])"
      [ngClass]="ccss.getClass('header')"
    >
      <app-global-header> </app-global-header>
    </header>

    <main
      class="main-container"
      appSkipLinkTarget
      skipLinkText="skip_to_main_content"
      [skipLinkIndex]="0"
      [skipLinkPersistAcrossNavigation]="true"
      tabindex="-1"
    >
      <router-outlet></router-outlet>
    </main>

    <footer
      class="global-footer-container"
      [ngClass]="[ccss.getClass('footer')]"
      [ngClass.gt-sm]="[ccss.getClass('footer'), 'desktop']"
      [fxHide]="searchView.mapViewSelected"
      role="contentinfo"
    >
      <platform-page-container [fullscreen]="false" [contentSpacing]="true">
        <platform-global-footer
          [ngClass]="{ 'logo-link-suppressed': suppressLogoLink }"
          [translations]="{
            legals: 'app_footer_legals' | translate,
            lastUpdated: 'app_footer_lastUpdated' | translate,
            poweredBy: 'app_footer_poweredBy' | translate,
            termsOfUse: 'app_footer_termsOfUse' | translate,
            privacyPolicy: 'app_footer_privacyPolicy' | translate,
            logoAltText: 'app_company_logo_alt_text' | translate
          }"
          [lastUpdate]="updatedAt | async"
          [suppressLogoLink]="suppressLogoLink"
        >
          <div
            class="footer-disclaimers"
            *ngIf="footerLinks | async as footLinks"
            data-cy="footer-disclaimers"
          >
            <div
              class="footer-external-links"
              *ngIf="footLinks.external"
              data-cy="footer-external-links"
            >
              <ul>
                <li
                  *ngFor="let link of footLinks.external; trackBy: trackByText"
                >
                  <a
                    target="_blank"
                    rel="noopener"
                    [href]="link.uri"
                    [innerHTML]="link.text | translate"
                  ></a>
                </li>
              </ul>
            </div>

            <div
              class="footer-internal-links"
              *ngIf="footLinks.internal"
              data-cy="footer-internal-links"
            >
              <ul>
                <li
                  *ngFor="let link of footLinks.internal; trackBy: trackByText"
                >
                  <button
                    class="platform-button leading-10 text-sm underline"
                    [ngClass]="link.text"
                    [routerLink]="link.uri"
                    [innerHTML]="link.text | translate"
                    queryParamsHandling="preserve"
                  ></button>
                </li>
              </ul>
            </div>

            <div
              *ngFor="
                let disclaimer of footerDisclaimers | async;
                trackBy: trackById
              "
              class="disclaimers"
            >
              <p
                *ngIf="disclaimer.label"
                [innerHTML]="disclaimer.label | translate"
              ></p>
              <p [innerHTML]="disclaimer.text | translate"></p>
            </div>
          </div>
        </platform-global-footer>
      </platform-page-container>
      <div class="footer-app-copyright">
        <platform-page-container [fullscreen]="false" [contentSpacing]="true">
          {{ 'app_footer_vc_copyright' | translate: { year: copyrightYear } }}
        </platform-page-container>
      </div>
    </footer>
  </div>

  <div [ngClass]="{ 'bva-content': bvaEnabled }">
    <app-bva-sidebar></app-bva-sidebar>
  </div>
  <div *ngIf="!fabDisabled && persistChat">
    <app-sticky-contact-fab></app-sticky-contact-fab>
  </div>

  <div *ngIf="!chatDisabled && persistChat">
    <app-chat></app-chat>
  </div>
</div>

<ng-template #loader>
  <div class="spinner">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</ng-template>
