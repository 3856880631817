import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { combineLatest, Observable, of } from 'rxjs';
import {
  map,
  catchError,
  switchMap,
  filter,
  distinctUntilChanged,
} from 'rxjs/operators';
import { TelehealthDetails } from '@interfaces/telehealth-details.model';
import { SettingsService } from '@services/settings.service';
import { TelehealthVendor } from '@interfaces/telehealth-vendor.model';
import { ConfigurationService } from '@services/configuration.service';
import { AppParamsService } from '@services/app.params.service';

@Injectable({
  providedIn: 'root',
})
export class TelehealthService {
  public vendors: Observable<TelehealthVendor[]>;
  private telehealthDetails: Observable<TelehealthDetails[]>;

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private configurationService: ConfigurationService,
    private appParams: AppParamsService
  ) {
    this.telehealthDetails = this.requestDetails();
    this.vendors = this.getVendors();
  }

  /**
   * Returns vendors that match given vendor codes.
   * @param vendors: array of mapped SSO vendor settings.
   * @param vendorCodes: array of vendor code strings.
   * @returns TelehealthVendor[]
   */
  public getFilteredVendors(
    vendors: TelehealthVendor[],
    vendorCodes: string[] = []
  ): TelehealthVendor[] {
    vendors = vendors.filter((vendor: TelehealthVendor) => {
      // vendorCodes is an array in format of vendor|prodtype
      return vendorCodes.includes(vendor.code + '|' + vendor.prodtype);
    });
    return vendors;
  }

  private getConfigSignature(): Observable<string> {
    return this.configurationService.signature.pipe(
      filter((sig: string) => !!sig),
      distinctUntilChanged()
    );
  }

  /**
   * Returns teleheath details
   * @param sig: config signature string.
   * @returns TelehealthDetails[]
   */
  private getTelehealthDetails(sig: string): Observable<TelehealthDetails[]> {
    const params: HttpParams = this.appParams.setHttpParams({
      config_signature: sig,
    });
    const url = `/api/telehealth_details.json`;
    return this.http.get(url, { params: params, withCredentials: true }).pipe(
      catchError(() => of({})),
      map((data: any) => (data && data.telehealth_details) || []),
      map((data: TelehealthDetails[]) =>
        data.map((item) => new TelehealthDetails(item))
      ),
      map((details) =>
        details.filter((item) => item.teleHealthVendCd && item.teleHealthUrl)
      )
    );
  }

  /**
   * Returns array of SSO and config telehealth details.
   * @returns Observable<TelehealthDetails[]>
   */
  private requestDetails(): Observable<TelehealthDetails[]> {
    return this.getConfigSignature().pipe(
      switchMap((sig) => this.getTelehealthDetails(sig))
    );
  }

  /**
   * Returns mapped SSO vendor settings.
   * @returns Observable<TelehealthVendor[]>
   */
  private getVendors(): Observable<TelehealthVendor[]> {
    return combineLatest([
      this.telehealthDetails,
      this.settingsService.getSetting('telehealth_vendors'),
    ]).pipe(
      switchMap(([details, vendorSettings]) => {
        const vendors = details
          .map((detail: TelehealthDetails) => {
            let vendor = {} as any;
            if (vendorSettings && vendorSettings[detail.teleHealthVendCd]) {
              vendor =
                vendorSettings[detail.teleHealthVendCd][
                  detail.teleHealthProdType
                ];
              vendor.prodtype = detail.teleHealthProdType;
              vendor.code = detail.teleHealthVendCd;
              vendor.link = detail.teleHealthUrl;
              return new TelehealthVendor(vendor);
            }
          })
          .filter((val) => val !== undefined);
        return of(vendors);
      })
    );
  }
}
