import { PrintData } from '@interfaces/print.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StorageUtilities } from '@utilities/storage.utilities';

export interface State extends EntityState<PrintData> {
  selected: string | null;
}

export const printAdapter: EntityAdapter<PrintData> =
  createEntityAdapter<PrintData>({
    selectId: (printData: PrintData) => printData.id,
    sortComparer: false,
  });

const storage = new StorageUtilities();
const saved = storage.sessionStorageGet('printData');

export const savedState = printAdapter.getInitialState({
  ...saved,
});

export const printDataState = printAdapter.getInitialState({
  ...savedState,
});
