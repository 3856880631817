import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Breakpoints } from '@classes/breakpoints.class';

@Component({
  selector: 'app-wizard-selection-card',
  templateUrl: './wizard-selection-card.component.html',
})
export class WizardSelectionCardComponent {
  @Input() public title: string;
  @Input() public description?: string;
  @Input() public ctaText?: string;
  @Output() public cta: EventEmitter<any> = new EventEmitter();

  constructor(public breakpoints: Breakpoints) {}

  public handleSubmit(event: Event): void {
    this.cta.emit(event);
  }
}
