import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationOfCareComponent } from './location-of-care.component';
import { DlsButtonModule } from '@zelis/dls/button';
import { GlobalLocationSelectModule } from '@components/global-header/location/global-location-select.module';
import { TranslateModule } from '@ngx-translate/core';
import { WizardLoginLinkModule } from '@components/network-selection-wizard/wizard-login-link/wizard-login-link.module';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [LocationOfCareComponent],
  imports: [
    CommonModule,
    DlsButtonModule,
    GlobalLocationSelectModule,
    TranslateModule,
    MatDividerModule,
    WizardLoginLinkModule,
  ],
  exports: [LocationOfCareComponent],
})
export class LocationOfCareModule {}
