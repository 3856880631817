import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WizardCardLayoutTemplateComponent } from './wizard-card-layout-template.component';
import { WizardSelectionCardModule } from '../wizard-selection-card/wizard-selection-card.module';

@NgModule({
  declarations: [WizardCardLayoutTemplateComponent],
  imports: [CommonModule, WizardSelectionCardModule],
  exports: [WizardCardLayoutTemplateComponent],
})
export class WizardCardLayoutTemplateModule {}
