<span class="network-container">
  <span
    [ngClass]="{
      'high-contrast-theme': darkTheme,
      'network-disabled': disableDropdown && !mobile
    }"
  >
    <mat-form-field
      (click)="openNetworkTray()"
      (keyup.enter)="openNetworkTray()"
      appearance="{{ appearance }}"
      class="global-network-dropdown mt-[2px]"
      [ngClass]="{ hide: hideFormField, 'dark-bg': darkTheme }"
      [attr.aria-label]="'home_network_select' | translate"
      data-cy="global-network.network-tray"
      subscriptSizing="dynamic"
    >
      <mat-label [ngClass]="{ 'text-palette-m3-system-gray-10': !darkTheme }">
        {{ 'app_global_network' | translate }}
      </mat-label>
      <mat-select
        #selectRef
        [disabled]="disableDropdown && !mobile"
        [placeholder]=""
        [(ngModel)]="selectedOption"
        [panelClass]="getPanelClass(hideFormField, disableDropdown)"
        [ngClass]="{
          toutable: toutEnabled && !toutDismissed,
          tout: tout && !toutDismissed
        }"
        (selectionChange)="handleSelect($event.value)"
        (click)="closeFixedTooltips()"
        (keyup.enter)="closeFixedTooltips()"
        name="network"
        class="network-select"
        [class.pointer-events-none]="mobile"
        data-cy="global-network.network-select"
        [attr.aria-label]="'home_network_select' | translate"
      >
        <mat-option
          class="network-select-option border-t border-gray-100"
          *ngIf="!autoSelect"
          value="*"
        >
          {{ 'home_network_select' | translate }}
        </mat-option>
        <mat-option
          class="border-t border-gray-100"
          [ngClass]="
            hideFormField
              ? 'mobile-network-select-option'
              : 'network-select-option'
          "
          *ngFor="
            let network of mappedNetworks;
            trackBy: trackByNetworkId;
            let i = index
          "
          (click)="dismissMobileTray()"
          (keyup.enter)="dismissMobileTray()"
          [value]="network.id"
          [attr.aria-label]="network.name | translate"
          [attr.data-cy]="'network-options.option.' + i"
        >
          <span
            class="network-name text-sm tracking-normal"
            [title]="network.name | translate"
          >
            {{ network.name | translate }}
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </span>
  <app-fixed-tooltip
    *ngIf="toutEnabled && !toutDismissed"
    (closeElement)="onTooltipClose()"
    [title]="'tout_network_title' | translate"
    [text]="'tout_network_text' | translate"
  >
  </app-fixed-tooltip>
  <app-fixed-tooltip
    *ngIf="
      alphaPrefixNetworkToutEnabled &&
      mappedNetworks?.length > 0 &&
      !toutDismissed
    "
    [title]="'tout_network_title' | translate"
    [text]="'tout_network_alpha_prefix_text' | translate"
    [showDismissButton]="true"
    [callToActionText]="'tout_network_alpha_prefix_cta_text' | translate"
    (callToActionClick)="onAlphaPrefixNetworkToutClick()"
    (dismissClick)="onAlphaPrefixNetworkToutDismiss()"
    (closeElement)="onTooltipClose()"
  >
  </app-fixed-tooltip>
</span>
