<div class="passive-chat-container" [ngClass.lt-md]="'passive-chat-mobile'">
  <div class="header-overlay">
    <h4
      class="text-medium"
      [innerHTML]="'smartshopper_let_us_help' | translate"
    ></h4>
  </div>
  <div class="secondary-content" fxLayout="column" fxLayoutGap="16px">
    <div
      *ngIf="'smartshopper_pat_phone' | translate"
      [innerHTML]="
        'smartshopper_call_number'
          | translate: { pat_phone: ('smartshopper_pat_phone' | translate) }
      "
    ></div>
    <div
      *ngIf="(isAvailable | async) && ('smartshopper_pat_phone' | translate)"
      fxLayout="row"
      class="break-container"
    >
      <div class="heading-break"></div>
      <div class="or text-medium">{{ 'app_global_or' | translate }}</div>
      <div class="heading-break"></div>
    </div>
    <app-chat-button
      *ngIf="isAvailable | async"
      chatId="sticky_fab"
      [buttonCopy]="'smartshopper_chat_copy' | translate"
    >
    </app-chat-button>
  </div>
</div>
