import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AppParamsService } from '@services/app.params.service';
import { SearchParams } from '@interfaces/search-params.model';
import { NetworksService } from '@services/networks.service';
import { ProceduresService } from '@services/procedures.service';
import { SearchFacetParamsService } from '@components/+search/classes/search-facet-params.service';
import { BehaviorSubject } from 'rxjs';
import { CriticalParamsService } from '@services/critical-params/critical-params.service';
import { ConfigurationService } from '@services/configuration.service';
import { SearchFilterV2 } from '@interfaces/search-filter-v2.model';

@Injectable({
  providedIn: 'root',
})
export class SearchParamsService {
  public lastSearchParams: BehaviorSubject<HttpParams> = new BehaviorSubject(
    new HttpParams()
  );

  constructor(
    private appParamsService: AppParamsService,
    private searchFacetParams: SearchFacetParamsService,
    private networksService: NetworksService,
    private configurationService: ConfigurationService,
    private proceduresService: ProceduresService,
    private criticalParamsService: CriticalParamsService,
    private searchFacetParamsService: SearchFacetParamsService
  ) {}

  public clearLastSearchParams(): void {
    this.lastSearchParams.next(null);
  }

  public setHttpParams(
    filters: any,
    facetsRequest: boolean = false,
    filtersFromStore?: SearchFilterV2[]
  ): HttpParams {
    const selectedNetwork = this.networksService.getSelectedNetwork();

    let params: HttpParams = this.appParamsService.setHttpParams(
      new SearchParams(
        {
          ...filters,
          ...this.criticalParamsService.criticalParams,
          account_id: this.configurationService.account_id.getValue(),
        },
        facetsRequest,
        selectedNetwork.tier_code
      )
    );
    if (filters.locale) {
      params = params.set('data_language', filters.locale);
    }
    if (params.get('award_type_codes') === 'blue_compare') {
      params = params.delete('award_type_codes').set('blue_compare', 'true');
    }
    if (filters.age_limitations) {
      params = params.delete('age_limitations');
      params = params.set('age_limitations:' + filters.age_limitations, 'true');
    }
    if (filters.procedure_id) {
      params = this.addProcedureParams(params, filters.procedure_id);
    } else {
      params = this.addMemberParams(params);
    }
    if (filtersFromStore) {
      params = this.searchFacetParamsService.updateMultiSelectFilterParams(
        params,
        filtersFromStore
      );
      params = this.searchFacetParamsService.handleSingleTiersParam(
        params,
        filtersFromStore
      );
    }

    const lastSearchParams = this.searchFacetParams.calculate(
      params,
      facetsRequest,
      filtersFromStore
    );
    if (!facetsRequest) {
      this.lastSearchParams.next(lastSearchParams);
    }
    return lastSearchParams;
  }

  public getReferrerParams(
    type: string,
    radius: number,
    clientCanonicalId: string,
    providerGeo: string
  ): any {
    const out: any = { radius: radius, provider_geo: providerGeo };
    out[type] = this.lastSearchParams.getValue().get(type);
    if (clientCanonicalId) {
      out.client_canonical_id = clientCanonicalId;
    }
    return out;
  }

  private addMemberParams(params: HttpParams): HttpParams {
    params = params.delete('procedure_id');
    const memberParams = this.appParamsService.getMemberParams();
    if (memberParams) {
      params = params.set('member_number', memberParams.member_number);
    }
    return params;
  }

  private addProcedureParams(
    params: HttpParams,
    procedureId: string
  ): HttpParams {
    const procedureParams =
      this.proceduresService.setProcedureParams(procedureId);
    procedureParams.keys().forEach((k) => {
      params = params.set(k, procedureParams.get(k));
    });
    return params;
  }
}
