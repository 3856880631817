<div>
  <div class="location-input" data-cy="global-location-select.input">
    <mat-form-field
      appearance="{{ appearance }}"
      class="location-autosuggest mt-[2px] w-full"
      floatLabel="always"
      subscriptSizing="dynamic"
      [ngClass]="{ 'dark-bg': darkTheme }"
    >
      <mat-label [ngClass]="{ 'text-palette-m3-system-gray-10': !darkTheme }">
        {{
          gpsLoading ? '' : ('global_location_select_placeholder' | translate)
        }}
      </mat-label>
      <span class="gps-loading" *ngIf="gpsLoading" matPrefix>
        <div class="flex flex-row pl-4">
          <zelis-progress-spinner [diameter]="24" [strokeWidth]="4">
          </zelis-progress-spinner>
          <span class="p-2 ml-8">
            {{ 'app_global_locating' | translate }}
          </span>
        </div>
      </span>
      <input
        class="selectionColor ellipsis"
        appUnfocus
        type="text"
        #autoCompleteInput
        matInput
        onclick="this.select()"
        onfocus="this.select()"
        (blur)="setAutosuggestText()"
        [(ngModel)]="gpsLoading ? '' : text"
        (ngModelChange)="onInputChange()"
        [matAutocomplete]="auto"
        [attr.aria-label]="'home_location_select' | translate"
        [disabled]="gpsLoading"
        (keyup)="onKeyUp()"
        data-cy="location-input.selector"
      />
      <mat-icon
        matSuffix
        svgIcon="dls-location-arrow-solid"
        class="h-5 w-5 leading-8"
      >
      </mat-icon>
    </mat-form-field>
  </div>
</div>

<mat-autocomplete
  class="global-location-panel"
  #auto="matAutocomplete"
  panelWidth="auto"
  (optionSelected)="handleSelect($event.option.value)"
>
  <mat-option
    appUnfocus
    class="geo-icon"
    data-cy="location-select.current-location"
    [ngClass]="{ 'mobile-current-location': mobileOverlay }"
    role="option"
    [attr.aria-label]="'app_global_current_location' | translate"
    [value]="'current-location'"
    tabindex="0"
  >
    <span class="flex flex-row align-middle">
      <mat-icon
        matSuffix
        svgIcon="dls-location-arrow-solid"
        class="h-5 w-5 text-palette-m3-system-blue-40"
      >
      </mat-icon>
      <span class="text-palette-m3-system-blue-40">{{
        'app_global_current_location' | translate
      }}</span>
    </span>
  </mat-option>
  <mat-option
    *ngFor="let option of mappedResults; let i = index"
    [ngClass]="{ 'mobile-location-option': mobileOverlay }"
    [value]="option"
  >
    <div
      class="global-location-option"
      [attr.data-cy]="'location-options.option.' + i"
    >
      {{ option.toString() }}
    </div>
  </mat-option>
  <mat-option *ngIf="mappedResults !== null && !mappedResults?.length">
    <div class="no-location-result" data-cy="location-select.no-result">
      {{ 'global_location_select_no_results' | translate }}
    </div>
  </mat-option>
</mat-autocomplete>
