import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { SavedProvidersService } from '@services/saved-providers/saved-providers.service';
import {
  addProviderToSavedProviders,
  addSavedProvidersFailure,
  addSavedProvidersSuccess,
  removeProviderFromSavedProviders,
  removeSavedProvidersFailure,
  removeSavedProvidersSuccess,
  requestSavedProviders,
  requestSavedProvidersFailure,
  requestSavedProvidersSuccess,
  setSavedProvidersEnabled,
} from './saved-providers.actions';
import { catchError, map, mergeMap, of, tap, withLatestFrom } from 'rxjs';
import { SavedProvider } from '@interfaces/saved-provider.model';
import { AppParamsService } from '@services/app.params.service';

@Injectable()
export class SavedProvidersEffects {
  dispatchSavedProvidersEnabled = createEffect(() =>
    this.actions.pipe(
      ofType(setSavedProvidersEnabled),
      map((action) => requestSavedProviders(action))
    )
  );

  requestSavedProviders = createEffect(() =>
    this.actions.pipe(
      ofType(requestSavedProviders),
      mergeMap(({ member_number }) => {
        return this.savedProvidersService
          .requestSavedProviders(member_number)
          .pipe(
            map(({ saved_providers }: { saved_providers: SavedProvider[] }) => {
              return requestSavedProvidersSuccess({
                results: saved_providers.map(
                  (savedProvider) => new SavedProvider(savedProvider)
                ),
              });
            }),
            catchError((error: TypeError) =>
              of(
                requestSavedProvidersFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );

  postSavedProviders = createEffect(() =>
    this.actions.pipe(
      ofType(addProviderToSavedProviders),
      withLatestFrom(this.appParams.listenToSelectedMemberNumber()),
      mergeMap(([{ providerCCID, providerName }, memberNumber]) => {
        return this.savedProvidersService
          .postSavedProviders(providerCCID, memberNumber)
          .pipe(
            map((_result) => {
              this.savedProvidersService.listUpdatedSinceWarning.next(true);
              return addSavedProvidersSuccess({
                savedProvider: new SavedProvider({
                  client_canonical_id: providerCCID,
                }),
                providerName: providerName,
              });
            }),
            catchError((error: TypeError) =>
              of(
                addSavedProvidersFailure({
                  error: error,
                  providerCCID: providerCCID,
                })
              )
            )
          );
      })
    )
  );

  deleteSavedProviders = createEffect(() =>
    this.actions.pipe(
      ofType(removeProviderFromSavedProviders),
      withLatestFrom(this.appParams.listenToSelectedMemberNumber()),
      mergeMap(([{ savedProvider, providerName }, memberNumber]) => {
        return this.savedProvidersService
          .deleteSavedProviders(savedProvider.client_canonical_id, memberNumber)
          .pipe(
            map((_result) => {
              this.savedProvidersService.listUpdatedSinceWarning.next(true);
              return removeSavedProvidersSuccess({
                providerCCID: savedProvider.client_canonical_id,
                providerName: providerName,
              });
            }),
            catchError((error: TypeError) =>
              of(
                removeSavedProvidersFailure({
                  error: error,
                  savedProvider: { ...savedProvider, updating: false },
                })
              )
            )
          );
      })
    )
  );

  alertAddSavedProvidersSuccess = createEffect(
    () =>
      this.actions.pipe(
        ofType(addSavedProvidersSuccess),
        tap((action) =>
          this.savedProvidersService.alertAddSavedProviderSuccess(
            action.providerName
          )
        )
      ),
    { dispatch: false }
  );

  alertAddSavedProvidersFailure = createEffect(
    () =>
      this.actions.pipe(
        ofType(addSavedProvidersFailure),
        tap(() => this.savedProvidersService.alertAddSavedProviderFailure())
      ),
    { dispatch: false }
  );

  alertRemoveSavedProvidersSuccess = createEffect(
    () =>
      this.actions.pipe(
        ofType(removeSavedProvidersSuccess),
        tap((action) =>
          this.savedProvidersService.alertRemoveSavedProvidersSuccess(
            action.providerName
          )
        )
      ),
    { dispatch: false }
  );

  alertRemoveSavedProvidersFailure = createEffect(
    () =>
      this.actions.pipe(
        ofType(removeSavedProvidersFailure),
        tap(() => this.savedProvidersService.alertRemoveSavedProvidersFailure())
      ),
    { dispatch: false }
  );

  constructor(
    private actions: Actions,
    private savedProvidersService: SavedProvidersService,
    private appParams: AppParamsService
  ) {}
}
