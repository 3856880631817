import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './saved-providers.reducer';
import { SavedProvidersEffects } from './saved-providers.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('saved_providers', reducer),
    EffectsModule.forFeature([SavedProvidersEffects]),
  ],
})
export default class SavedProvidersModule {}
