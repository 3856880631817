export class GatedEntryOverlayConfig {
  public enabled?: boolean;
  public authUserEnabled?: boolean;
  public homeScreenDisabled?: boolean;
  public locationAutoResolve?: boolean;
  public networkSelectionDisabled?: boolean;
  public alwaysShow?: boolean;

  constructor(config?: GatedEntryOverlayConfig) {
    config = config || {};
    // Defaulted to enabled
    this.enabled = config.enabled !== false;
    this.authUserEnabled = this.isAuthUserEnabled(config);
    // Defaulted to home screen not disabled
    this.homeScreenDisabled = config.homeScreenDisabled === true;
    // Defaulted to NOT location auto-resolve
    this.locationAutoResolve = config.locationAutoResolve === true;
    // Defaulted to network selection not disabled
    this.networkSelectionDisabled = config.networkSelectionDisabled === true;
    this.alwaysShow = config.alwaysShow === true;
  }

  private isAuthUserEnabled?(config): boolean {
    if (typeof config.authUserEnabled === 'boolean') {
      return config.authUserEnabled;
    } else {
      return this.enabled;
    }
  }
}
