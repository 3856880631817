import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { GatedEntryOverlayHomeComponent } from './gated-entry-overlay-home.component';
import { DlsButtonModule } from '@zelis/dls/button';
import { GtmModule } from '../../../directives/gtm/gtm.module';
import { MatIconModule } from '@angular/material/icon';
@NgModule({
  declarations: [GatedEntryOverlayHomeComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    DlsButtonModule,
    GtmModule,
    MatIconModule,
  ],
  exports: [GatedEntryOverlayHomeComponent],
})
export class GatedEntryOverlayHomeModule {}
