const searchRoutesMap = {
  procedures: 'procedure_id',
  search_specialties: 'search_specialty_id',
  specialty_search: 'search_specialty_id',
  name: 'name',
  providers: 'name',
  hospital_affiliations: 'hospital_affiliation_ids',
  group_affiliations: 'group_affiliation_ids',
};

export default searchRoutesMap;
