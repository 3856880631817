<button
  (click)="openMenu()"
  class="mobile-hamburger-menu p-0"
  type="button"
  [attr.aria-label]="'app_global_menu' | translate"
>
  <mat-icon
    svgIcon="dls-hamburger-light"
    class="w-10 h-10 align-middle"
    data-cy="mobile-hamburger-menu.icon"
  ></mat-icon>
</button>
