<ng-container *ngIf="cards.length !== 5; else fiveCardLayout">
  <div
    class="container mb-10"
    [class.md:flex-row]="cards.length <= 3"
    [class.md:grid]="cards.length === 4 || cards.length === 6"
    [class.md:w-auto]="cards.length === 4 || cards.length === 6"
    [class.grid-cols-2]="cards.length === 4"
    [class.grid-cols-3]="cards.length === 6"
  >
    <div
      *ngFor="let card of cards"
      class="card-width"
      [class.lg:w-auto]="cards.length === 6"
      [class.xl:w-96]="cards.length === 6"
    >
      <app-wizard-selection-card
        [title]="card.title"
        [description]="card.description"
        [ctaText]="card.ctaText"
        (cta)="card.handleSubmit($event)"
      >
      </app-wizard-selection-card>
    </div>
  </div>
</ng-container>

<ng-template #fiveCardLayout>
  <div class="container md:flex-row">
    <div *ngFor="let card of cards.toArray().slice(0, 3)" class="card-width">
      <app-wizard-selection-card
        [title]="card.title"
        [description]="card.description"
        [ctaText]="card.ctaText"
        (cta)="card.handleSubmit($event)"
      >
      </app-wizard-selection-card>
    </div>
  </div>

  <div class="container md:flex-row mt-4 mb-10">
    <div *ngFor="let card of cards.toArray().slice(3, 5)" class="card-width">
      <app-wizard-selection-card
        [title]="card.title"
        [description]="card.description"
        [ctaText]="card.ctaText"
        (cta)="card.handleSubmit($event)"
      >
      </app-wizard-selection-card>
    </div>
  </div>
</ng-template>
