import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { BillingCodeService } from '@services/billing-code.service';
import * as BillingCodeActions from './billing-code.actions';
import { BillingCode } from '@interfaces/billing-code.interface';
import { BillingCodeBenefitLimits } from '@interfaces/billing-code-benefit-limits.model';
import { BillingCodeOON } from '@interfaces/billing-code-OON.model';
import { RatesSummaryResultsActions } from '@store/rates-serp/serp-summary';
import { BillingCodePreRequisite } from '@interfaces/billing-code-pre-requisite.model';

@Injectable()
export class BillingCodeEffects {
  getBillingCodesAutocompleteSearchTerm = createEffect(() =>
    this.actions.pipe(
      ofType(BillingCodeActions.loadBillingCodesByAutocompleteSearchTerm),
      mergeMap((action) => {
        return this.billingCodeService
          .searchBillingCodes(
            action.searchTerm,
            action.page,
            action.isProfilePage
          )
          .pipe(
            map((data: { billing_codes: BillingCode[]; meta: any }) =>
              BillingCodeActions.loadBillingCodesByAutocompleteSearchTermSuccess(
                {
                  billing_codes: data.billing_codes,
                  meta: data.meta,
                  isProfilePage: action.isProfilePage,
                }
              )
            ),
            catchError((error) =>
              of(
                BillingCodeActions.loadBillingCodesByAutocompleteSearchTermFailure(
                  { error }
                )
              )
            )
          );
      })
    )
  );

  getBillingCodeByServiceCode = createEffect(() =>
    this.actions.pipe(
      ofType(BillingCodeActions.loadBillingCodeByServiceCode),
      mergeMap((action) => {
        return this.billingCodeService
          .getBillingCodeByServiceCode(action.serviceCode)
          .pipe(
            map((billing_code: any) =>
              BillingCodeActions.loadBillingCodeByServiceCodeSuccess({
                billing_code,
              })
            ),
            catchError((error) =>
              of(
                BillingCodeActions.loadBillingCodeByServiceCodeFailure({
                  error,
                })
              )
            )
          );
      })
    )
  );

  setBillingCodeSearchedAsSelectedInStore = createEffect(() =>
    this.actions.pipe(
      ofType(BillingCodeActions.loadBillingCodeByServiceCodeSuccess),
      map((action) =>
        BillingCodeActions.selectedBillingCode({
          payload: action.billing_code,
        })
      )
    )
  );

  dispatchBillingCodeBenefitLimits = createEffect(() =>
    this.actions.pipe(
      ofType(BillingCodeActions.UpdateSelectedBillingCodeDependents),
      map(() => BillingCodeActions.loadBillingCodeBenefitLimits())
    )
  );

  getBillingCodeBenefitLimitsSuccess = createEffect(() =>
    this.actions.pipe(
      ofType(RatesSummaryResultsActions.requestRatesResultsSuccess),
      map((action) => action.results._meta),
      map((meta) => {
        return BillingCodeActions.loadBillingCodeBenefitLimitsSuccess({
          payload: new BillingCodeBenefitLimits({
            benefitLimits: meta.inn_benefit_limit,
          }),
        });
      })
    )
  );

  getBillingCodeBenefitLimitsFailure = createEffect(() =>
    this.actions.pipe(
      ofType(RatesSummaryResultsActions.requestRatesResultsFailure),
      map((action) => action.error),
      map((error: string) => {
        return BillingCodeActions.loadBillingCodeBenefitLimitsFailure({
          error: error,
        });
      })
    )
  );

  dispatchPreRequisite = createEffect(() =>
    this.actions.pipe(
      ofType(BillingCodeActions.UpdateSelectedBillingCodeDependents),
      map(() => BillingCodeActions.loadBillingCodePreRequisite())
    )
  );

  getPreRequisiteSuccess = createEffect(() =>
    this.actions.pipe(
      ofType(RatesSummaryResultsActions.requestRatesResultsSuccess),
      map((action) => action.results._meta),
      map((meta) => {
        return BillingCodeActions.loadBillingCodePreRequisiteSuccess({
          payload: new BillingCodePreRequisite({
            preAuthorization: meta.preauth_required,
            concurrentReview: meta.concurrent_review,
            stepTherapy: meta.step_therapy_fail_first,
          }),
        });
      })
    )
  );

  getPreRequisiteFailure = createEffect(() =>
    this.actions.pipe(
      ofType(RatesSummaryResultsActions.requestRatesResultsFailure),
      map((action) => action.error),
      map((error: string) => {
        return BillingCodeActions.loadBillingCodePreRequisiteFailure({
          error: error,
        });
      })
    )
  );

  dispatchOON = createEffect(() =>
    this.actions.pipe(
      ofType(BillingCodeActions.UpdateSelectedBillingCodeDependents),
      map((action) => BillingCodeActions.loadBillingCodeOON(action))
    )
  );

  getOON = createEffect(() =>
    this.actions.pipe(
      ofType(BillingCodeActions.loadBillingCodeOON),
      map((action) => action.payload),
      mergeMap((billingCode: string) =>
        this.billingCodeService.getBillingCodeOON(billingCode).pipe(
          map((oon: BillingCodeOON) =>
            BillingCodeActions.loadBillingCodeOONSuccess({
              payload: oon,
            })
          ),
          catchError((error) =>
            of(BillingCodeActions.loadBillingCodeOONFailure({ error }))
          )
        )
      )
    )
  );

  dispatchUpdateDependents = createEffect(() =>
    this.actions.pipe(
      ofType(BillingCodeActions.selectedBillingCode),
      map((action) =>
        !!action.payload
          ? BillingCodeActions.UpdateSelectedBillingCodeDependents({
              payload: action.payload.service_code,
            })
          : BillingCodeActions.ResetSelectedBillingCodeDependents()
      )
    )
  );

  constructor(
    private actions: Actions,
    private billingCodeService: BillingCodeService
  ) {}
}
