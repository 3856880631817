import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TermAutosuggest } from '../../classes/term-autosuggest.class';
import { LocationAutosuggest } from '../../classes/location-autosuggest.class';
import { Subscription } from 'rxjs';
import { SubscriptionManager } from '@zelis/platform-ui-components';

@Component({
  selector: 'app-mobile-autosuggest-overlay',
  templateUrl: './mobile-autosuggest-overlay.component.html',
  styleUrls: ['./mobile-autosuggest-overlay.component.scss'],
})
export class MobileAutosuggestOverlayComponent implements OnInit, OnDestroy {
  private subscriptions = new SubscriptionManager();

  constructor(
    public dialogRef: MatDialogRef<MobileAutosuggestOverlayComponent>,
    public termAutosuggest: TermAutosuggest,
    public locationAutosuggest: LocationAutosuggest,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.subscriptions.add(this.subscribeToAutosuggestChosen());
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public onOptionSelect(): void {
    this.dialogRef.close({ optionSelected: true });
  }

  private subscribeToAutosuggestChosen(): Subscription {
    return this.termAutosuggest.autosuggestChosen.subscribe((res) => {
      if (res) {
        this.dialogRef.close();
      }
    });
  }
}
