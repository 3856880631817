import { BillingCodeBenefitLimit } from './billing-code-benefit-limit.model';

export class BillingCodeBenefitLimits {
  benefitLimits?: BillingCodeBenefitLimit[];
  loading?: boolean;
  error?: string;

  constructor(obj?: any) {
    this.benefitLimits = this.mapBenefitLimits(obj?.benefitLimits);
    this.loading = obj?.loading || false;
    this.error = obj?.error || null;
  }

  private mapBenefitLimits(benefitLimits: []): BillingCodeBenefitLimit[] {
    benefitLimits = benefitLimits || [];
    return benefitLimits.map(
      (benefitLimit) => new BillingCodeBenefitLimit(benefitLimit)
    );
  }
}
