import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { SearchCheckboxComponent } from '@components/+search/search-checkbox/search-checkbox.component';
import { DlsCheckboxModule } from '@zelis/dls/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    DlsCheckboxModule,
    MatTooltipModule,
    TranslateModule,
    FlexLayoutModule,
  ],
  declarations: [SearchCheckboxComponent],
  exports: [SearchCheckboxComponent],
})
export class SearchCheckboxModule {}
