import { HttpSpecMock } from '@interfaces/http-spec-mock.model';

export class HttpSpecMockConfig {
  // Spec mocks to use instead of looking for a recording
  public specMocks?: HttpSpecMock[];
  // Spec context for mock recording matching
  public context?: string;
  // Client to use for mock matching and recording
  public client?: string;
  // Login with username
  public login?: string;

  constructor(obj?: HttpSpecMockConfig) {
    obj = obj || {};
    this.specMocks = obj.specMocks
      ? obj.specMocks.map((mock) => new HttpSpecMock(mock))
      : null;
    this.context = obj.context || null;
    this.client = obj.client || null;
    this.login = obj.login || null;
  }
}
