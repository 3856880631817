import { Component } from '@angular/core';
import { CcssService } from '@services/ccss/ccss.service';
import { Observable } from 'rxjs';
import { GuidedSearchService } from '@services/guided-search/guided-search.service';
import { Breakpoints } from '@classes/breakpoints.class';
import { Store } from '@ngrx/store';
import { SearchState } from '@store/search/search.state';
import { setSearchMethodAction } from '@store/search/search.actions';
import { SearchMethods } from '@enums/search-methods.enum';

@Component({
  selector: 'app-guided-search-trigger',
  templateUrl: './guided-search-trigger.component.html',
  styleUrls: ['./guided-search-trigger.component.scss'],
})
export class GuidedSearchTriggerComponent {
  public isEnabled: Observable<boolean>;
  public isReady: Observable<boolean>;

  constructor(
    public ccssService: CcssService,
    public breakpoints: Breakpoints,
    private guidedSearchService: GuidedSearchService,
    private store: Store<SearchState>
  ) {
    this.isEnabled = this.guidedSearchService.isEnabled;
    this.isReady = this.guidedSearchService.isReady;
  }

  public onTriggerClick(): void {
    this.store.dispatch(
      setSearchMethodAction({ searchMethod: SearchMethods.BrowseByCategory })
    );
    this.guidedSearchService.selectGuidedSearchOption();
  }
}
