import {
  filter,
  switchMap,
  map,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocationService } from '@services/location/location.service';
import { GlobalHeaderService } from '@components/global-header/global-header.service';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { SearchTrigger } from './search-trigger.class';
import { TermAutosuggest } from './term-autosuggest.class';
import { CityService } from '@services/location/city-lookup.service';
import { Place } from './place.class';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { LocationGpsStatus } from '@interfaces/location-gps-status.interface';
import { Router } from '@angular/router';
import { RouteUtilities } from '@utilities/route.utilities';
import { CriticalParamsService } from '@services/critical-params/critical-params.service';
import { NetworkSelectionWizardService } from '@services/network-selection-wizard/network-selection-wizard.service';

@Injectable({
  providedIn: 'root',
})
export class LocationAutosuggest {
  public selectedLocation: Place;
  public cities: Place[];
  public state: any;
  public subscriptions = new SubscriptionManager();
  public gpsStatus: BehaviorSubject<LocationGpsStatus> = new BehaviorSubject(
    null
  );
  public autosuggestLocations: BehaviorSubject<Place[]> = new BehaviorSubject(
    null
  );

  private gpsRequestSubscription: Subscription;
  private locationEntered: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    public globalHeaderService: GlobalHeaderService,
    public criticalParamsService: CriticalParamsService,
    public searchTrigger: SearchTrigger,
    public termAutosuggest: TermAutosuggest,
    private locationService: LocationService,
    private cityService: CityService,
    private routeUtilities: RouteUtilities,
    private router: Router,
    private networkSelectWizardService: NetworkSelectionWizardService
  ) {
    this.subscriptions.add(this.subscribeToLocationGeo());
    this.subscriptions.add(this.subscribeToCriticalParamsGeo());
    this.subscriptions.add(this.subscribeToLocationEntered());
  }

  public destroy(): void {
    this.subscriptions.destroy();
  }

  public onLocationEntered(event: any): void {
    this.locationEntered.next(event.termEntered);
  }

  public onLocationSelect(event: Place): void {
    this.selectedLocation = event;
    this.state = this.selectedLocation.state_code;
    this.criticalParamsService.setCriticalParams({
      geo_location:
        this.selectedLocation && this.selectedLocation.geo
          ? this.selectedLocation.geo
          : '',
    });
    this.locationService.locationSelected(event);
    this.locationService.isDetectedLocation.next(false);
    this.locationService.geo.next(event);
    this.networkSelectWizardService.saveSelectedLocation(event);
    this.redoSearch();
  }

  public requestBrowserLocation(): void {
    this.locationGpsIsLoading(true);
    // Cancel existing GPS locate, if any
    if (this.gpsRequestSubscription) {
      this.gpsRequestSubscription.unsubscribe();
    }
    this.gpsRequestSubscription = this.getBrowserLocation().subscribe(
      (place: Place) => {
        if (place && place.isValid()) {
          this.criticalParamsService.setCriticalParams({
            geo_location: place.geo,
          });
          this.selectedLocation = place;
          this.locationService.locationSelected(this.selectedLocation);
          this.locationService.geo.next(place);
          this.redoSearch();
        }
        this.locationGpsIsLoading(false, place);
      }
    );
    this.subscriptions.add(this.gpsRequestSubscription);
  }

  public redoSearch(): void {
    const type = this.searchTrigger.selectedType.value;
    const id = this.searchTrigger.selectedId.value;
    const name = this.searchTrigger.selectedTerm.value;
    const providerTypeDescription =
      this.searchTrigger.providerTypeDescription.value;
    const routeState = this.routeUtilities.getState(this.router.url);
    const isHome = routeState === 'home';
    const isRates = routeState === 'rates';
    const isBillingCodeSearch = routeState === 'billing-code-search';

    if (!!type && !isHome && !isRates && !isBillingCodeSearch) {
      if (providerTypeDescription) {
        this.runProviderTypeSearch(providerTypeDescription);
      } else {
        this.termAutosuggest.activateSearch({ type: type, id: id, name: name });
      }
    }
  }

  private getBrowserLocation(): Observable<Place> {
    return this.locationService
      .browserLocate()
      .pipe(map((place: Place) => (place && place.isValid() ? place : null)));
  }

  private subscribeToLocationGeo(): Subscription {
    return this.locationService.geo
      .pipe(filter((place) => place && place.isValid()))
      .subscribe((place) => {
        this.criticalParamsService.setCriticalParams({
          geo_location: place.geo,
        });
        this.subscribeToCitiesByGeo(place);
      });
  }

  private subscribeToCitiesByGeo(loc: Place): void {
    this.subscriptions.add(
      this.cityService.forPlace(loc).subscribe((results: Place[]) => {
        this.selectedLocation = results[0];
      })
    );
  }

  private subscribeToCriticalParamsGeo(): Subscription {
    return this.criticalParamsService.criticalParamsSubject
      .pipe(
        filter((data) => !!data.geo_location),
        map((data) => data.geo_location),
        distinctUntilChanged()
      )
      .subscribe((geo_location) => {
        if (/-?\d+.?\d*,-?\d+.?\d*/.test(geo_location)) {
          // wait for geo_location to be in lat,lng format
          this.subscribeToCitiesByGeo(
            new Place({
              name: '',
              geo: geo_location,
            })
          );
        }
      });
  }

  private subscribeToLocationEntered(): Subscription {
    return this.getLocationEntered().subscribe((places: Place[]) => {
      this.cities = places;
      this.autosuggestLocations.next(places);
    });
  }

  private getLocationEntered(): Observable<Place[]> {
    return this.locationEntered.pipe(
      filter((term) => !!term && term !== 'current-location'),
      debounceTime(500),
      switchMap((term) => this.cityService.forPlace(new Place({ name: term })))
    );
  }

  private locationGpsIsLoading(loading: boolean, place: Place = null): void {
    this.gpsStatus.next({ loading: loading, place: place });
  }

  private runProviderTypeSearch(providerTypeDescription: string): void {
    this.termAutosuggest.activateSearch(
      { type: 'name', name: '*' },
      {
        provider_type_description: providerTypeDescription,
      }
    );
  }
}
