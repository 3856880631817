import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { SettingsService } from '@services/settings.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { selectSearchTypeSortDefaultOption } from '@store/search-filters/sort-filters/sort-filters.selectors';
import { select, Store } from '@ngrx/store';

const SORT_CONFIG = require('../components/+search/classes/search_sort.config.json');

@Injectable({
  providedIn: 'root',
})
export class SerpLiteRedirectGuard implements CanActivate {
  constructor(
    private settingsService: SettingsService,
    private router: Router,
    private store: Store
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const searchSpecialtyId: number = parseInt(
      route.paramMap.get('search_specialty_id'),
      10
    );
    const geoLocation = route.queryParamMap.get('geo_location');
    return this.settingsService.getSetting('serp_lite_specialties').pipe(
      // Clean setting array
      map((specialties: any[]) => specialties || []),
      // Should redirect if requested specialty ID is in the array
      map((specialties: any[]) =>
        specialties.find(
          (value) => value.search_specialty_id === searchSpecialtyId
        )
      ),
      // Redirect or stay on requested route
      switchMap((specialty: any) =>
        iif(
          () => !!specialty,
          this.redirectToSpecialtySearch(specialty, geoLocation),
          of(true)
        )
      )
    );
  }

  private getSearchSortSetting(): Observable<string> {
    return this.store.pipe(
      select(selectSearchTypeSortDefaultOption('serp_lite')),
      filter((sortDefault) => !!sortDefault),
      map((sortDefault) => sortDefault.query)
    );
  }

  private getRedirectUrlTree(
    specialty: any,
    sortDefault: string,
    geoLocation: string
  ): UrlTree {
    return this.router.createUrlTree(
      ['specialty_search', specialty.search_specialty_id],
      {
        queryParamsHandling: 'merge',
        queryParams: {
          sort: sortDefault,
          radius: specialty.radius,
          geo_location: geoLocation,
          page: '1',
          search_specialty_id: null,
        },
      }
    );
  }

  private redirectToSpecialtySearch(
    specialty: any,
    geoLocation: string
  ): Observable<UrlTree> {
    return this.getSearchSortSetting().pipe(
      map((sortDefault: string) =>
        this.getRedirectUrlTree(specialty, sortDefault, geoLocation)
      )
    );
  }
}
