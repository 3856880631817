import { filter, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RouteUtilities } from '@utilities/route.utilities';
import { ProceduresService } from '@services/procedures.service';
import { SpecialtiesService } from '@services/specialties/specialties.service';
import { SearchTriggerParams } from '@interfaces/search-trigger-params.model';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { Specialty } from '@interfaces/specialty.model';
import { SearchRouteType } from '@interfaces/search-route-type.interface';
import { StorageUtilities } from '@utilities/storage.utilities';
import { Procedure } from '@interfaces/procedure.model';

@Injectable({
  providedIn: 'root',
})
export class SearchTrigger {
  public routeUtilities = new RouteUtilities();
  public autosuggest = new BehaviorSubject(null);
  public selectedTerm = new BehaviorSubject('');
  public selectedId = new BehaviorSubject('');
  public selectedDescription = new BehaviorSubject('');
  public selectedType: BehaviorSubject<SearchRouteType> = new BehaviorSubject(
    null
  );
  public selectedTcc = new BehaviorSubject('');
  public locale = 'en';
  public providerTypeDescription: BehaviorSubject<string> = new BehaviorSubject(
    null
  );

  private priorParams = new BehaviorSubject({});

  constructor(
    private specialtiesService: SpecialtiesService,
    private proceduresService: ProceduresService,
    private translateService: TranslateService,
    private storageUtilities: StorageUtilities
  ) {}

  public setSelectedTerm(params: SearchTriggerParams): void {
    const checkParams = {
      procedure_id: params.procedure_id,
      search_specialty_id: params.search_specialty_id,
      name: params.name,
    };

    if (
      !isEqual(this.priorParams.value, checkParams) ||
      this.translateService.currentLang !== this.locale
    ) {
      this.priorParams.next(checkParams);
      this.locale = this.translateService.currentLang;
      if (params.search_specialty_id && !params.affiliation) {
        this.updateSpecialty(params);
      } else if (params.procedure_id) {
        this.updateProcedure(params);
      } else if (params.name) {
        let nameValue;
        if (params.affiliation && params.name === '*') {
          nameValue = '';
        } else if (params.provider_type_description && params.name === '*') {
          this.providerTypeDescription.next(params.provider_type_description);
          nameValue = decodeURIComponent(params.provider_type_description);
        } else {
          this.providerTypeDescription.next(null);
          nameValue = this.translateService.instant(params.name);
        }
        if (this.isSameSearch(nameValue)) {
          return;
        }
        this.selectedDescription.next('');
        this.selectedTerm.next(nameValue);
        this.selectedType.next('name');
        this.selectedId.next('');
        this.selectedTcc.next('');
      } else {
        this.clearFields();
      }
    }
  }

  public clearFields(): void {
    this.selectedTerm.next('');
    this.selectedDescription.next('');
    this.selectedType.next(null);
    this.selectedId.next('');
    this.selectedTcc.next('');
  }

  private updateSpecialty(params: SearchTriggerParams): void {
    this.specialtiesService
      .requestSpecialties(params.search_specialty_id)
      .pipe(
        filter((specialties) => !!(specialties && specialties.length)),
        first()
      )
      .subscribe((specialties: Specialty[]) => {
        let specialty: Specialty = specialties[0];
        specialty
          ? this.storageUtilities.sessionStorageSet(
              'currentSpecialty',
              specialty
            )
          : (specialty =
              this.storageUtilities.sessionStorageGet('currentSpecialty'));
        const newSearchTerm =
          specialty.name_translated[this.translateService.currentLang] ||
          specialty.name;
        if (this.isSameSearch(newSearchTerm)) {
          return;
        }
        this.selectedTerm.next(
          specialty.name_translated[this.translateService.currentLang] ||
            specialty.name
        );
        this.selectedDescription.next(
          specialty.description[this.translateService.currentLang] || ''
        );
        this.selectedType.next('search_specialties');
        this.selectedId.next(params.search_specialty_id);
        this.selectedTcc.next('');
      });
  }

  private updateProcedure(params: SearchTriggerParams): void {
    this.proceduresService
      .getProcedures(params.procedure_id, this.locale)
      .pipe(
        filter((procedures) => !!(procedures && procedures.length)),
        first()
      )
      .subscribe((procedures: Procedure[]) => {
        const procedure = procedures[0];
        const newSearchTerm = procedure['locale_name'] || '';
        if (this.isSameSearch(newSearchTerm)) {
          return;
        }
        this.selectedTerm.next(procedure['locale_name'] || '');
        this.selectedDescription.next(procedure['description'] || '');
        this.selectedType.next('procedures');
        this.selectedId.next(params.procedure_id);
        this.selectedTcc.next(procedure['treatment_category_code']);
      });
  }

  private isSameSearch(newSearchTerm: string): boolean {
    return this.selectedTerm.getValue() === newSearchTerm;
  }
}
