import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  mergeMap,
  catchError,
  withLatestFrom,
  filter,
  take,
} from 'rxjs/operators';
import * as SettingsFilterActions from './settings-filters/settings-filters.actions';
import * as RadiusFilterActions from './radius-filters/radius-filters.actions';
import * as SortFilterActions from './sort-filters/sort-filters.actions';
import { SortConfig } from '@interfaces/sort-config.model';
import { SearchFilter } from '@interfaces/search-filter.model';
import { SettingsService } from '@services/settings.service';
import { of } from 'rxjs';
import { SearchFiltersSettings } from '@components/+search/classes/search-filters-settings.class';
import { getResolvedNetwork } from '@store/network/network.selectors';
import { SearchFilters } from '@components/+search/classes/search-filters.class';
import { selectSettingsFilters } from '@store/search-filters/settings-filters/settings-filters.selectors';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { SettingsFiltersState } from '@store/search-filters/settings-filters/settings-filters.state';

const RADIUS_CONFIG = require('@components/+search/classes/search_radius.config.json');
const SETTINGS_FILTERS_CONFIG = require('@components/+search/classes/search_filters.config.json');
const SORT_CONFIG = require('@components/+search/classes/search_sort.config.json');

@Injectable()
export class SettingsFiltersEffects {
  getAllSearchFiltersFromConfig = createEffect(() =>
    this.actions.pipe(
      ofType(SettingsFilterActions.requestSettingsSearchFiltersFromConfig),
      withLatestFrom(this.store.select(getResolvedNetwork)),
      mergeMap(([_setting, network]) =>
        this.settingsService.getSetting('search_filters').pipe(
          take(1),
          map((searchFilters: { [filter: string]: SearchFilter }) => {
            return SettingsFilterActions.requestSettingsSearchFiltersFromConfigSuccess(
              {
                settings_filters: searchFilters
                  ? {
                      filters:
                        this.searchFiltersSettings.mapSearchFiltersConfig(
                          searchFilters,
                          network
                        ),
                    }
                  : {
                      filters:
                        this.searchFiltersSettings.mapSearchFiltersConfig(
                          SETTINGS_FILTERS_CONFIG,
                          network
                        ),
                    },
              }
            );
          }),
          catchError((error) =>
            of(
              SettingsFilterActions.requestSettingsSearchFiltersFromConfigFailure(
                {
                  error,
                }
              )
            )
          )
        )
      )
    )
  );

  applyFacetDataToFilters = createEffect(() =>
    this.actions.pipe(
      ofType(SettingsFilterActions.requestFacets),
      map((action) => action.search_params),
      withLatestFrom(
        this.store
          .select(selectSettingsFilters)
          .pipe(filter((state: SettingsFiltersState) => !state.loading))
      ),
      mergeMap(([search_params, storeFilters]) => {
        return this.searchFiltersClass
          .getFiltersWithFacetData(
            'rates',
            search_params,
            cloneDeep(storeFilters.filters)
          )
          .pipe(
            map((filterData: any) => {
              return SettingsFilterActions.setSearchFiltersWithFacetData({
                appliedFilters: filterData,
              });
            }),
            catchError((error: TypeError) =>
              of(
                SettingsFilterActions.requestFacetsFailure({
                  error,
                })
              )
            )
          );
      })
    )
  );

  setSearchFiltersWithLocationGeoFacet = createEffect(() =>
    this.actions.pipe(
      ofType(RadiusFilterActions.requestRadiusSearchFiltersFromConfigSuccess),
      map((action) => action.radius_filters.options),
      map((radiusOptions) =>
        SettingsFilterActions.setSearchFiltersWithLocationGeoFacet({
          location_geo_filter:
            this.searchFiltersClass.buildFacetLocationGeo(radiusOptions),
        })
      )
    )
  );

  constructor(
    private actions: Actions,
    private settingsService: SettingsService,
    private searchFiltersSettings: SearchFiltersSettings,
    private searchFiltersClass: SearchFilters,
    private store: Store
  ) {}
}

@Injectable()
export class RadiusFiltersEffects {
  getAllRadiusSearchFiltersFromConfig = createEffect(() =>
    this.actions.pipe(
      ofType(RadiusFilterActions.requestRadiusSearchFiltersFromConfig),
      mergeMap(() =>
        this.settingsService.getSetting('search_radius').pipe(
          take(1),
          map((radiusFilters: SearchFilter) =>
            RadiusFilterActions.requestRadiusSearchFiltersFromConfigSuccess({
              radius_filters: radiusFilters ? radiusFilters : RADIUS_CONFIG,
            })
          ),
          catchError((error) =>
            of(
              RadiusFilterActions.requestRadiusSearchFiltersFromConfigFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions: Actions,
    private settingsService: SettingsService
  ) {}
}

@Injectable()
export class SortFiltersEffects {
  getAllSortSearchFiltersFromConfig = createEffect(() =>
    this.actions.pipe(
      ofType(SortFilterActions.requestSortSearchFiltersFromConfig),
      withLatestFrom(this.store.select(getResolvedNetwork)),
      mergeMap(([action, network]) =>
        this.settingsService.getSetting('search_sort').pipe(
          take(1),
          map((sortFilters: SortConfig) =>
            SortFilterActions.requestSortSearchFiltersFromConfigSuccess({
              sort_filters: sortFilters
                ? this.searchFiltersSettings.mapSearchSortConfig(
                    sortFilters,
                    network
                  )
                : SORT_CONFIG,
            })
          ),
          catchError((error) =>
            of(
              SortFilterActions.requestSortSearchFiltersFromConfigFailure({
                // do we want to show default configs?
                error,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions: Actions,
    private settingsService: SettingsService,
    private searchFiltersSettings: SearchFiltersSettings,
    private store: Store
  ) {}
}
