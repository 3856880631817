import { SearchSortOption } from '@interfaces/search-sort-option.model';

export class SortConfig {
  selected?: SearchSortOption;
  default?: SearchSortOption[];
  procedure_id_remove?: string[];
  procedure_id_default?: string;
  search_specialty_id_remove?: string[];
  search_specialty_id_default?: string;
  name_remove?: string[];
  name_default?: string;
  serp_lite_remove?: string[];
  serp_lite_default?: string;
  rates_remove?: string[];
  rates_default?: string;
  procedure_id?: SearchSortOption[];
  search_specialty_id?: SearchSortOption[];
  name?: SearchSortOption[];
  rates?: SearchSortOption[];
  serp_lite?: SearchSortOption[];

  constructor(obj: SortConfig) {
    this.selected = obj.selected || {};
    this.default = obj.default || [];
    this.procedure_id_remove = obj.procedure_id_remove || [];
    this.procedure_id_default = obj.procedure_id_default || null;
    this.search_specialty_id_remove = obj.search_specialty_id_remove || [];
    this.search_specialty_id_default = obj.search_specialty_id_default || null;
    this.name_remove = obj.name_remove || [];
    this.name_default = obj.name_default || null;
    // Default sort exclusions. Config extends this list.
    this.serp_lite_remove = [
      'app_global_sort_quality',
      'app_global_rating',
      'app_global_sort_tier',
      ...(obj.serp_lite_remove || []),
    ];
    this.serp_lite_default = obj.serp_lite_default || null;
    this.rates_remove = obj.rates_remove || [];
    this.rates_default = obj.rates_default || null;
    this.procedure_id = obj.procedure_id || [];
    this.search_specialty_id = obj.search_specialty_id || [];
    this.name = obj.name || [];
    this.rates = obj.rates || [];
    this.serp_lite = obj.serp_lite || [];
  }
}
