import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducer } from './member-activities.reducer';
import { MemberActivitiesEffects } from './member-activities.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    StoreModule.forFeature('member-activities', reducer),
    EffectsModule.forFeature([MemberActivitiesEffects]),
  ],
})
export class MemberActivitiesModule {}
