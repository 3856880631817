<span class="no-wrap" *ngIf="filter.options && filter.options.length">
  <mat-checkbox
    data-cy="search-checkbox.matCheckbox"
    class="material-override"
    [checked]="filter.selected"
    (keydown.enter)="facetOptionSelected(filter)"
    (change)="facetOptionSelected(filter)"
    labelPosition="after"
    [matTooltip]="filter.tooltip | translate"
    matTooltipShowDelay="1500"
    platformChildFocus
  >
    {{ filter.name | translate }}
  </mat-checkbox>
</span>
