import { SearchFilterV2 } from '@interfaces/search-filter-v2.model';
import { SelectedFilter } from '@interfaces/selected-filter.interface';

export type SearchFiltersV2State = {
  configFilters: SearchFilterV2State;
  selectedFilters?: SelectedFilter;
  appliedFilters?: SearchFilterV2State;
  baseFilters?: SearchFilterV2State;
};

export type SearchFilterV2State = {
  filters?: SearchFilterV2[];
  loading?: boolean;
  error?: string;
};

export const initialState: SearchFiltersV2State = {
  configFilters: { loading: false, filters: null },
};
