import { SavedProvider } from '@interfaces/saved-provider.model';
import { createAction, props } from '@ngrx/store';

export enum SavedProvidersActionsTypes {
  requestSavedProviders = '[GET] request saved providers results',
  requestSavedProvidersSuccess = '[GET] request saved providers results success',
  requestSavedProvidersFailure = '[GET] request saved providers results failed',
  setSavedProvidersEnabled = '[SET] saved providers enabled',
  setSavedProvidersDisabled = '[SET] saved providers disabled',
  addProviderToSavedProviders = '[POST] add provider to saved providers',
  addSavedProvidersSuccess = '[POST] add provider to saved providers success',
  addSavedProvidersFailure = '[POST] add provider to saved providers failed',
  removeProviderFromSavedProviders = '[DELETE] remove provider from saved providers',
  removeSavedProvidersFailure = '[DELETE] remove provider from saved providers failed',
  removeSavedProvidersSuccess = '[DELETE] remove provider from saved providers success',
}

export const requestSavedProviders = createAction(
  SavedProvidersActionsTypes.requestSavedProviders,
  props<{ member_number: string }>()
);

export const requestSavedProvidersSuccess = createAction(
  SavedProvidersActionsTypes.requestSavedProvidersSuccess,
  props<{ results: SavedProvider[] }>()
);

export const requestSavedProvidersFailure = createAction(
  SavedProvidersActionsTypes.requestSavedProvidersFailure,
  props<{ error: any }>()
);

export const setSavedProvidersEnabled = createAction(
  SavedProvidersActionsTypes.setSavedProvidersEnabled,
  props<{ member_number: string; max: number }>()
);
export const setSavedProvidersDisabled = createAction(
  SavedProvidersActionsTypes.setSavedProvidersDisabled
);
export const addProviderToSavedProviders = createAction(
  SavedProvidersActionsTypes.addProviderToSavedProviders,
  props<{ providerCCID: string; providerName: string }>()
);

export const addSavedProvidersSuccess = createAction(
  SavedProvidersActionsTypes.addSavedProvidersSuccess,
  props<{ savedProvider: SavedProvider; providerName: string }>()
);

export const addSavedProvidersFailure = createAction(
  SavedProvidersActionsTypes.addSavedProvidersFailure,
  props<{ error: any; providerCCID: string }>()
);

export const removeProviderFromSavedProviders = createAction(
  SavedProvidersActionsTypes.removeProviderFromSavedProviders,
  props<{ savedProvider: SavedProvider; providerName: string }>()
);

export const removeSavedProvidersFailure = createAction(
  SavedProvidersActionsTypes.removeSavedProvidersFailure,
  props<{ error: any; savedProvider: SavedProvider }>()
);

export const removeSavedProvidersSuccess = createAction(
  SavedProvidersActionsTypes.removeSavedProvidersSuccess,
  props<{ providerCCID: string; providerName: string }>()
);
