<div
  class="text-center text-palette-m3-system-gray-10 flex flex-col items-center p-4 md:p-0"
>
  <h3
    class="dls-subhead-1-bold"
    [innerHTML]="'network_selection_wizard_product_type_title' | translate"
  ></h3>
  <div
    class="text-base mt-2 mb-10 max-w-[582px]"
    [innerHTML]="
      'network_selection_wizard_product_type_description' | translate
    "
  ></div>
  <app-wizard-card-layout-template>
    <app-wizard-selection-card
      data-cy="network-selection-wizard-hmo-option"
      [title]="
        'network_selection_wizard_product_type_hmo_card_title' | translate
      "
      [description]="
        'network_selection_wizard_product_type_hmo_card_description' | translate
      "
      [ctaText]="'app_global_select' | translate"
      (cta)="setProductType(ProductType.hmo)"
    >
    </app-wizard-selection-card>
    <app-wizard-selection-card
      data-cy="network-selection-wizard-ppo-option"
      [title]="
        'network_selection_wizard_product_type_ppo_card_title' | translate
      "
      [description]="
        'network_selection_wizard_product_type_ppo_card_description' | translate
      "
      [ctaText]="'app_global_select' | translate"
      (cta)="setProductType(ProductType.ppo)"
    >
    </app-wizard-selection-card>
  </app-wizard-card-layout-template>
  <app-wizard-login-link></app-wizard-login-link>
</div>
