import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SpecialtiesService } from '@services/specialties/specialties.service';
import { ProceduresService } from '@services/procedures.service';
import { AlphaListService } from '@services/alpha-list/alpha-list.service';
import { MembersService } from '@services/members.service';
import { TranslateService } from '@ngx-translate/core';
import { GuidedSearchSetting } from '@interfaces/guided-search-setting.model';
import {
  forkJoin,
  of,
  iif,
  Observable,
  combineLatest,
  zip,
  mergeMap,
} from 'rxjs';
import { FeaturesService } from '@services/features/features.service';
import { AuthStatus } from '@interfaces/auth-status.model';
import { Specialty } from '@interfaces/specialty.model';
import { ProviderTypesService } from '@services/provider-types/provider-types.service';
import { GuidedSearchType } from '@interfaces/guided-search-type.interface';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { GuidedSearchDialogComponent } from '@components/guided-search/guided-search-dialog/guided-search-dialog.component';
import { TermAutosuggest } from '@classes/term-autosuggest.class';
import { SettingsService } from '@services/settings.service';
import { cloneDeep, isEqual } from 'lodash';
import { Procedure } from '@interfaces/procedure.model';
import { IncentivizedProcedure } from '@interfaces/incentivized-procedure.model';
import { Store, select } from '@ngrx/store';
import { AuthStoreSelectors } from '@store/auth';
import { NetworksService } from '@services/networks.service';
import { Router } from '@angular/router';
import { DialogService } from '@zelis/dls/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { HasHistoricalClaimsConfig } from '@interfaces/has-historical-claims.model';
import { MleStatus } from '@interfaces/mle-status.model';
import { MleService } from '@services/mle.service';
import { CouponsService } from '@services/coupons.service';
import { ProductAnalyticsService } from '@services/product-analytics/product-analytics.service';
import { RouteUtilities } from '@utilities/route.utilities';
import { Member } from '@components/members';
const SEARCH_TYPE_MAP = {
  search_specialties: 'specialties',
  procedures: 'procedures',
  incentivized_procedures: 'incentivizedProcedures',
  incentivized_specialties: 'incentivizedSpecialties',
  provider_types: 'providerTypes',
};

const TOP_LEVEL_OPTION_NAME = 'home_browse_by_category';

@Injectable({
  providedIn: 'root',
})
export class GuidedSearchService {
  public guidedSearchOptions: Observable<GuidedSearchSetting[] | null>;
  public isEnabled: Observable<boolean>;
  public isReady: Observable<boolean>;
  public showProcedureCosts: boolean;
  private futureMember: boolean;

  constructor(
    private specialtiesService: SpecialtiesService,
    private proceduresService: ProceduresService,
    private alphaListService: AlphaListService,
    private translateService: TranslateService,
    private membersService: MembersService,
    private featuresService: FeaturesService,
    private providerTypesService: ProviderTypesService,
    private settingsService: SettingsService,
    private dialogService: DialogService,
    private mediaQuery: MediaObserver,
    private termAutosuggest: TermAutosuggest,
    private store: Store<any>,
    private networksService: NetworksService,
    private router: Router,
    private mleService: MleService,
    private couponsService: CouponsService,
    private productAnalyticsService: ProductAnalyticsService,
    private routeUtilities: RouteUtilities
  ) {
    this.guidedSearchOptions = this.getGuidedSearchOptions();
    this.isEnabled = this.getIsEnabled();
    this.isReady = this.getSearchReady();
  }

  public getSelectedGuidedSearchOption(
    options: Observable<GuidedSearchSetting[]>,
    selected: Observable<GuidedSearchSetting>
  ): Observable<GuidedSearchSetting> {
    return combineLatest([
      options,
      selected.pipe(distinctUntilChanged(isEqual)),
    ]).pipe(
      map(([settings, selectedOption]) =>
        this.mapMenuOptions(settings, selectedOption)
      )
    );
  }

  public selectGuidedSearchOption(
    option?: GuidedSearchSetting,
    restoreFocus?: boolean,
    cardOrder?: number
  ): MatDialogRef<GuidedSearchDialogComponent, any> {
    const typeData = this.getOptionTypeData(option);
    if (option?.onClick?.name === 'onClick') {
      return option?.onClick();
    }
    if (
      !option ||
      option?.options?.length > 0 ||
      typeData?.length > 0 ||
      option.unauthenticatedProcedures
    ) {
      return this.openModal(option, restoreFocus, cardOrder);
    } else {
      this.navigateToSearch(option);
    }
  }

  public navigateToSearch(option: GuidedSearchSetting): void {
    if (!option.id) {
      return;
    }
    const filters = option.filters
      ? { preserveFilters: true, ...option.filters }
      : {};
    this.termAutosuggest.activateSearch(
      { type: option.type, id: option.id, name: option.name },
      filters
    );
  }

  public getIncentivizedSpecialties(): Observable<GuidedSearchSetting> {
    return this.guidedSearchOptions.pipe(
      mergeMap((settings: GuidedSearchSetting[]) => settings),
      filter(
        (setting: GuidedSearchSetting) => setting.type === 'combined_incentives'
      ),
      map((setting) =>
        setting.options.find(
          (option) => option.type === 'incentivized_specialties'
        )
      )
    );
  }

  public openGuidedSearchByType(type: GuidedSearchType): void {
    if (type === 'menu') {
      this.openModal();
    } else {
      this.openModal(
        new GuidedSearchSetting({
          name: `guided_search_all_${type}`,
          type: type,
        })
      );
    }
  }

  private activateAlphaList(option: GuidedSearchSetting): void {
    const typeData = this.getOptionTypeData(option);
    if (option.useAlphaList && typeData.length > 0) {
      this.alphaListService.alphabetize(
        typeData,
        option.type,
        this.translateService.currentLang
      );
      this.alphaListService.activeList = option.type;
    } else {
      this.alphaListService.activeList = '';
    }
  }

  private mapMenuOptions(
    options: GuidedSearchSetting[],
    selectedOption?: GuidedSearchSetting
  ): GuidedSearchSetting {
    let out: GuidedSearchSetting;

    if (selectedOption && selectedOption.name !== TOP_LEVEL_OPTION_NAME) {
      // Map to selected option
      out = this.reconcileSelectedOption(
        options,
        new GuidedSearchSetting(selectedOption)
      );
    } else {
      // Map to base Browse option
      out = new GuidedSearchSetting({
        name: TOP_LEVEL_OPTION_NAME,
        options: options,
      });
    }

    this.activateAlphaList(out);

    return out;
  }

  private reconcileSelectedOption(
    options: GuidedSearchSetting[],
    selectedOption: GuidedSearchSetting
  ): GuidedSearchSetting {
    let found: GuidedSearchSetting;
    options.forEach((option) => {
      if (!found) {
        if (option.name === selectedOption.name) {
          found = option;
        } else if (option.options) {
          found = this.reconcileSelectedOption(option.options, selectedOption);
        }
      }
    });
    return found;
  }

  private showTerminalOption(option: GuidedSearchSetting): boolean {
    return !!(
      option.id &&
      (!option.type ||
        this.getOptionTypeData(option).some(
          (item) => String(item.id) === String(option.id)
        ))
    );
  }

  private showPathwayOption(option: GuidedSearchSetting): boolean {
    return !!(
      !option.id &&
      (!option.type ||
        option.type === 'link' ||
        this.getOptionTypeData(option).length > 0 ||
        option.options?.length > 0)
    );
  }

  private getIsEnabled(): Observable<boolean> {
    return this.guidedSearchOptions.pipe(map((settings) => !!settings));
  }

  private getSearchReady(): Observable<boolean> {
    return this.guidedSearchOptions.pipe(
      map((settings) => settings?.length > 0)
    );
  }

  private getMember(): Observable<Member> {
    return this.membersService.member.pipe(
      catchError(() => of(null)),
      first((member) => member !== undefined)
    );
  }

  private getAuthStatus(): Observable<AuthStatus> {
    return this.store.pipe(
      select(AuthStoreSelectors.getAuthStatus),
      first((auth) => auth.resolved)
    );
  }

  private shouldRequestIncentivizedProcedures(
    auth: AuthStatus,
    incentivesEnabled: boolean
  ): Observable<boolean> {
    if (auth?.auth_status && incentivesEnabled) {
      return this.getCostFlag().pipe(
        switchMap((costFlag: boolean) =>
          iif(
            () => costFlag,
            this.getMember().pipe(map((member: Member) => !!member)),
            of(false)
          )
        )
      );
    }
    return of(false);
  }

  private getCostFlag(): Observable<boolean> {
    return this.featuresService.getFeatureFlags().pipe(
      first((features) => !!features),
      map((features) => features?.cost)
    );
  }

  private filterBySuppression(
    option: GuidedSearchSetting,
    showUnauthenticatedProcedures: boolean
  ): boolean {
    if (
      showUnauthenticatedProcedures &&
      option.type === 'procedures' &&
      !option.nestedSetting
    ) {
      option.unauthenticatedProcedures = true;
      return true;
    }
    if (this.shouldSuppressOption(option)) {
      return false;
    }
    if (this.showPathwayOption(option)) {
      return true;
    } else if (this.showTerminalOption(option)) {
      return true;
    }
    return false;
  }

  private shouldSuppressOption(option: GuidedSearchSetting): boolean {
    // Suppress if explicitly suppressed or missing type or name
    if (option.suppress || !option.type || !option.name) {
      return true;
    }
    // Suppress if not a terminal option and there are no child options or type data
    if (
      !option.id &&
      !option.options?.length &&
      !this.getOptionTypeData(option).length &&
      !option.useAlphaList &&
      option.type !== 'link'
    ) {
      return true;
    }
    return false;
  }

  private getOptionTypeData(option: GuidedSearchSetting): any[] {
    return (option && option[SEARCH_TYPE_MAP[option.type]]) || [];
  }

  private openModal(
    option?: GuidedSearchSetting,
    restoreFocus?: boolean,
    cardOrder?: number
  ): MatDialogRef<GuidedSearchDialogComponent, any> {
    let data: any = { selectedOption: option, cardOrder };
    if (option?.unauthenticatedProcedures) {
      data = {
        selectedOption: option,
        loginPrompt: true,
        cardOrder,
        headlineKey: 'unauthenticated_procedures_tile_headline',
        subHeadlineKey: 'unauthenticated_procedures_tile_sub_headline',
        noAccountKey: 'unauthenticated_procedures_tile_no_account',
        loginPromptKey: 'unauthenticated_procedures_tile_login_prompt',
      };
    }
    return this.dialogService.open(GuidedSearchDialogComponent, {
      width: '784px',
      maxHeight: '95vh',
      height: 'auto',
      restoreFocus: restoreFocus,
      data: data,
      panelClass: this.mediaQuery.isActive('lt-md') ? 'full-screen' : '',
    });
  }

  // Build Guided Search main observable
  private getGuidedSearchOptions(): Observable<GuidedSearchSetting[] | null> {
    return this.getGuidedSearchSettingsAndStatuses().pipe(
      // Get Procedures and Incentives
      switchMap(
        ([settings, specialties, requestIncentiveProcedures, providerTypes]) =>
          this.getProceduresAndIncentives(
            settings,
            specialties,
            requestIncentiveProcedures,
            providerTypes
          )
      ),
      withLatestFrom(this.couponsService.isRxCouponsEligible),
      switchMap(
        ([
          [settings, specialties, procedures, incentives, providerTypes],
          couponsEligible,
        ]) =>
          this.insertAndMapData(
            settings,
            specialties,
            procedures,
            incentives,
            providerTypes,
            couponsEligible
          )
      ),
      withLatestFrom(this.getShowUnauthenticatedProcedures()),
      // Filter suppressed options
      map(([settings, showUnauthenticatedProcedures]) =>
        this.filterSuppressedOptions(settings, showUnauthenticatedProcedures)
      ),
      map((settings) => this.insertProcedureCostSearchTile(settings))
    );
  }

  private insertAndMapData(
    settings,
    specialties,
    procedures,
    incentives,
    providerTypes,
    couponsEligible
  ): Observable<GuidedSearchSetting[]> {
    return this.getMember().pipe(
      switchMap((member: Member) => {
        settings = this.insertMedicationFinder(
          settings,
          couponsEligible,
          member
        );
        const mapping: GuidedSearchSetting[] = this.mergeAllListsIntoSettings(
          this.insertIncentivesOption(
            specialties,
            settings,
            incentives,
            member
          ),
          this.mergeIncentivesIntoSpecialties(specialties, member),
          this.mergeIncentivesIntoProcedures(procedures, incentives),
          incentives,
          providerTypes,
          member
        );
        // Clone to prevent up-stream mutation
        return of(cloneDeep(mapping));
      })
    );
  }

  private getGuidedSearchSettingsAndStatuses(): Observable<
    [GuidedSearchSetting[], Specialty[], boolean, GuidedSearchSetting[]] | any[]
  > {
    return this.settingsService.getSetting('guided_search').pipe(
      switchMap((guidedSearch) =>
        this.shouldShowProcedureCostSearch().pipe(map(() => guidedSearch))
      ),
      // Map Guided Search settings
      switchMap((guidedSearch) => this.mapGuidedSearchSettings(guidedSearch)),
      // Get Specialties, Statuses, and Provider Types
      switchMap((settings) =>
        this.getSpecialtiesStatusesAndProviderTypes(settings)
      ),
      // Get should request Incentivized Procedures
      switchMap(
        ([settings, specialties, auth, incentivesEnabled, providerTypes]) =>
          this.getShouldRequestIncentivizedProcedures(
            settings,
            specialties,
            auth,
            incentivesEnabled,
            providerTypes
          )
      ),
      // Prevent re-request & re-processing of previous steps
      shareReplay(1)
    );
  }

  private getProceduresAndIncentives(
    settings: GuidedSearchSetting[],
    specialties: Specialty[],
    requestIncentiveProcedures: boolean,
    providerTypes: GuidedSearchSetting[]
  ): Observable<
    | [
        GuidedSearchSetting[],
        Specialty[],
        Procedure[],
        IncentivizedProcedure[],
        GuidedSearchSetting[]
      ]
    | any[]
  > {
    return iif(
      () => !!settings,
      combineLatest([
        of(settings),
        of(specialties),
        this.getGuidedSearchProcedures(),
        this.getGuidedSearchIncentives(requestIncentiveProcedures),
        of(providerTypes),
      ]),
      of([null])
    );
  }

  private getShouldRequestIncentivizedProcedures(
    settings: GuidedSearchSetting[],
    specialties: Specialty[],
    auth: AuthStatus,
    incentivesEnabled: boolean,
    providerTypes: GuidedSearchSetting[]
  ): Observable<
    [GuidedSearchSetting[], Specialty[], boolean, GuidedSearchSetting[]] | any[]
  > {
    return iif(
      () => !!settings,
      forkJoin([
        of(settings),
        of(specialties),
        this.shouldRequestIncentivizedProcedures(auth, incentivesEnabled),
        of(providerTypes),
      ]),
      of([null])
    );
  }

  private getSpecialtiesStatusesAndProviderTypes(
    settings: GuidedSearchSetting[]
  ): Observable<
    | [
        GuidedSearchSetting[],
        Specialty[],
        AuthStatus,
        boolean,
        GuidedSearchSetting[]
      ]
    | any[]
  > {
    let providerTypes = null;
    if (
      !!settings &&
      settings.find(
        (setting) =>
          setting.name === 'guided_search_provider_types_title' &&
          !setting.suppress
      )
    ) {
      providerTypes = this.getProviderTypes();
    } else {
      providerTypes = of([]);
    }
    return iif(
      () => !!settings,
      zip(
        of(settings),
        this.specialtiesService.requestSpecialties(null),
        this.getAuthStatus(),
        this.getIncentivesEnabled(),
        providerTypes
      ),
      of([null])
    );
  }

  private getIncentivesEnabled(): Observable<boolean> {
    return this.membersService.incentivesEnabled.pipe(
      filter((enabled) => enabled !== undefined)
    );
  }

  private mapGuidedSearchSettings(
    guidedSearch: any
  ): Observable<GuidedSearchSetting[]> {
    return iif(
      () => !!guidedSearch,
      combineLatest([
        of(guidedSearch),
        this.networksService.resolvedNetwork,
      ]).pipe(
        // Convert object to array
        map(([setting]) => Object.values(setting)),
        // Convert any[] to GuidedSearchSetting[]
        map((settings: any[]) =>
          settings.map((setting) => new GuidedSearchSetting(setting))
        )
      ),
      of(null)
    );
  }

  private filterSuppressedOptions(
    options: GuidedSearchSetting[],
    showUnauthenticatedProcedures: boolean
  ): GuidedSearchSetting[] {
    if (!options) {
      return null;
    }
    options.forEach((option) => {
      const hadOptions: boolean = !!option.options?.length;
      option.options = this.filterSuppressedOptions(
        option.options,
        showUnauthenticatedProcedures
      );
      // Force suppress if all options were removed
      if (!option.options?.length && hadOptions) {
        option.suppress = true;
      }
      // Force suppress procedures if has historical claims
      if (!!this.showProcedureCosts && option.type === 'procedures') {
        option.suppress = true;
      }
      // Use Alpha List if not terminal, no configured options, but type data is present
      option.useAlphaList =
        !option.id &&
        !option.suppress &&
        !option.options?.length &&
        this.getOptionTypeData(option).length > 0;
    });
    options = options.filter((option) =>
      this.filterBySuppression(option, showUnauthenticatedProcedures)
    );
    return options;
  }

  private getGuidedSearchProcedures(): Observable<Procedure[]> {
    return this.proceduresService.proceduresUpdated;
  }

  private getGuidedSearchIncentives(
    shouldRequest: boolean
  ): Observable<IncentivizedProcedure[]> {
    return iif(
      () => shouldRequest,
      this.proceduresService.requestIncentivizedProcedures(),
      of(null)
    );
  }

  private getProviderTypes(): Observable<GuidedSearchSetting[]> {
    return this.providerTypesService.requestProviderTypes().pipe(
      map((providerTypes) => providerTypes || []),
      map((providerTypes) =>
        providerTypes.map(
          (providerType) =>
            new GuidedSearchSetting({
              name: providerType,
              type: 'provider_types',
            })
        )
      )
    );
  }

  private mergeAllListsIntoSettings(
    settings: GuidedSearchSetting[],
    specialties: Specialty[],
    procedures: Procedure[],
    incentives: IncentivizedProcedure[],
    providerTypes: GuidedSearchSetting[],
    member: Member
  ): GuidedSearchSetting[] {
    if (settings) {
      settings = this.mergeOptionsByType(
        settings,
        specialties,
        procedures,
        incentives,
        providerTypes,
        member
      );
    }
    return settings;
  }

  private mergeOptionsByType(
    settings: GuidedSearchSetting[],
    specialties: Specialty[],
    procedures: Procedure[],
    incentives: IncentivizedProcedure[],
    providerTypes: GuidedSearchSetting[],
    member: Member
  ): GuidedSearchSetting[] {
    settings.forEach((option) => {
      if (option.type === 'search_specialties' && specialties) {
        this.suppressIncentivizedSpecialties(option, member);
        option.specialties = specialties;
      }
      if (option.type === 'procedures' && procedures) {
        option.procedures = procedures;
      }
      if (option.type === 'incentivized_procedures' && incentives) {
        option.incentivizedProcedures = incentives;
      }
      if (option.type === 'incentivized_specialties' && specialties) {
        option.incentivizedSpecialties = specialties.filter(
          (specialty) => specialty.reward_amount
        );
      }
      if (option.type === 'provider_types' && providerTypes) {
        option.providerTypes = providerTypes;
      }
      if (option.options) {
        this.mergeOptionsByType(
          option.options,
          specialties,
          procedures,
          incentives,
          providerTypes,
          member
        );
      }
    });
    return settings;
  }

  private insertIncentivesOption(
    specialties: Specialty[],
    settings: GuidedSearchSetting[],
    incentives: IncentivizedProcedure[],
    member: Member
  ): GuidedSearchSetting[] {
    const hasIncentivizedSpecialties =
      specialties?.filter((specialty) => specialty.reward_amount)?.length > 0;
    const displaySpecialtyOption =
      hasIncentivizedSpecialties && member?.quality_rewards_eligible;
    if (settings && incentives?.length > 0 && !this.futureMember) {
      const specialtiesOption = new GuidedSearchSetting({
        type: 'incentivized_specialties',
        name: 'guided_search_all_incentivized_specialties',
        description: 'guided_search_all_incentivized_specialties_descriptions',
        options: [],
      });
      const proceduresOption = new GuidedSearchSetting({
        type: 'incentivized_procedures',
        name: 'guided_search_all_incentivized_procedures',
        description: 'guided_search_all_incentivized_procedures_descriptions',
        options: [],
      });
      const combinedOption = new GuidedSearchSetting({
        type: displaySpecialtyOption
          ? 'combined_incentives'
          : 'incentivized_procedures',
        name: 'rts_title',
        description: 'home_smartshopper_tile_description',
        options: displaySpecialtyOption
          ? [specialtiesOption, proceduresOption]
          : [],
      });
      return [combinedOption, ...settings];
    }
    return settings;
  }

  private insertMedicationFinder(
    settings: GuidedSearchSetting[],
    rx_coupons_eligible: boolean,
    member: Member
  ): GuidedSearchSetting[] {
    const rx_savings_eligible = member?.rx_savings_eligible;

    if (settings && (rx_savings_eligible || rx_coupons_eligible)) {
      const tileType = rx_savings_eligible ? 'medFinder' : 'couponsOnly';
      const option = this.getMedFinderTile(tileType);
      return [option, ...settings];
    }
    return settings;
  }

  private getMedFinderTile(tileType: string): GuidedSearchSetting {
    const tileOptions = {
      // in the future there will be a combined coupon/medfinder Option
      couponsOnly: {
        title: 'coupons_tile_title',
        description: 'coupons_tile_description',
        route: 'coupons',
        icon: 'dls-medications-capsule-and-tablet-light',
      },
      medFinder: {
        title: 'medication_finder_title',
        description: 'medication_finder_description',
        route: 'medication-finder',
        icon: 'dls-medications-prescription-bottle-light',
      },
    };
    const tile = tileOptions[tileType];
    return new GuidedSearchSetting({
      type: 'link',
      onClick: () => this.medFinderTileOnClick(tile.route),
      name: tile.title,
      description: tile.description,
      options: [],
      icon: tile.icon,
    });
  }

  private medFinderTileOnClick(route: string): void {
    if (route === 'medication-finder') {
      this.productAnalyticsService.sendTrackEvent(
        'MedFinder Browse Tile clicked',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        { medFinder_browse_tile_clicked: true }
      );
    }
    this.router.navigate([route]);
  }

  private insertProcedureCostSearchTile(
    settings: GuidedSearchSetting[]
  ): GuidedSearchSetting[] {
    if (settings && !!this.showProcedureCosts) {
      const option = new GuidedSearchSetting({
        type: 'link',
        onClick: () => this.openBillingCodeSearch(),
        name: 'tinc_billing_search_tile',
        description: 'tinc_billing_search_tile_description',
        options: [],
        icon: 'dls-dollar-signs-hands-holding-light',
      });
      return [option, ...settings];
    }
    return settings;
  }

  private openBillingCodeSearch(): void {
    const routeState = this.routeUtilities.getState(this.router.url);
    this.router.navigate(['billing-code-search'], {
      queryParams: { from: routeState },
      queryParamsHandling: 'merge',
    });
  }

  private mergeIncentivesIntoProcedures(
    procedures: Procedure[],
    incentives: IncentivizedProcedure[]
  ): Procedure[] {
    if (procedures?.length > 0 && incentives?.length > 0) {
      incentives.forEach((incentive) => {
        const found = procedures.find(
          (procedure) => procedure.id === incentive.id
        );
        if (found) {
          found.eligible_for_incentive = incentive.eligible_for_incentive;
          found.high_incentive_amount = incentive.high_incentive_amount;
          found.low_incentive_amount = incentive.low_incentive_amount;
        }
      });
    }
    return procedures;
  }

  private mergeIncentivesIntoSpecialties(
    specialties: Specialty[],
    member: Member
  ): Specialty[] {
    if (member?.quality_rewards_eligible && specialties?.length > 0) {
      specialties.map(
        (specialty: Specialty) =>
          (specialty.high_incentive_amount = specialty.reward_amount)
      );
    }
    return specialties;
  }

  private getShowUnauthenticatedProcedures(): Observable<boolean> {
    return combineLatest([
      this.settingsService.getSetting(
        'suppress_unauthenticated_procedure_tile'
      ),
      this.getAuthStatus(),
    ]).pipe(
      switchMap(([suppress, auth]: [boolean, AuthStatus]) => {
        if (!suppress && !auth.auth_status) {
          return of(true);
        }
        return of(false);
      })
    );
  }

  private shouldShowProcedureCostSearch(): Observable<
    [HasHistoricalClaimsConfig, AuthStatus, MleStatus, Member]
  > {
    return combineLatest([
      this.settingsService.getSetting(
        'has_historical_claims',
        HasHistoricalClaimsConfig
      ),
      this.store.pipe(select(AuthStoreSelectors.getAuthStatus)),
      this.mleService.status,
      this.membersService.member,
    ]).pipe(
      tap(
        ([claims, auth, mleStatus, member]: [
          HasHistoricalClaimsConfig,
          AuthStatus,
          MleStatus,
          Member
        ]) => {
          this.futureMember = member?.future_member;
          this.showProcedureCosts =
            !claims.enabled && auth.auth_status && mleStatus.isEligible;
        }
      )
    );
  }

  private suppressIncentivizedSpecialties(
    option: GuidedSearchSetting,
    member: Member
  ): void {
    if (option.primary_specialties_tile && !member?.quality_rewards_eligible) {
      option.options = option.options.filter((setting: GuidedSearchSetting) => {
        return setting.type !== 'incentivized_specialties';
      });
    }
  }
}
