import { TermAutosuggest } from '@classes/term-autosuggest.class';
import { filter, take } from 'rxjs/operators';
import { SearchParamsService } from '../../../+search/search-params.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { MsaSettingsService } from '../../msa.settings.service';
import { Breakpoints } from '@classes/breakpoints.class';
import { SearchTrigger } from '@classes/search-trigger.class';
import { BvaService } from '../bva.service';
import { NotificationOverlayComponent } from '../../../notification-overlay';
import { Router } from '@angular/router';
import { Msa } from '@interfaces/msa.model';
import { WindowService } from '@services/window.service';
import { HttpParams } from '@angular/common/http';
import { DialogButtonConfig, DialogService } from '@zelis/dls/dialog';
import { TranslateService } from '@ngx-translate/core';

interface NotificationButton {
  translation: string;
  method: string;
}

@Component({
  selector: 'app-bva-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class BvaSidebarComponent implements OnInit, OnDestroy {
  public enabled: boolean = false;
  public msa: Msa;
  public intendedProvider = null;
  public confirmModal = null;

  private procedureId = null;
  private subscriptions = new SubscriptionManager();
  private lastSearchParams: HttpParams;

  constructor(
    private msaSettingsService: MsaSettingsService,
    public breakpoints: Breakpoints,
    public searchParamsService: SearchParamsService,
    public searchTrigger: SearchTrigger,
    public bvaService: BvaService,
    public termAutosuggest: TermAutosuggest,
    public router: Router,
    private windowService: WindowService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.subscribeToMsaSso();
    this.getLastSearchParams();
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public subscribeToMsaSso(): void {
    this.subscriptions.add(
      this.msaSettingsService
        .getMsaSso()
        .pipe(
          filter(
            (msaSso: Msa) =>
              !!(msaSso.sso && msaSso.msaId && msaSso.bvaEndpoint)
          )
        )
        .subscribe((msa) => {
          this.msa = msa;
          this.enabled = true;
          this.subscriptions.add(this.subscribeToSearchResults());
        })
    );
  }

  public subscribeToSearchResults(): Subscription {
    return this.bvaService.lastSearchResults
      .pipe(
        filter(
          (results) => results && !!this.lastSearchParams.get('procedure_id')
        )
      )
      .subscribe((lastSearchResults) => {
        this.bvaService
          .requestProviders()
          .pipe(take(1))
          .subscribe((searchResults) => {
            if (
              this.procedureHasChanged(lastSearchResults) &&
              this.resultsHaveChanged(searchResults) &&
              !this.bvaService.providersAreClean()
            ) {
              this.openConfirmationModal(true, searchResults);
            } else {
              this.setSearchResults(searchResults);
              this.procedureId = this.lastSearchParams.get('procedure_id');
            }
          });
      });
  }

  public intendedProvidersAreAvailable(): boolean {
    return !!(
      this.bvaService.results &&
      this.bvaService.results.providers &&
      this.bvaService.results._meta &&
      this.bvaService.results._meta.cost
    );
  }

  public clearIntendedProvider(): void {
    this.intendedProvider = null;
  }

  public sync(): void {
    this.bvaService.sync();
  }

  public newSearch(): void {
    this.openConfirmationModal();
  }

  public getButtons(
    resultsChanged: boolean,
    results?: any
  ): Array<DialogButtonConfig> {
    return [
      {
        content: this.translateService.instant(
          resultsChanged ? 'bva_cancel_change' : 'bva_stay_on_page'
        ),
        onClick: resultsChanged ? this.goBack.bind(this) : null,
        variant: 'stroked',
        isCloseButton: true,
      },
      {
        content: this.translateService.instant(
          resultsChanged ? 'bva_change_procedure' : 'bva_continue'
        ),
        onClick: this.activateNewSearch.bind(this, resultsChanged, results),
        variant: 'stroked',
        isCloseButton: true,
      },
    ];
  }

  public getBodyText(resultsChanged: boolean): string {
    return resultsChanged
      ? 'bva_confirmation_modal_body_result_changed'
      : 'bva_confirmation_modal_body_result_not_changed';
  }

  public goBack(): void {
    this.windowService['history'].back();
  }

  private activateNewSearch(resultsChanged: boolean, results: any): void {
    this.clearIntendedProvider();
    this.bvaService.clearResults();
    this.bvaService.clearProviders();
    if (!resultsChanged) {
      this.termAutosuggest.activateHome();
    } else {
      this.bvaService.results = results;
    }
  }

  private resultsHaveChanged(res: any): boolean {
    return !!(
      this.bvaService.results.providers &&
      JSON.stringify(res) !== JSON.stringify(this.bvaService.results)
    );
  }

  private openConfirmationModal(resultsChanged?: boolean, results?: any): void {
    this.confirmModal = this.dialogService.open(NotificationOverlayComponent, {
      width: '550px',
      height: 'auto',
      data: {
        headline: '',
        body: this.getBodyText(resultsChanged),
        buttons: this.getButtons(resultsChanged, results),
      },
    });
  }

  private procedureHasChanged(lastSearchResults: any): boolean {
    return lastSearchResults.searchParams?.procedureId !== this.procedureId;
  }

  private setSearchResults(search: any): void {
    const providers = this.bvaService.results.providers;
    if (search.providers && providers && providers.length > 0) {
      this.bvaService.results.providers.push(...search.providers);
      const meta = this.bvaService.results._meta;
      if (search._meta && meta && meta.length > 0) {
        this.bvaService.results._meta.push(...search._meta);
      }
    } else {
      this.bvaService.results = search;
    }
  }

  private getLastSearchParams(): void {
    this.subscriptions.add(
      this.searchParamsService.lastSearchParams.subscribe(
        (params: HttpParams) => (this.lastSearchParams = params)
      )
    );
  }
}
