import { Component, EventEmitter, Output } from '@angular/core';
import { SubMarketSegment } from '@enums/uux-marketing-plans.enum';

@Component({
  selector: 'app-medicare-selection',
  templateUrl: './medicare-selection.component.html',
})
export class MedicareSelectionComponent {
  @Output() public subMarketSegment: EventEmitter<SubMarketSegment> =
    new EventEmitter();
  readonly SubMarketSegment = SubMarketSegment;

  public setSubMarketSegment(segment: SubMarketSegment): any {
    this.subMarketSegment.emit(segment);
  }
}
