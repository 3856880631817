import { createAction, props } from '@ngrx/store';

export enum MemberActivitiesActionsTypes {
  loadMemberActivities = '[GET] Member activities request',
  loadMemberActivitiesSuccess = '[GET] Member activities success',
  loadMemberActivitiesFailure = '[GET] Member activities failed',
  setMemberActivitiesEnabled = '[SET] Member activities enabled',
  loadActivityDetails = '[GET] Member activity details request',
  loadActivityDetailsSuccess = '[GET] Member activity details success',
  loadActivityDetailsFailure = '[GET] Member activity details failed',
  setActivitiesSuppressIllustrations = '[SET] Member activities suppress illustrations',
}

export const loadMemberActivities = createAction(
  MemberActivitiesActionsTypes.loadMemberActivities
);

export const loadMemberActivitiesSuccess = createAction(
  MemberActivitiesActionsTypes.loadMemberActivitiesSuccess,
  props<{ memberActivities: any }>()
);

export const loadMemberActivitiesFailure = createAction(
  MemberActivitiesActionsTypes.loadMemberActivitiesFailure,
  props<{ error: any }>()
);

export const loadActivityDetails = createAction(
  MemberActivitiesActionsTypes.loadActivityDetails,
  props<{ activityId: string }>()
);

export const loadActivityDetailsSuccess = createAction(
  MemberActivitiesActionsTypes.loadActivityDetailsSuccess,
  props<{ activityDetails: any }>()
);

export const loadActivityDetailsFailure = createAction(
  MemberActivitiesActionsTypes.loadActivityDetailsFailure,
  props<{ error: any }>()
);

export const setMemberActivitiesEnabled = createAction(
  MemberActivitiesActionsTypes.setMemberActivitiesEnabled,
  props<{ enabled: boolean }>()
);

export const setActivitiesSuppressIllustrations = createAction(
  MemberActivitiesActionsTypes.setActivitiesSuppressIllustrations,
  props<{ suppressIllustrations: boolean }>()
);
