<ng-container *ngIf="showSnackbar" [ngTemplateOutlet]="snackbar">
</ng-container>

<ng-container
  *ngIf="showPrintPageLink && printCount > 0"
  [ngTemplateOutlet]="printPageLink"
>
</ng-container>

<ng-container *ngIf="showCheckbox" [ngTemplateOutlet]="checkbox">
</ng-container>

<ng-template #showSnackbar>
  <zelis-snackbar [duration]="3000"> </zelis-snackbar>
</ng-template>

<ng-template #printPageLink>
  <a
    class="hover:underline"
    target="_blank"
    alt="{{ 'tinc_print_rates_save_for_print' | translate }}"
    (click)="goToPrintPage()"
    aria-label="{{ 'tinc_print_rates_save_for_print' | translate }}"
    data-cy="print-page-button"
    (keyup.enter)="goToPrintPage()"
    (keydown.space)="goToPrintPage()"
    tabindex="0"
  >
    <span>
      ({{ printCount | json }})
      {{ 'tinc_print_rates_save_for_print' | translate }}
    </span>
  </a>
</ng-template>

<ng-template #checkbox>
  <ng-container *ngIf="!(printCount === 20 && !saved); else disabledCheckbox">
    <mat-checkbox
      class="mt-6"
      aria-label="{{ 'tinc_print_rates_save_for_print_cta' | translate }}"
      data-cy="save-for-print"
      (change)="$event.checked ? saveToPrintList() : deleteFromPrintList()"
      [checked]="saved"
    >
      {{ 'tinc_print_rates_save_for_print_cta' | translate }}
    </mat-checkbox>
  </ng-container>

  <ng-template #disabledCheckbox>
    <div
      class="mt-6 ml-6"
      [matTooltip]="'tinc_print_rates_save_for_print_tooltip' | translate"
      [matTooltipPosition]="'above'"
      attr.aria-label="{{
        'tinc_print_rates_save_for_print_tooltip' | translate
      }}"
      role="tooltip"
      tabindex="0"
    >
      <mat-checkbox
        class="text-palette-m3-system-gray-35"
        aria-label="{{ 'tinc_print_rates_save_for_print_cta' | translate }}"
        data-cy="save-for-print-disabled"
        [checked]="false"
        [disabled]="true"
      >
        {{ 'tinc_print_rates_save_for_print_cta' | translate }}
      </mat-checkbox>
    </div>
  </ng-template>
</ng-template>
