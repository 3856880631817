<div data-cy="snackbar-container" [zelisFocus]="true" tabIndex="-1">
  <div class="text-white flex flex-row justify-between">
    <div class="flex flex-row">
      <div *ngIf="data.icon" class="w-6 h-6 mr-4" data-cy="snackbar-icon">
        <mat-icon [svgIcon]="data.icon"></mat-icon>
      </div>
      <div class="flex flex-col">
        <div class="dls-body-2-regular" data-cy="snackbar-message">
          {{ data.message }}
        </div>
        <div
          *ngIf="data.metaData"
          class="dls-caption-regular"
          data-cy="snackbar-metadata"
        >
          {{ data.metaData }}
        </div>
      </div>
    </div>
    <div class="flex sm:flex-row sm:items-start">
      <ng-template
        *ngTemplateOutlet="action; context: { $implicit: false }"
      ></ng-template>
      <mat-icon
        tabindex="0"
        *ngIf="data.closeBtn"
        data-cy="snackbar-dismiss"
        class="cursor-pointer ml-4"
        (click)="snackBarRef.dismiss()"
        (keydown.enter)="snackBarRef.dismiss()"
        >close</mat-icon
      >
    </div>
  </div>
  <ng-template
    *ngTemplateOutlet="action; context: { $implicit: true }"
  ></ng-template>
</div>

<ng-template #action let-mobile>
  <div
    tabindex="0"
    *ngIf="data.cta"
    data-cy="snackbar-cta"
    (click)="cta()"
    (keydown.enter)="cta()"
    class="text-palette-m3-system-blue-70 text-base font-medium cursor-pointer"
    [ngClass]="{
      'sm:hidden text-right': mobile,
      'hidden sm:block ml-4': !mobile
    }"
  >
    {{ data.cta.label }}
  </div>
</ng-template>
