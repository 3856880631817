import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlanState } from '@interfaces/plan-state.interface';
import { FormControl, Validators } from '@angular/forms';
import { Breakpoints } from '@classes/breakpoints.class';

@Component({
  selector: 'app-employer-state-selection',
  templateUrl: './employer-state-selection.component.html',
})
export class EmployerStateSelectionComponent implements OnInit {
  @Input() public planStates: PlanState[];
  @Input() public selectedPlanState: PlanState;
  @Output() planStateSelect: EventEmitter<PlanState> = new EventEmitter();

  public planState: FormControl<PlanState> = new FormControl(
    null,
    Validators.required
  );

  constructor(public breakpoints: Breakpoints) {}

  ngOnInit(): void {
    this.planState.setValue(this.selectedPlanState);
  }

  public trackByStateCodeFn(index: number, item: PlanState): string {
    return item.state_code;
  }

  public selectCompare(optValue: PlanState, selValue: PlanState): boolean {
    return optValue.state_code === selValue?.state_code;
  }

  public onContinueClick(): void {
    this.handleContinue();
  }

  public onContinueEnterPress(): void {
    this.handleContinue();
  }

  private handleContinue(): void {
    this.planStateSelect.emit(this.planState.value);
  }
}
