import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {
  private apiUrl = '/api/propel/member_activities';

  constructor(private http: HttpClient) {}

  public getActivities(): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}.json`)
      .pipe(map(this.augmentActivities), catchError(this.handleError));
  }

  public getActivityDetails(activityId: string): Observable<any> {
    const url = `${this.apiUrl}/${activityId}.json`;
    return this.http.get<any>(url, { withCredentials: true }).pipe(
      map((response) => this.augmentActivityData(response)),
      catchError(this.handleError)
    );
  }

  private augmentActivityData(response: any): any {
    const activity = response.included.find(
      (include: any) => include.type === 'activities'
    );

    const nbas = [];

    response.data.relationships.nbas.data.map((nba: any) => {
      const nbaId = nba.id;

      const nbaData = response.included.find(
        (include: any) => include.id === nbaId
      );

      nbas.push(nbaData);
    });

    const steps = nbas.map((nba: any) => {
      const relationshipData = Object.keys(nba.relationships)
        .filter(
          (key) =>
            (key === 'article' || key === 'form') &&
            nba.relationships[key].data !== null
        )
        .map((key) => {
          const relationshipId = nba.relationships[key].data.id;
          return response.included.find(
            (include) => include.id === relationshipId
          );
        })[0];

      return {
        ...relationshipData,
        state: nba.attributes.state,
        form_answers: nba.attributes.form_answers,
        submit_type: 'nba',
      };
    });

    const attestationFormID =
      response.data.relationships.attestation_form.data?.id;

    if (attestationFormID) {
      const attestationForm = response.included.find(
        (include: any) => include.id === attestationFormID
      );

      const updatedAttestationForm = {
        ...attestationForm,
        form_answers: response.data.attributes.attestation_form_answers,
        state: response.data.attributes.attestation_form_answers
          ? 'complete'
          : 'pending',
        submit_type: 'activity-enrollment',
      };

      steps.push(updatedAttestationForm);
    }

    // Extract and return only the necessary activity data
    const activityData = {
      ...activity.attributes,
      steps: steps,
    };

    return activityData;
  }

  private augmentActivities(response: any): any {
    const { data, included } = response;
    const activities = {
      active: [],
      done: [],
    };
    data.forEach((activityEnrollment) => {
      const {
        id: enrollmentID,
        attributes: { completed_at },
        relationships: {
          activity: {
            data: { id: activityId },
          },
          nbas: { data: nbasData },
        },
      } = activityEnrollment;

      const activity = included.find((include) => include.id === activityId);

      let nbaStatus = '';
      let group = '';

      if (!completed_at) {
        const { id: nbaId } = nbasData[0];
        const nba = included.find((include) => include.id === nbaId);

        nbaStatus = nba.attributes.state === 'pending' ? 'start' : 'continue';
        group = 'active';
      } else {
        nbaStatus = 'review';
        group = 'done';
      }

      activities[group].push({
        ...activity.attributes,
        cta: nbaStatus,
        id: enrollmentID,
      });
    });

    return activities;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      errorMessage = `A client-side error occurred: ${error.error.message}`;
    } else {
      // Backend error
      errorMessage =
        `A backend error occurred: ${error.status}, ` +
        `body was: ${error.error}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
