import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WizardPreviousSelections } from '@interfaces/wizard-previous-selections.interface';
import { PageTransition } from '@interfaces/page-transition.interface';
import { NetworkSelectionWizardService } from '@services/network-selection-wizard/network-selection-wizard.service';
import { StorageUtilities } from '@utilities/storage.utilities';
import { ProductAnalyticsService } from '@services/product-analytics/product-analytics.service';

@Component({
  selector: 'app-confirm-previous-selections',
  templateUrl: './confirm-previous-selections.component.html',
  styleUrls: ['./confirm-previous-selections.component.scss'],
})
export class ConfirmPreviousSelectionsComponent {
  @Input() previousSelections: WizardPreviousSelections;
  @Output() public goToOverlayPage: EventEmitter<PageTransition> =
    new EventEmitter();
  @Output() public closeOverlay: EventEmitter<void> = new EventEmitter();
  public storage: StorageUtilities = new StorageUtilities();

  constructor(
    private networkSelectWizardService: NetworkSelectionWizardService,
    private productAnalyticsService: ProductAnalyticsService
  ) {}

  public onGoToOverlayPage(): void {
    this.sendTrackEvent({ change_selection_clicked: true });
    this.goToOverlayPage.emit({ page: 'modify-previous-selections' });
  }

  public onConfirmSelections() {
    this.networkSelectWizardService.setSelections();
    this.storage.sessionStorageSet('hasSetWizardSelections', true);
    this.sendTrackEvent({ confirm_button_clicked: true });
    this.closeOverlay.emit();
  }

  private sendTrackEvent(eventData: any): void {
    const data = {
      confirm_button_clicked: false,
      change_selection_clicked: false,
      confirmed_selections: {
        ...this.previousSelections,
      },
      ...eventData,
    };

    this.productAnalyticsService.sendTrackEvent(
      'Plan/Location Confirmation Flow',
      data
    );
  }
}
