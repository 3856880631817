import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { GatedEntryStatus } from '@interfaces/gated-entry-status.interface';
import { GatedEntryOverlayConfig } from '@interfaces/gated-entry-overlay-config.model';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { SettingsService } from '@services/settings.service';
import { LocationService } from '@services/location/location.service';
import { AppParamsService } from '@services/app.params.service';
import { AuthStatus } from '@interfaces/auth-status.model';
import { StorageUtilities } from '@utilities/storage.utilities';
import { GatedEntryOverlayComponent } from '@components/gated-entry-overlay/gated-entry-overlay.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TranslateService } from '@ngx-translate/core';
import { RouteUtilities } from '@utilities/route.utilities';
import { NetworksService } from '@services/networks.service';
import { filter } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class GatedEntryService {
  public status: BehaviorSubject<GatedEntryStatus>;
  public onDismiss: BehaviorSubject<boolean>;

  private statusClosed: GatedEntryStatus = {
    isOpened: false,
    isClosed: true,
    isEnabled: true,
  };
  private statusOpened: GatedEntryStatus = {
    isOpened: true,
    isClosed: false,
    isEnabled: true,
  };
  private statusDisabled: GatedEntryStatus = {
    isOpened: false,
    isClosed: false,
    isEnabled: false,
  };
  constructor(
    private settingsService: SettingsService,
    private locationService: LocationService,
    private appParamsService: AppParamsService,
    private storage: StorageUtilities,
    private bottomSheet: MatBottomSheet,
    private translateService: TranslateService,
    private routeUtilities: RouteUtilities,
    private networkService: NetworksService
  ) {
    this.status = new BehaviorSubject(this.statusClosed);
    this.onDismiss = new BehaviorSubject(null);
  }

  public getGatedEntry(auth: AuthStatus, loadParams: any): Observable<boolean> {
    return combineLatest([
      this.settingsService
        .getSetting('gated_entry', GatedEntryOverlayConfig)
        .pipe(first()),
      this.locationService.geo.pipe(map((geo) => geo?.sso_location)),
      this.status.pipe(distinctUntilChanged()),
      this.networkService.getValidNetworksCount().pipe(first()),
    ]).pipe(
      switchMap(([setting, hasSsoLocation, status, networksCount]) => {
        if (hasSsoLocation) {
          this.storage.sessionStorageSet('hasSsoLocation', hasSsoLocation);
        }
        const ssoLocation = this.storage.sessionStorageGet('hasSsoLocation');
        if (
          this.shouldStartGatedEntry(
            auth,
            setting,
            loadParams,
            status,
            ssoLocation,
            networksCount
          )
        ) {
          this.openGatedEntryOverlay(
            auth.auth_status,
            setting,
            this.getInitParamsToStart(loadParams),
            this.getStartingPage(networksCount, ssoLocation)
          );
          return of(true);
        } else if (!status.isOpened) {
          this.gateDisabled();
          return of(false);
        }
        return of(false);
      })
    );
  }
  public gateOpened(): void {
    this.status.next(this.statusOpened);
  }

  public gateClosed(): void {
    this.status.next(this.statusClosed);
  }

  public gateDisabled(): void {
    this.status.next(this.statusDisabled);
  }

  public dismiss(): void {
    this.onDismiss.next(true);
  }

  private hasUserSelectedCritical(): boolean {
    const criticals = this.appParamsService.getUserSelectedCritical();
    return !!criticals && criticals.length >= 1;
  }

  private shouldStartGatedEntry(
    auth: AuthStatus,
    gatedEntryConfig: GatedEntryOverlayConfig,
    loadParams: any,
    status: GatedEntryStatus,
    ssoLocation: boolean,
    networkCount: number
  ): boolean {
    const authUserEnabled =
      gatedEntryConfig.authUserEnabled && auth.auth_status;
    const unAuthUserEnabled = gatedEntryConfig.enabled && !auth.auth_status;
    const gatedEntryActive = this.storage.sessionStorageGet('gatedEntryActive');
    const gatedEntryCompleted = this.storage.sessionStorageGet(
      'gatedEntryCompleted'
    );
    const hasSwitchedLanguages = loadParams.hasOwnProperty('locale');
    const pageType = this.routeUtilities.getState();
    const onSupportPage = authUserEnabled && pageType === 'support';
    const memberWithOneNetwork = networkCount === 1 && ssoLocation;
    const authUserSkipGatedEntry =
      authUserEnabled &&
      ssoLocation &&
      gatedEntryConfig.networkSelectionDisabled;

    return (
      (unAuthUserEnabled || authUserEnabled) &&
      !auth.msa_auth_status &&
      !status.isOpened &&
      (gatedEntryConfig.alwaysShow ||
        !loadParams.geo_location ||
        gatedEntryActive) &&
      (gatedEntryConfig.alwaysShow ||
        !this.hasUserSelectedCritical() ||
        hasSwitchedLanguages) &&
      !gatedEntryCompleted &&
      !onSupportPage &&
      !memberWithOneNetwork &&
      !authUserSkipGatedEntry
    );
  }

  private getInitParamsToStart(loadParams: any): any {
    let initialAppParams = this.storage.sessionStorageGet('gatedEntryActive');
    initialAppParams = initialAppParams || loadParams || {};
    this.storage.sessionStorageSet('gatedEntryActive', initialAppParams);
    return initialAppParams;
  }

  private getStartingPage(
    networkCount: number,
    ssoLocation: boolean
  ): string | null {
    if (networkCount === 1 || !ssoLocation) {
      return null;
    }
    return ssoLocation ? 'network-select' : 'network-location';
  }

  private openGatedEntryOverlay(
    loggedIn: boolean,
    config: GatedEntryOverlayConfig,
    initialAppParams: any,
    startingPage?: string
  ): void {
    const sheetRef = this.bottomSheet.open(GatedEntryOverlayComponent, {
      panelClass: 'full-screen-sheet',
      disableClose: true,
      hasBackdrop: false,
      data: {
        initialAppParams: initialAppParams,
        gatedEntryConfig: config,
        loggedIn: loggedIn,
        startingPage: startingPage,
      },
      ariaModal: false,
      ariaLabel: this.translateService.instant('gated_entry_home_title'),
    });
    sheetRef.afterDismissed().subscribe(() => this.endGatedEntry());
  }

  private endGatedEntry(): void {
    this.storage.sessionStorageRemove('gatedEntryActive');
  }
}
