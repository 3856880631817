import { BillingCodeBenefitAmounts } from './billing-code-benefits-OON.interface';
import { RatesCostSummary } from './rates-results-cost-summary.interface';

export class BillingCodeOON {
  rate_type: 'Amount' | 'Percentage';
  isPercent: boolean;
  cost_summary: RatesCostSummary;
  allowed_amount: RatesCostSummary;
  benefits: BillingCodeBenefitAmounts;
  estimated_cost: RatesCostSummary;
  loading: boolean;
  error: string;
  plan_liability_low: number;
  plan_liability_high: number;
  member_liability_low: number;
  member_liability_high: number;
  estimated_out_of_pocket_cost: RatesCostSummary;
  show_not_covered_card: boolean;

  constructor(obj?: any) {
    this.rate_type = obj?.rate_type || 'Amount';
    this.isPercent = this.rate_type === 'Percentage';
    this.cost_summary = obj?.cost_summary || null;
    this.allowed_amount = this.setAllowedAmount();
    this.member_liability_low = obj?.member_liability_low ?? null;
    this.member_liability_high = obj?.member_liability_high ?? null;
    this.estimated_out_of_pocket_cost = this.setEstimatedOutOfPocketCost();
    this.plan_liability_low = obj?.plan_liability_low ?? null;
    this.plan_liability_high = obj?.plan_liability_high ?? null;
    this.estimated_cost = this.setEstimatedCost();
    this.benefits = obj?.benefits || null;
    this.loading = obj?.loading || false;
    this.error = obj?.error || null;
    this.show_not_covered_card = obj?.show_not_covered_card || false;
  }

  private setAllowedAmount(): RatesCostSummary {
    if (this.cost_summary) {
      const newObj = { ...this.cost_summary };
      // mop interferes with cost range pipe. mop not needed for amount_allowed
      newObj.mop_range_low = null;
      newObj.mop_range_high = null;
      return newObj;
    }
    return null;
  }

  private setEstimatedCost(): RatesCostSummary {
    if (this.plan_liability_low !== null) {
      // create obj so it can be used with cost range pipe
      return this.buildCostObj(
        this.plan_liability_low,
        this.plan_liability_high
      );
    }
    return null;
  }

  private setEstimatedOutOfPocketCost(): RatesCostSummary {
    if (this.member_liability_low !== null) {
      // create obj so it can be used with cost range pipe
      return this.buildCostObj(
        this.member_liability_low,
        this.member_liability_high
      );
    }
    return null;
  }

  private buildCostObj(low, high): RatesCostSummary {
    const key = this.isPercent ? 'percent' : 'amount';
    return {
      mop_range_low: null,
      mop_range_high: null,
      [key + '_range_low']: low,
      [key + '_range_high']: high,
    } as RatesCostSummary;
  }
}
