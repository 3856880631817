import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Provider } from '@interfaces/provider.model';
import { Identifier } from '@interfaces/identifier.interface';
import { AppointmentAvailability } from '@interfaces/appointment-availability.interface';
import { of } from 'rxjs';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  private suppressed: boolean;
  private identifierTypeCode = 'HDW';
  private affiliationField = 'headway_affiliations';

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService
  ) {
    this.settingsService
      .getSetting('appointment_scheduling_suppressed')
      .subscribe((appointmentSetting: boolean) => {
        this.suppressed = appointmentSetting;
      });
  }

  public getAvailabilityByProviders(providers: Provider[]): any {
    const identifiers = this.getIdentifiers(providers, this.identifierTypeCode);
    if (this.suppressed || !identifiers.length) {
      return of([]);
    }
    return this.http.get(
      '/api/providers/availability.json?provider_ids=' + identifiers.toString(),
      { withCredentials: true }
    );
  }

  public updateProvidersWithAvailability(
    providers: Provider[],
    availabilities: AppointmentAvailability[]
  ): Provider[] {
    return providers.map((provider: Provider) => {
      const isAvailable = availabilities.some(
        (availability: AppointmentAvailability) =>
          this.isProviderAvailable(
            provider,
            availability,
            this.identifierTypeCode
          )
      );

      return {
        ...provider,
        is_available_for_appointment: isAvailable,
      };
    });
  }

  private getIdentifiers(
    providers: Provider[],
    identifierTypeCode: string
  ): string[] {
    return providers
      .filter((provider) => provider[this.affiliationField])
      .flatMap((provider) => provider.identifiers)
      .filter((identifier) => identifier.type_code === identifierTypeCode)
      .map((identifier) => identifier.value);
  }

  private isProviderAvailable(
    provider: Provider,
    availability: AppointmentAvailability,
    identifierTypeCode: string
  ): boolean {
    return provider.identifiers.some(
      (identifier: Identifier) =>
        identifier.type_code === identifierTypeCode &&
        identifier.value === availability.provider_id.toString()
    );
  }
}
